import LegendItem from '../LegendItem';
import DonutLegendItem from '../DonutLegendItem';
import { ILegendProps } from '../types';
import { removeKeyFromObject } from '../utils';
import PieChartLegendItem from './PieChartLegendItem';

export default function ChartLegend<T>({
  data,
  type = 'bar',
  fills = ['#418FDE', '#FF671F'],
}: ILegendProps<T>) {
  if (type === 'donut') {
    return (
      <div className="naxatw-flex naxatw-w-full naxatw-justify-center ">
        <div className="cover scrollbar naxatw-flex naxatw-max-h-[140px] naxatw-flex-col naxatw-justify-center naxatw-gap-4 naxatw-overflow-y-auto">
          {data.map((key: any, index: any) => (
            <DonutLegendItem
              key={key.name}
              color={fills[index] || '#0088F8'}
              name={key.name}
              value={key.value}
            />
          ))}
        </div>
      </div>
    );
  }

  if (type === 'pieChart') {
    return (
      <div className="pie-chart-legend-container naxatw-mb-8 naxatw-mr-8 naxatw-max-w-[24.9375rem] md:naxatw-mb-0">
        {data?.map((key: any) => (
          <PieChartLegendItem key={key.name} data={key} />
        ))}
      </div>
    );
  }
  const keys: string[] = Object.keys(
    removeKeyFromObject(data[0], 'name' as keyof (typeof data)[0]),
  );

  return (
    <div className="naxatw-flex naxatw-w-full naxatw-justify-center ">
      <div className="cover naxatw-flex naxatw-gap-4 ">
        {keys.map((key, index) => (
          <LegendItem key={key} color={fills[index] || '#0088F8'} name={key} />
        ))}
      </div>
    </div>
  );
}
