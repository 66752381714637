/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import IconButton from '@Components/common/IconButton';
import {
  KnowledgeRepoFormFields,
  knowledgeRepoDefaultValues,
  knowledgeRepoDocumentResponseType,
  knowledgeRepoFormType,
  knowledgeRepoFormTypeInformation,
  KnowledgeRepoContextualFormFields,
  includeProvinces,
} from '@Constants/FormConstants/knowledgeRepoConstants';
import groupFormElements, {
  getFileNameFromURL,
  prepareDataForDropdown,
} from '@Utils/index';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
import { FormFieldProps } from '@Constants/interface/FormInterface';
import { FormControl } from '@Components/common/FormUI';
import InputLabel from '@Components/common/InputLabel';
import getInputElement from '@Components/common/FormComponent/GetInputElement';
import ErrorMessage from '@Components/common/ErrorMessage';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@Components/RadixComponents/Button';
import { useTypedSelector } from '@Store/hooks';
import {
  deleteKnowledgeRepositoryDoc,
  getChoiceDropdownList,
  getKnowledgeRepositoryById,
  getKnowledgeRepositoryDocById,
  getProgrammeProjectsDropDown,
  getThematicFieldOptions,
  patchKnowledgeRepository,
  postKnowledgeRepository,
  postKnowledgeRepositoryDoc,
  postKnowledgeRepositoryProgrammes,
  postKnowledgeRepositoryProjects,
  postKnowledgeRepositoryThematicField,
} from '@Services/knowledgeRepository';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { setDropdownOptionsList } from '@Store/actions/knowledgeRepository';
import { useLocation, useParams } from 'react-router-dom';
import {
  KnowledgeRepositoryValidationSchema,
  KnowledgeRepositoryConceptualValidationSchema,
} from '@Validations/KnowledgeRepositoryForm';
import isEmpty from '@Utils/isEmpty';
import prepareFormData from '@Utils/prepareFormData';
import { toast } from 'react-toastify';
import Portal from '@Components/common/Layouts/Portal';
import DeleteConfirmationOverlay from '@Components/common/PopupOverlays/DeleteConfirmationOverlay';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { animated, useSpring } from '@react-spring/web';
import SwitchTab from '@Components/common/FormUI/SwitchTab';
import { getProvinceList } from '@Services/common';
import AccordianDropDown from '@Components/common/AccordianDropDown';
import { IAccordianDropDownData } from '@Constants/interface';

type propType = {
  onClose: () => void;
};

const KnowledgeRepositoryForm = ({ onClose }: propType) => {
  const { id: knowledgeRepoId } = useParams();
  const userProfile = useTypedSelector(state => state?.common?.userProfile);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const [hasAnimated, setHasAnimated] = useState(false);
  const [programmeData, setProgrammeData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [activeFormType, setActiveFormType] = useState(
    'Programmatic Information',
  );

  const isContextualInformation =
    activeFormType === 'Contextual Information' ?? false;

  const knowledgeRepoFormFields = groupFormElements(
    userProfile.group_name === 'PEF User'
      ? KnowledgeRepoContextualFormFields
      : isContextualInformation
        ? KnowledgeRepoContextualFormFields
        : KnowledgeRepoFormFields,
  );

  const knowledgeRepoDropdownOptions = useTypedSelector(
    state => state.knowledgeRepository.dropdownOptions,
  );
  const {
    formState: { errors },
    register,
    getValues,
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { dirtyFields },
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: knowledgeRepoDefaultValues,
    resolver: zodResolver(
      isContextualInformation
        ? KnowledgeRepositoryConceptualValidationSchema
        : KnowledgeRepositoryValidationSchema,
    ),
  });

  const documentToDelete = watch('documentToDelete');

  const formProps = {
    register,
    control,
    getValues,
    setValue,
    errors,
    dirtyFields,
    reset,
  };

  const handleDropdownSuccess = (
    data: Record<string, any>[],
    key: string,
    labelKey: string,
  ) => {
    dispatch(
      setDropdownOptionsList({
        key,
        value: prepareDataForDropdown(data, labelKey),
      }),
    );
  };

  function transformProgramsKeys(programs: any[]): IAccordianDropDownData[] {
    return programs.map(program => {
      return {
        id: program.program__id,
        name: program.program__name,
        label: program.program__name, // Add the 'label' property
        subCategories: program.subcategories.map((subcategory: any) => ({
          id: subcategory.id,
          label: subcategory.label,
        })),
      };
    });
  }

  // If edit, fetch previously added data
  const { isLoading: isKnowledgeRepoFetching } = useQuery({
    enabled: !!knowledgeRepoId,
    queryKey: ['knowledgerepo-form-data'],
    queryFn: () => getKnowledgeRepositoryById(knowledgeRepoId || ''),
    onError: () =>
      toast.error('Failed to fetch knowledge repository form data'),
    select: data => data?.data,
    onSuccess: data => {
      Object.keys(data)?.forEach((key: any) => {
        setValue(key, data[key]);
      });
    },
  });
  const { isLoading: isKnowledgeRepoDocumentFetching } = useQuery({
    enabled: !!knowledgeRepoId,
    queryKey: ['knowledgerepo-document-data'],
    queryFn: () => getKnowledgeRepositoryDocById(knowledgeRepoId || ''),
    onError: () =>
      toast.error('Failed to fetch knowledge repository documents'),
    select: data => {
      const response: knowledgeRepoDocumentResponseType[] = data?.data;
      const updatedDocumentResponse = response?.map(file => {
        return {
          id: file.id,
          file: {
            name: getFileNameFromURL(file.file),
          },
          previewURL: file.file,
        };
      });
      return updatedDocumentResponse;
    },
    onSuccess: data => {
      setValue('documents', data);
    },
  });

  // Dropdown options data fetch
  // const { isLoading: isProgramLoading } = useQuery({
  //   queryKey: ['knowledgerepo-program-options'],
  //   queryFn: () => getProgrammeOptions(),
  //   onError: () => toast.error('Failed to fetch programme options'),
  //   select: data => data?.data,
  //   onSuccess: data => {
  //     handleDropdownSuccess(data, 'associate_program', 'name');
  //   },
  // });
  // const { isLoading: isComponentLoading } = useQuery({
  //   queryKey: ['knowledgerepo-component-options'],
  //   queryFn: () => getComponentOptions(),
  //   onError: () => toast.error('Failed to fetch component options'),
  //   select: data => data?.data,
  //   onSuccess: data => {
  //     handleDropdownSuccess(data, 'associate_project', 'name');
  //   },
  // });
  const { isLoading: isThematicFieldLoading } = useQuery({
    queryKey: ['knowledgerepo-thematicfield-options'],
    queryFn: () => getThematicFieldOptions(),
    onError: () => toast.error('Failed to fetch thematic field options'),
    select: data => data?.data,
    onSuccess: data => {
      handleDropdownSuccess(data, 'thematic_field', 'name');
    },
  });
  const { isLoading: isFileTypeLoading } = useQuery({
    queryKey: ['knowledgerepo-filetype-options'],
    queryFn: () => getChoiceDropdownList('file_type'),
    onError: () => toast.error('Failed to fetch file type options'),
    select: data => {
      const response = data?.data;
      const updatedData = response?.map((datax: Record<string, any>) => {
        return {
          id: datax.name,
          name: datax.name,
        };
      });
      return updatedData;
    },
    onSuccess: data => {
      handleDropdownSuccess(data, 'file_type', 'name');
    },
  });
  const { isLoading: isReleaseTypeLoading } = useQuery({
    queryKey: ['knowledgerepo-releasetype-options'],
    queryFn: () => getChoiceDropdownList('release_type'),
    onError: () => toast.error('Failed to fetch file type options'),
    select: data => {
      const response = data?.data;
      const updatedData = response?.map((datax: Record<string, any>) => {
        return {
          id: datax.name,
          name: datax.name,
          value: datax.name,
          label: datax.name,
        };
      });
      return updatedData;
    },
    onSuccess: data => {
      handleDropdownSuccess(data, 'release_type', 'name');
    },
  });
  const { isLoading: isProvinceLoading } = useQuery({
    queryKey: ['get-province-list'],
    queryFn: () => getProvinceList(),
    select: data =>
      data?.data?.filter((province: Record<string, any>) =>
        includeProvinces?.includes(province.name),
      ),

    onSuccess: data => {
      handleDropdownSuccess(data, 'province', 'name');
    },
  });

  const { data: projectProgrammeData, isLoading: isProjectProgrammeLoading } =
    useQuery({
      queryKey: ['get-project-programme-list'],
      queryFn: () => getProgrammeProjectsDropDown(),
      select: res => transformProgramsKeys(res.data),
    });

  const getDirtyFieldValues = () => {
    const allValues = getValues();
    const dirtyValues: any = {};
    Object.keys(allValues).forEach((key: string) => {
      if (dirtyFields[key as keyof typeof dirtyFields]) {
        dirtyValues[key] = allValues[key as keyof typeof dirtyFields];
      }
    });

    return dirtyValues;
  };

  // Patch knowledge repository
  const {
    mutateAsync: patchKnowledgeRepositoryData,
    isLoading: isPatchKnowledgeRepositoryDataLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      patchKnowledgeRepository(knowledgeRepoId || '', payloadData),
    onError: () =>
      toast.error('Failed to update knowledge repository. Try again'),
  });

  // Post knowledge repository
  const {
    mutateAsync: postKnowledgeRepositoryData,
    isLoading: isPostKnowledgeRepositoryDataLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postKnowledgeRepository(payloadData),
    onError: () =>
      toast.error('Failed to create knowledge repository. Try again'),
  });

  const {
    mutateAsync: postKnowledgeRepositoryProjectsData,
    isLoading: isPostKnowledgeRepositoryProjectsLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postKnowledgeRepositoryProjects(payloadData),
    onError: () =>
      toast.error('Failed to create knowledge repository. Try again'),
  });

  // Mutation for posting knowledge repository data for programmes
  const {
    mutateAsync: postKnowledgeRepositoryProgrammesData,
    isLoading: isPostKnowledgeRepositoryProgrammesLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postKnowledgeRepositoryProgrammes(payloadData),
    onError: () =>
      toast.error('Failed to create knowledge repository. Try again'),
  });

  // Mutation for posting knowledge repository data for thematic fields
  const {
    mutateAsync: postKnowledgeRepositoryThematicFieldData,
    isLoading: isPostKnowledgeRepositoryThematicFieldLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postKnowledgeRepositoryThematicField(payloadData),
    onError: () =>
      toast.error('Failed to create knowledge repository. Try again'),
  });
  // Post knowledge repository documents
  const {
    mutateAsync: postKnowledgeRepositoryDocumentData,
    isLoading: isPostKnowledgeRepositoryDocumentLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postKnowledgeRepositoryDoc(payloadData),
    onError: () => toast.error('Failed to attach documents. Try again'),
  });

  // Delete knowledge repository documents
  const {
    mutate: deleteKnowledgeRepositoryDocument,
    isError: isDeleteDocumentError,
    error: deleteDocumentError,
    isLoading: isDeleteDocumentLoading,
  } = useMutation({
    mutationFn: async () => {
      if (!documentToDelete) return;
      await deleteKnowledgeRepositoryDoc(documentToDelete);
    },
    onSuccess: () => {
      const documentList = getValues('documents');
      const updatedDocuments = documentList?.filter(
        document => document?.id !== documentToDelete,
      );
      setValue('documents', updatedDocuments);
      toast.success('Record deleted successfully');
      setValue('documentToDelete', null);
    },
  });

  const patchKnowledgeRepo = async (formValues: knowledgeRepoFormType) => {
    if (isEmpty(formValues)) return;
    const { documents, ...values } = formValues;

    const response = isContextualInformation
      ? await patchKnowledgeRepositoryData({
          ...values,
          is_contextual_indicator: true,
        })
      : await patchKnowledgeRepositoryData(values);
    if (!documents || (documents && documents?.length === 0)) return;
    const promises = documents?.map(async (document: Record<string, any>) => {
      if (document?.file instanceof File) {
        const documentFormData = {
          file: document?.file,
          knowledge_repository: response?.data?.id,
        };
        await postKnowledgeRepositoryDocumentData(
          prepareFormData(documentFormData),
        );
      }
    });
    await Promise.all(promises);
  };

  const postKnowledgeRepo = async (formValues: knowledgeRepoFormType) => {
    if (isEmpty(formValues)) return;

    const {
      documents,
      thematic_field,
      associate_program,
      associate_project,
      ...values
    } = formValues;
    const response = isContextualInformation
      ? await postKnowledgeRepositoryData({
          knowledge_repository: { ...values },
          is_contextual_indicator: true,
        })
      : await postKnowledgeRepositoryData(values);

    if (documents?.length === 0) return;
    const promises = documents?.map(async (document: Record<string, any>) => {
      const documentFormData = {
        file: document?.file,
        knowledge_repository: response?.data?.id,
      };
      await postKnowledgeRepositoryDocumentData(
        prepareFormData(documentFormData),
      );
    });
    const programmePromises = programmeData?.map(async (programme: any) => {
      await postKnowledgeRepositoryProgrammesData({
        knowledge_repository: response?.data?.id,
        associate_program: programme,
      });
    });
    const projectPromises = projectData?.map(async (project: any) => {
      await postKnowledgeRepositoryProjectsData({
        knowledge_repository: response?.data?.id,
        project,
      });
    });
    const thematicFieldPromises = thematic_field?.map(async (field: any) => {
      await postKnowledgeRepositoryThematicFieldData({
        knowledge_repository: response?.data?.id,
        thematic_field: field,
      });
    });
    await Promise.all(promises);
    await Promise.all(programmePromises);
    await Promise.all(projectPromises);
    await Promise.all(thematicFieldPromises || []);
  };

  const handleFormSubmit = async () => {
    try {
      const values = getValues();
      const editFields = getDirtyFieldValues();
      if (knowledgeRepoId) {
        await patchKnowledgeRepo(editFields);
      } else {
        await postKnowledgeRepo(values);
      }

      toast.success(
        knowledgeRepoId
          ? 'Knowledge repository updated successfully'
          : 'Knowledge repository added successfully ',
      );
      queryClient.invalidateQueries({
        queryKey: ['knowledge-repository-tabledata'],
      });
      onClose();
      reset();
    } catch (error) {
      toast.error('Error occured while saving!');
    }
  };

  const isDropdownOptionsLoading =
    isProvinceLoading ||
    isThematicFieldLoading ||
    (knowledgeRepoId &&
      (isKnowledgeRepoFetching || isKnowledgeRepoDocumentFetching)) ||
    isFileTypeLoading ||
    isProjectProgrammeLoading ||
    isReleaseTypeLoading;

  const isSaveBtnLoading =
    isPostKnowledgeRepositoryDataLoading ||
    isPostKnowledgeRepositoryDocumentLoading ||
    isPatchKnowledgeRepositoryDataLoading;

  const isSaveBtnDisabled =
    isThematicFieldLoading ||
    isPostKnowledgeRepositoryProjectsLoading ||
    isPostKnowledgeRepositoryProgrammesLoading ||
    isPostKnowledgeRepositoryThematicFieldLoading ||
    isFileTypeLoading ||
    isReleaseTypeLoading ||
    isPostKnowledgeRepositoryDataLoading ||
    isProjectProgrammeLoading ||
    isPostKnowledgeRepositoryDocumentLoading ||
    (knowledgeRepoId &&
      (isKnowledgeRepoFetching || isKnowledgeRepoDocumentFetching)) ||
    isPatchKnowledgeRepositoryDataLoading;

  const props = useSpring({
    from: { scale: 0 },
    to: { scale: 1 },
    config: { duration: 100 }, // Adjust duration as needed
    onRest: () => {
      if (!hasAnimated) {
        setHasAnimated(true); // Set it to true after the animation
      }
    },
  });

  useEffect(() => {
    setHasAnimated(false); // Reset before starting
  }, []);

  useEffect(() => {
    if (pathname?.includes('contextual')) {
      setActiveFormType('Contextual Information');
    }
  }, [pathname]);

  return (
    <div className="naxatw-absolute naxatw-left-1/2 naxatw-top-1/2 naxatw-flex naxatw-w-[90%] naxatw-translate-x-[-50%] naxatw-translate-y-[calc(-50%+31.5px)] naxatw-items-center naxatw-justify-center sm:naxatw-max-w-[34.75rem] 2xl:naxatw-max-w-[40%]">
      <animated.div
        className="naxatw-flex naxatw-w-full naxatw-flex-col naxatw-rounded-2xl naxatw-border naxatw-border-gray-300 naxatw-bg-[#fff] naxatw-transition-all naxatw-duration-200 "
        style={{
          transform: props.scale.to(scale => `scale(${scale})`),
        }}
      >
        <div className="naxatw-flex naxatw-items-center naxatw-justify-between naxatw-self-stretch naxatw-px-7 naxatw-py-5 naxatw-shadow-[0px_2px_20px_4px_rgba(0,0,0,0.12)]">
          <BreadCrumb
            heading={`Knowledge Repository ${knowledgeRepoId ? 'Edit' : 'Add'} Form`}
            overlayStatus={() => onClose()}
          />
          <IconButton
            name="close"
            className="!naxatw-h-9 !naxatw-w-9 naxatw-gap-1 naxatw-rounded-lg hover:naxatw-bg-gray-100"
            iconClassName="naxatw-font-normal naxatw-text-[#757575] naxatw-text-[24px] naxatw-leading-[24px]"
            onClick={() => onClose()}
          />
        </div>

        {userProfile.group_name === 'PEF User' ? (
          ''
        ) : pathname.includes('edit') ? null : (
          <SwitchTab
            options={knowledgeRepoFormTypeInformation || []}
            activeLabel={activeFormType || ''}
            wrapperClassName="!naxatw-cursor-pointer !naxatw-gap-[0rem] naxatw-mt-[1.25rem] naxatw-px-[1.5rem]"
            defaultBg={false}
            defaultBehaviour={false}
            className="naxatw-text-sm naxatw-leading-5 naxatw-text-[#484848]"
            titleClassName="naxatw-text-sm"
            onChange={label => {
              setActiveFormType(label);
            }}
          />
        )}

        <div className="naxatw-flex naxatw-w-full naxatw-gap-3 ">
          <div className="naxatw-w-full">
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <div className="naxatw-py-5 naxatw-pl-6 naxatw-pr-4">
                <FlexColumn className="scrollbar  naxatw-h-[calc(100vh-23.8rem)]  naxatw-w-full  naxatw-space-y-5 naxatw-overflow-y-scroll naxatw-pr-2">
                  {isDropdownOptionsLoading ? (
                    <FormSkeleton numRows={4} className="!naxatw-w-full" />
                  ) : (
                    knowledgeRepoFormFields?.map(
                      (fieldRow: Record<string, any>, i) => (
                        <FlexRow key={i}>
                          {fieldRow?.map((field: FormFieldProps) => {
                            const { id } = field;
                            if (field.id === 'associate_program') {
                              return (
                                <FormControl
                                  className="naxatw-w-full naxatw-gap-[0.5rem] "
                                  key={`${field.id}- ${field.name}`}
                                >
                                  <InputLabel
                                    label={field.label}
                                    // tooltipMessage={field.label}
                                    astric={field.required}
                                    id={field.id}
                                  />
                                  <AccordianDropDown
                                    options={projectProgrammeData || []}
                                    className="naxatw-h-1/2 naxatw-w-full"
                                    enableSearchbar={false}
                                    placeholder={`${programmeData.length} Programmes & ${projectData.length} Projects`}
                                    value={programmeData}
                                    subCategoriesValue={projectData}
                                    onChange={value => {
                                      setProgrammeData(value);
                                    }}
                                    subCategoriesOnChange={value => {
                                      setProjectData(value);
                                    }}
                                  />
                                </FormControl>
                              );
                            }
                            return (
                              <FormControl
                                className="naxatw-w-full naxatw-gap-[0.5rem] "
                                key={`${field.id}- ${field.name}`}
                              >
                                <InputLabel
                                  label={field.label}
                                  // tooltipMessage={field.label}
                                  astric={field.required}
                                  id={field.id}
                                />
                                {getInputElement(
                                  {
                                    ...field,
                                    id: `${field.id}`,
                                  },
                                  /* @ts-ignore */
                                  formProps,
                                  knowledgeRepoDropdownOptions?.[id] || [],
                                )}
                                <>
                                  {
                                    /* @ts-ignore */
                                    formProps.errors[id] && (
                                      <ErrorMessage
                                        /* @ts-ignore */
                                        message={formProps.errors[id]?.message}
                                      />
                                    )
                                  }
                                </>
                              </FormControl>
                            );
                          })}
                        </FlexRow>
                      ),
                    )
                  )}
                </FlexColumn>
              </div>

              <div className="naxatw-flex naxatw-justify-center naxatw-py-5 naxatw-shadow-formshadow">
                <Button
                  size="sm"
                  variant="primary"
                  className="naxatw-px-4 naxatw-py-2"
                  type="submit"
                  isLoading={isSaveBtnLoading}
                  disabled={isSaveBtnDisabled}
                >
                  Save
                </Button>
              </div>
            </form>
          </div>
        </div>
        {documentToDelete && (
          <Portal>
            <div className="naxatw-absolute naxatw-left-0 naxatw-top-0 naxatw-h-[100vh] naxatw-w-[100vw] naxatw-bg-black naxatw-opacity-30" />
            <DeleteConfirmationOverlay
              onClose={() => setValue('documentToDelete', null)}
              onDelete={() => deleteKnowledgeRepositoryDocument()}
              isError={isDeleteDocumentError}
              isLoading={isDeleteDocumentLoading}
              error={deleteDocumentError}
            />
          </Portal>
        )}
      </animated.div>
    </div>
  );
};

export default hasErrorBoundary(KnowledgeRepositoryForm);
