import { z } from 'zod';

export const UserManagementValidationSchema = z
  .object({
    id: z.number().optional().nullable(),
    first_name: z.string().trim().min(1, 'First Name is Required'),
    last_name: z.string().trim().min(1, 'Last Name is Required'),
    email: z.string().email('Invalid email'),
    username: z.string().trim().min(1, 'Username is Required'),
    password: z.string().optional().nullable(),
    confirm_password: z.string().optional().nullable(),
    program_project: z.any().optional().nullable(),
    role: z
      .number()
      .int()
      .min(1, 'Role is required')
      .nullable()
      .refine(value => value !== null, {
        message: 'Role is required',
      }),
  })
  .refine(
    data => {
      if (!data.id) {
        if (data.password?.trim().length === 0) {
          return false;
        }
      }
      return true;
    },
    {
      message: 'Password is required.',
      path: ['password'],
    },
  )
  .refine(
    data => {
      if (!data.id) {
        if ((data.password?.trim()?.length as number) < 6) {
          return false;
        }
      }
      return true;
    },
    {
      message: 'Password must be greater than 5 characters.',
      path: ['password'],
    },
  )

  .refine(
    data => {
      if (!data.id) {
        if (data.confirm_password?.trim().length === 0) {
          return false;
        }
      }
      return true;
    },
    {
      message: 'Confirm Password is required.',
      path: ['confirm_password'],
    },
  )
  .refine(
    data => {
      if (data.password === data.confirm_password) {
        return true;
      }
      return false;
    },
    {
      message: 'Password and confirm password does not match',
      path: ['confirm_password'],
    },
  );

export type UserManagementValidationSchemaProps = z.infer<
  typeof UserManagementValidationSchema & {
    [key: string]: any;
  }
>;
