/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import DataTable from '@Components/common/DataTable';
import { Button } from '@Components/RadixComponents/Button';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { useNavigate, useParams } from 'react-router-dom';
import { getProgrammeDetails } from '@Services/provincialProfile';
import { useQuery } from '@tanstack/react-query';
import Skeleton from '@Components/RadixComponents/Skeleton';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';

const ComponentsTable = () => {
  const navigate = useNavigate();
  const { programmeId } = useParams();

  const { data: programmeDetails, isLoading: programmeDetailsIsLoading } =
    useQuery({
      queryKey: ['programme-details-table-data'],
      queryFn: async () =>
        getProgrammeDetails({
          program: programmeId,
        }),
      select: res => res.data,
    });

  const columns = [
    {
      header: 'FIRST TIER PARTNER',
      accessorKey: 'first_tier_partner',
    },
    {
      header: 'SECTOR',
      accessorKey: 'sector',
    },
    {
      header: 'WORKING Areas',
      accessorKey: 'working_province',
    },
    {
      header: 'MARKER',
      accessorKey: 'marker',
    },
    // {
    //   header: '',
    //   accessorKey: 'budget_allocated',
    // },
    {
      header: 'Budget Allocated (£)',
      accessorKey: 'budget',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ row }: any) => (
        <span>{row?.original?.budget?.toLocaleString('en-us') || '-'}</span>
      ),
    },
  ];
  const tableData = programmeDetails?.find(
    (id: any) => id?.program_id === Number(programmeId),
  );
  return (
    <>
      <div className="naxatw-rounded-xl naxatw-border naxatw-border-gray-200 naxatw-bg-white naxatw-shadow-sm">
        <div className="naxatw-flex naxatw-justify-between naxatw-px-6 naxatw-py-[1.4rem]">
          <h5 className="naxatw-font-medium naxatw-leading-7 naxatw-tracking-[-0.045rem] naxatw-text-[#0B2E62]">
            Project Details
          </h5>
          <Button variant="secondary">
            <ToolTip
              name="download"
              message="Download Data"
              className="naxatw-flex naxatw-items-center naxatw-text-xl naxatw-text-secondary-500"
            />
            Download Table Data
          </Button>
        </div>
        <div className="naxatw-h-[1px] naxatw-w-full naxatw-bg-[#EAECF0]" />
        {programmeDetailsIsLoading ? (
          <Skeleton className="naxatw-h-[15vh] naxatw-w-full" />
        ) : programmeDetails.length > 0 ? (
          <div className="naxatw-w-full naxatw-px-5 naxatw-py-3">
            <DataTable
              queryKey="programme details"
              queryFn={() => {}}
              columns={[
                {
                  header: 'PROJECT',
                  accessorKey: 'project',
                  // eslint-disable-next-line react/no-unstable-nested-components
                  cell: row => {
                    return `${Number(row?.row?.id) + 1}  ${row?.row?.original.project}`;
                  },
                },
                ...columns,
                {
                  header: '',
                  accessorKey: 'icon',
                  // eslint-disable-next-line react/no-unstable-nested-components
                  cell: ({ row }) => {
                    return (
                      <ToolTip
                        name="open_in_new"
                        className="naxatw-text-xl naxatw-text-[#41588F]"
                        message="Redirect"
                        iconClick={() => {
                          navigate(`?componentId=${row?.original?.project_id}`);
                        }}
                      />
                    );
                  },
                },
              ]}
              className="!naxatw-border-[0]"
              demoData={tableData?.data}
              isPaginated={false}
              searchInput=""
              // needSorting={false}
            />
          </div>
        ) : (
          <NoChartDataComponent />
        )}
      </div>
    </>
  );
};

export default hasErrorBoundary(ComponentsTable);
