/* eslint-disable import/prefer-default-export */

import { FormFieldProps } from '@Constants/interface/FormInterface';

export const multiStepGoalTitle: {
  id: number;
  name: string;
  value: 'goal' | 'assignGoals';
}[] = [
  { id: 1, name: 'Goal', value: 'goal' },
  { id: 2, name: 'Assign Goals', value: 'assignGoals' },
];

export const multiStepProvincialMinistryTitle: {
  id: number;
  name: string;
  value: 'ministries' | 'department';
}[] = [
  { id: 1, name: 'Ministries', value: 'ministries' },
  { id: 2, name: 'Department', value: 'department' },
];

export const provincialSummaryFormFields: FormFieldProps[] = [
  {
    label: 'Description',
    inputType: 'text',
    type: 'textArea',
    className: ' naxatw-min-h-[6.5rem]',
    placeholder: 'Description',
    id: 'description',
    name: 'description',
    required: true,
    isVisible: true,
  },
  {
    id: 'capital',
    label: 'Capital',
    inputType: 'text',
    type: 'input',
    placeholder: 'Capital',
    name: 'capital',
    required: true,
    isVisible: true,
  },
  {
    id: 'assembly',
    label: 'Provincial Assembly',
    inputType: 'text',
    type: 'input',
    placeholder: 'Provincial Assembly',
    name: 'Provincial Assembly',
    required: true,
    isVisible: true,
  },
  {
    id: 'incumbent',
    label: 'Incumbent',
    inputType: 'text',
    type: 'input',
    placeholder: 'Incumbent',
    name: 'Incumbent',
    required: true,
    isVisible: true,
  },
  {
    id: 'secretary',
    label: 'Provincial Secretary',
    inputType: 'text',
    type: 'input',
    placeholder: 'Provincial Secretary',
    name: 'Provincial  Secretary',
    required: true,
    isVisible: true,
  },
  {
    id: 'spokeperson',
    label: 'Spokesperson',
    inputType: 'text',
    type: 'input',
    placeholder: 'Spokesperson',
    name: 'Spokesperson',
    required: true,
    isVisible: true,
  },
  {
    id: 'no_of_ministries',
    label: 'Number of Ministries',
    inputType: 'text',
    type: 'input',
    placeholder: 'Number of Ministries',
    name: 'Number of Ministries',
    required: true,
    isVisible: true,
  },
  {
    id: 'update',
    label: 'Updates',
    className: ' naxatw-min-h-[6.5rem]',
    inputType: 'text',
    type: 'textArea',
    placeholder: 'Updates',
    name: 'Updates',
    required: true,
    isVisible: true,
  },
];

export const includeProvinicialMinstry = [
  'Sudurpashchim Province',
  'Gandaki Province',
  'Koshi Province',
  'Lumbini Province',
  'Karnali Province',
  'Madhesh Province',
  'Bagmati Province',
  'Federal',
];

export const includeAllProvincial = [
  'Lumbini Province',
  'Karnali Province',
  'Madhesh Province',
];

export function getFiscalYearOptions(noOfYears: number = 10) {
  const currentDay = new Date().getDate();
  const currentMonth = new Date().getMonth();
  let currentYear = new Date().getFullYear();

  if (currentMonth >= 6 && currentDay >= 16) {
    currentYear += 1;
  }

  const years = [];

  for (let i = 0; i < noOfYears; i++) {
    const startYear = currentYear - i;
    const endYear = startYear + 1;
    years.push({
      label: `${startYear}/${String(endYear).slice(2)}`,
      value: String(startYear),
    });
  }

  return years;
}
