/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useQuery } from '@tanstack/react-query';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { useNavigate } from 'react-router-dom';
import { downloadMapPopUpData, getPopupData } from '@Services/mainDashboard';
import { cn } from '@Utils/index';
import capitalizeFirstLetter from '@Utils/capitalizeFirstLetter';
import { Accordion } from '@radix-ui/react-accordion';
import { useEffect, useState } from 'react';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { Button } from '@Components/RadixComponents/Button';
import { useTypedSelector } from '@Store/hooks';
import Icon from '@Components/common/Icon';
import PopupAccordion from './Accordion';

const focalProvince = [
  { name: 'Karnali Province', id: 6 },
  { name: 'Madhesh Province', id: 2 },
  { name: 'Lumbini Province', id: 5 },
];

export default function MapPopup({
  hoveredMunicipality,
  selectedKey = 'municipality',
  queryParams,
  className,
}: {
  hoveredMunicipality: Record<string, any> | null;
  selectedKey?: string;
  queryParams?: Record<string, any>;
  className?: string;
}) {
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  const [visible, setIsVisible] = useState(false);

  // show filter
  const programmeFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.program,
  );
  const componentFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.component,
  );
  const firstTierPartnerParams = useTypedSelector(
    state => state.mainDashboard.filterParams.firstTierPartner,
  );
  const sectorGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.sector,
  );
  const markerGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.markerGroup,
  );
  const subMarkersParams = useTypedSelector(
    state => state.mainDashboard.filterParams.subMarkers,
  );

  const { data: popUpData, isFetching: isPopupLoading } = useQuery({
    queryKey: [
      'bek-popup-data',
      hoveredMunicipality?.id,
      selectedKey,
      // filter
      programmeFilterParams,
      componentFilterParams,
      firstTierPartnerParams,
      sectorGroupParams,
      markerGroupParams,
      subMarkersParams,
    ],
    queryFn: async () =>
      getPopupData({
        by: selectedKey,
        [selectedKey]: hoveredMunicipality?.id,
        // filter
        program: programmeFilterParams?.join(','),
        project: componentFilterParams?.join(','),
        first_tier_partner: firstTierPartnerParams?.join(','),
        sector: sectorGroupParams?.join(','),
        marker: markerGroupParams?.join(','),
        marker_category: subMarkersParams?.join(','),
        ...queryParams,
      }),
    enabled: !!hoveredMunicipality?.id,
    select: res => res?.data,
  });

  // download csv data
  const { refetch: downloadCsvPopup } = useQuery({
    queryKey: ['download-map-csv-data'],
    queryFn: async () =>
      downloadMapPopUpData(selectedKey, hoveredMunicipality?.id),
    enabled: false,
    onSuccess: response => {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(response?.data);
      a.download = `${selectedKey}-data.csv`;
      a.click();
    },
  });

  const getSelectedProvinceId = (name: string) => {
    return focalProvince?.find(province => province.name === name)?.id;
  };

  useEffect(() => {
    if (!hoveredMunicipality) {
      setIsVisible(false);
      return;
    }
    setIsVisible(true);
  }, [hoveredMunicipality]);
  if (!hoveredMunicipality?.id) {
    return <></>;
  }

  return (
    <div
      className={cn(
        className,
        `actions ${
          visible ? 'enter-active' : 'exit-active'
        } scrollbar naxatw-absolute naxatw-right-4 naxatw-top-[2%] naxatw-z-[11] naxatw-max-h-[calc(100%-0.6rem)] naxatw-w-[25rem] naxatw-gap-4  naxatw-overflow-y-auto naxatw-rounded-[4px] naxatw-border-[1px] naxatw-border-[#EAECF0] naxatw-bg-white naxatw-p-3 naxatw-text-[#475467]`,
      )}
    >
      {isPopupLoading ? (
        <div className=" naxatw-mt-2 naxatw-flex naxatw-flex-col naxatw-gap-1">
          <Skeleton className=" naxatw-h-5 naxatw-w-full" />
          <Skeleton className=" naxatw-h-5 naxatw-w-full" />
          <Skeleton className=" naxatw-h-5 naxatw-w-full" />
          <Skeleton className=" naxatw-h-5 naxatw-w-full" />
          <Skeleton className=" naxatw-h-5 naxatw-w-full" />
        </div>
      ) : (
        <FlexColumn className="naxatw-flex naxatw-flex-col naxatw-gap-1">
          {popUpData?.length === 0 ? (
            <p>No data found</p>
          ) : (
            <FlexColumn className="naxatw-border-b">
              <FlexRow className="naxatw-items-center">
                <FlexRow className="naxatw-flex naxatw-w-full naxatw-items-center naxatw-gap-2">
                  <div className="naxatw-h-[0.625rem] naxatw-w-[0.625rem] naxatw-rounded-full naxatw-bg-[#5DB8B2]" />
                  <p className="naxatw-text-[0.875rem] naxatw-font-bold naxatw-text-[#5DB8B2]">
                    {capitalizeFirstLetter(popUpData?.[0].name || '')}
                  </p>
                </FlexRow>
                <div
                  className="download-btn"
                  onClick={() => downloadCsvPopup()}
                >
                  <Icon
                    name="download_2"
                    className="naxatw-text-xl naxatw-text-[#212121]"
                  />
                </div>
              </FlexRow>
              <p className="naxatw-text-xs naxatw-font-medium naxatw-tracking-[0.0375rem] naxatw-text-[#475467]">
                {popUpData?.[0].summary?.program || 0} Programmes with{' '}
                {popUpData?.[0].summary?.project || 0} Projects across{' '}
                {popUpData?.[0].summary?.sector || 0} Sectors
                {/* , Allocated budget: £ */}
                {/* {convertToCurrencySystem(popUpData[0].summary?.budget || 0)} */}
              </p>
            </FlexColumn>
          )}
          <div className="scrollbar naxatw-h-fit naxatw-max-h-[35vh] naxatw-overflow-y-scroll naxatw-px-2 naxatw-py-2">
            {popUpData?.[0]?.programs?.length > 0 && (
              <>
                <Accordion type="single" collapsible>
                  {popUpData?.[0].programs?.map(
                    (program: any, index: number) => (
                      <PopupAccordion
                        key={program?.program_id}
                        title={program?.program_name}
                        data={program?.data}
                        listNumber={index + 1}
                      />
                    ),
                  )}
                </Accordion>
              </>
            )}
          </div>
          {popUpData[0]?.programs?.length > 0 &&
            focalProvince
              ?.map(prov => prov.name)
              ?.includes(popUpData?.[0]?.name) && (
              <div className="naxatw-flex naxatw-justify-center naxatw-py-2">
                <Button
                  variant="secondary"
                  color="primary"
                  className="naxatw-text-sm"
                  onClick={() =>
                    navigate(
                      `/explore-by/geography/${getSelectedProvinceId(popUpData?.[0]?.name)}`,
                    )
                  }
                >
                  View More Detail
                </Button>
              </div>
            )}
        </FlexColumn>
      )}
    </div>
  );
}
