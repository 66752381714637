import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { FlexColumn } from '@Components/common/Layouts';
import { useNavigate } from 'react-router-dom';
import ProvincialCommonCard from '../ProvincialCommonCard';

const ProvincialSummary = ({
  setToggleOn,
  selectedLocationId,
  provincialSummaryList,
  location,
}: any) => {
  const navigate = useNavigate();

  const {
    description,
    capital,
    incumbent,
    secretary,
    assembly,
    spokeperson,
    update,
    no_of_ministries: ministries,
  } = provincialSummaryList;

  return (
    <ProvincialCommonCard
      title="Provincial Summary"
      redirect={() => {
        setToggleOn();
        navigate(
          `/data-bank/provincial-profile/summary/edit/${selectedLocationId}?location=${location}`,
        );
      }}
    >
      <FlexColumn gap={5}>
        <div className="decription naxatw-text-grey-800">
          <p className="naxatw-mb-1 naxatw-text-xs naxatw-font-bold naxatw-tracking-[-0.015rem] ">
            Description
          </p>
          <p className="naxatw-text-[0.875rem] naxatw-font-normal naxatw-leading-5">
            {description || '-'}
          </p>
        </div>
        {/* ---- */}
        <div className="naxatw-grid naxatw-grid-cols-2 naxatw-gap-5">
          <div className="naxatw-flex naxatw-flex-col naxatw-gap-[0.625rem]">
            <p className="naxatw-text-xs naxatw-font-bold naxatw-tracking-[-0.015rem] naxatw-text-matt-100 ">
              Capital
            </p>
            <p className="naxatw-text-[0.875rem] naxatw-font-normal naxatw-leading-5 naxatw-text-gray-800">
              {capital || '-'}
            </p>
          </div>
          <div className="naxatw-flex naxatw-flex-col naxatw-gap-[0.625rem]">
            <p className="naxatw-text-xs naxatw-font-bold naxatw-tracking-[-0.015rem] naxatw-text-matt-100 ">
              Provincial Assembly
            </p>
            <p className="naxatw-text-[0.875rem] naxatw-font-normal naxatw-leading-5 naxatw-text-gray-800">
              {assembly || '-'}
            </p>
          </div>
          <div className="naxatw-flex naxatw-flex-col naxatw-gap-[0.625rem]">
            <p className="naxatw-text-xs naxatw-font-bold naxatw-tracking-[-0.015rem] naxatw-text-matt-100 ">
              Incumbent
            </p>
            <p className="naxatw-text-[0.875rem] naxatw-font-normal naxatw-leading-5 naxatw-text-gray-800">
              {incumbent || '-'}
            </p>
          </div>
          <div className="naxatw-flex naxatw-flex-col naxatw-gap-[0.625rem]">
            <p className="naxatw-text-xs naxatw-font-bold naxatw-tracking-[-0.015rem] naxatw-text-matt-100 ">
              Principal Secretary
            </p>
            <p className="naxatw-text-[0.875rem] naxatw-font-normal naxatw-leading-5 naxatw-text-gray-800">
              {secretary || '-'}
            </p>
          </div>
          <div className="naxatw-flex naxatw-flex-col naxatw-gap-[0.625rem]">
            <p className="naxatw-text-xs naxatw-font-bold naxatw-tracking-[-0.015rem] naxatw-text-matt-100 ">
              Spokesperson
            </p>
            <p className="naxatw-text-[0.875rem] naxatw-font-normal naxatw-leading-5 naxatw-text-gray-800">
              {spokeperson || '-'}
            </p>
          </div>
          <div className="naxatw-flex naxatw-flex-col naxatw-gap-[0.625rem]">
            <p className="naxatw-text-xs naxatw-font-bold naxatw-tracking-[-0.015rem] naxatw-text-matt-100 ">
              Number of Ministries
            </p>
            <p className="naxatw-text-[0.875rem] naxatw-font-normal naxatw-leading-5 naxatw-text-gray-800">
              {ministries || '-'}
            </p>
          </div>
        </div>

        {/* ----- */}

        <div className="updates naxatw-text-grey-800">
          <p className="naxatw-mb-1 naxatw-text-xs naxatw-font-bold naxatw-tracking-[-0.015rem] ">
            Updates
          </p>
          <p className="naxatw-text-[0.875rem] naxatw-font-normal naxatw-leading-5">
            {update || '-'}
          </p>
        </div>
      </FlexColumn>
    </ProvincialCommonCard>
  );
};

export default hasErrorBoundary(ProvincialSummary);
