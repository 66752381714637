/* eslint-disable no-nested-ternary */
import { useQuery } from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  getProgrammeDataByProgrammeId,
  getComponentDetailCount,
} from '@Services/provincialProfile';
import Skeleton from '@Components/RadixComponents/Skeleton';
import TopHeader from './TopHeader';
import SubHeader from './SubHeader';

const ProgrammeDetailsHeader = () => {
  const { programmeId } = useParams();
  const [searchParams] = useSearchParams();
  const componentId = searchParams.get('componentId');

  const { data: programmeDataList, isLoading: programmeDataIsLoading } =
    useQuery({
      queryKey: ['programmeList', programmeId],
      queryFn: async () =>
        getProgrammeDataByProgrammeId({ program: programmeId }),
      // enabled: !!provinceId || !!searchedText,
      select: res => res.data[0],
      enabled: !!programmeId,
    });

  const { data: componentDataList, isLoading: componentDataIsLoading } =
    useQuery({
      queryKey: ['componentList', programmeId, componentId],
      queryFn: async () =>
        getComponentDetailCount({
          program: programmeId,
          project: componentId,
        }),
      enabled: !!componentId,
      select: res => res.data[0],
    });
  return (
    <>
      {programmeDataIsLoading || (componentId && componentDataIsLoading) ? (
        <Skeleton className="naxatw-h-[4rem] naxatw-w-full" />
      ) : (
        <>
          <TopHeader
            title={
              !componentId
                ? programmeDataList?.program_name || "Programme's Name"
                : componentDataList?.project_name || "Projects's Name"
            }
          />
          <SubHeader
            id={
              !componentId
                ? programmeDataList?.program_code || programmeId
                : componentDataList?.project_code || componentId
            }
            status={programmeDataList?.program_status || 'Ongoing'}
            allocatedBudget={
              !componentId
                ? programmeDataList?.allocated_budget
                : componentDataList?.allocated_budget
            }
            componentCount={!componentId ? programmeDataList?.project_count : 0}
            sectorCount={
              !componentId
                ? programmeDataList?.sector_count || 0
                : componentDataList?.sector_count || 0
            }
          />
        </>
      )}
    </>
  );
};

export default ProgrammeDetailsHeader;
