/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef } from 'react';
import { cn } from '@Utils/index';
import {
  IAccordianDropDownData,
  IAccordianDropDownProps,
  IAccordianSubCategoriesData,
  IComboBoxProps,
  IDropDownData,
} from '@Constants/interface';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@Components/RadixComponents/Accordion';
import { v4 as uuid } from 'uuid';
import Icon from '@Components/common/Icon';
import { Button } from '@Components/RadixComponents/Button';
import {
  Command,
  CommandGroup,
  CommandItem,
} from '@Components/RadixComponents/Command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@Components/RadixComponents/Popover';
import { findParentIdsBySubcategoryIds } from '@Utils/findParentIdsWithChildIds';
import useDebouncedInput from '@Hooks/useDebouncedInput';
import hasErrorBoundary from '../hasErrorBoundary';
import Searchbar from '../SearchBar';
import { FlexRow } from '../Layouts';

function AccordianDropDown({
  options = [],
  multiple = false,
  choose = 'id',
  value,
  placeholder,
  onChange,
  onFocus,
  id,
  className,
  disabled,
  isLoading = false,
  dropDownSize = 'drop-lg',
  placeholderClassName,
  hasLeftIcon,
  leftIconName,
  lengthOfOptions = 0,
  popoverClassName,
  style,
  subCategoriesOnChange,
  subCategoriesValue,
  // if checkBox is true then it will show checkbox in dropdown by default it is false
  checkBox = false,
  enableSearchbar = true,
}: IAccordianDropDownProps) {
  const [open, setOpen] = React.useState(false);
  const [multipleAccordion, setMultipleAccordion] = React.useState<string[]>(
    [],
  );
  // const [limitError, setLimitError] = React.useState<boolean>(false);
  const [searchText, setSearchText] = React.useState('');
  const [filteredOptionsData, setFilteredOptionsData] = React.useState<any>([]);
  const [dropDownWidth, setDropDownWidth] = React.useState<number | undefined>(
    0,
  );

  const handleSelect = (
    currentValue: any,
    valueVarName: any,
    functionName?: (e: any) => void,
  ) => {
    if (onFocus) onFocus();

    // if (multiple) {
    const selectedValues = Array.isArray(valueVarName) ? [...valueVarName] : [];
    const selectedIndex = selectedValues.indexOf(currentValue);
    if (selectedIndex === -1) {
      selectedValues.push(currentValue);
    } else {
      selectedValues.splice(selectedIndex, 1);
    }
    if (functionName) {
      // if there is limit of selection

      if (lengthOfOptions !== 0) {
        if (selectedValues.length > lengthOfOptions) {
          return;
        }
      }
      functionName(selectedValues);
    }
  };
  // const handleSubCategoriesSelection = (currentValue: any) => {
  //   if (onFocus) onFocus();

  //   // if (multiple) {
  //   const selectedValues = Array.isArray(value) ? [...value] : [];
  //   const selectedIndex = selectedValues.indexOf(currentValue);
  //   if (selectedIndex === -1) {
  //     selectedValues.push(currentValue);
  //   } else {
  //     selectedValues.splice(selectedIndex, 1);
  //   }
  //   if (onChange) {
  //     // if there is limit of selection

  //     if (lengthOfOptions !== 0) {
  //       if (selectedValues.length > lengthOfOptions) {
  //         return;
  //       }
  //     }
  //     onChange(selectedValues);
  //   }
  // };

  // for search
  const [searchTextData, handleChangeData] = useDebouncedInput({
    ms: 400,
    init: searchText,
    onChange: debouncedEvent => setSearchText(debouncedEvent.target.value),
  });

  const triggerRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    setDropDownWidth(triggerRef.current?.clientWidth);
  }, [triggerRef.current?.clientWidth]);

  useEffect(() => {
    const filteredOptions = options?.filter(option =>
      option?.label?.toLowerCase()?.includes(searchText),
    );
    setFilteredOptionsData(filteredOptions);
  }, [searchText]);

  useEffect(() => {
    setFilteredOptionsData(options);
  }, [options]);

  useEffect(() => {
    const checkedSubCategories = findParentIdsBySubcategoryIds(
      options,
      subCategoriesValue as any[],
    );
    setMultipleAccordion(
      checkedSubCategories.map(
        (checkedAccordian: any) => `item-${checkedAccordian}`,
      ),
    );
  }, [subCategoriesValue]);

  return (
    // the component is not complete yet, some features are not implemented
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild ref={triggerRef}>
        <Button
          id={id}
          variant="dropDown"
          size={dropDownSize}
          role="combobox"
          disabled={disabled}
          aria-expanded={open}
          className={cn(
            'naxatw-group naxatw-flex naxatw-items-center naxatw-justify-between naxatw-gap-2 naxatw-bg-white hover:naxatw-border-secondary-500 disabled:!naxatw-cursor-not-allowed',
            className,
          )}
          onClick={() => setOpen(true)}
        >
          <>
            <div className="naxatw-flex naxatw-items-center naxatw-justify-center naxatw-gap-2">
              {hasLeftIcon && (
                <Icon
                  name={leftIconName ?? ''}
                  className="naxatw-flex naxatw-h-6 naxatw-w-6 naxatw-shrink-0 naxatw-items-center naxatw-justify-center naxatw-text-[24px] naxatw-font-light naxatw-leading-6 naxatw-text-matt-200 naxatw-duration-200 group-hover:naxatw-text-secondary-500"
                />
              )}

              <p
                className={cn(
                  'naxatw-body-sm  naxatw-line-clamp-1  naxatw-text-start naxatw-text-matt-200',
                  placeholderClassName,
                )}
              >
                {placeholder || 'Choose'}
              </p>
            </div>
            <Icon
              name="expand_more"
              className="naxatw-flex naxatw-h-6 naxatw-w-6 naxatw-shrink-0 naxatw-items-center naxatw-justify-center naxatw-text-[24px] naxatw-font-light naxatw-leading-6 naxatw-text-matt-200 naxatw-duration-200 group-hover:naxatw-text-secondary-500"
            />
          </>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className={` ${popoverClassName} naxatw-bg-white naxatw-p-[0px]`}
        style={{ width: `${dropDownWidth}px`, ...style }}
      >
        {enableSearchbar ? (
          <Searchbar
            value={searchTextData}
            onChange={handleChangeData}
            isSmall
            className="searchbar-dropdown !naxatw-py-3 focus:!naxatw-border-grey-600"
            wrapperStyle="!naxatw-h-auto"
          />
        ) : null}
        <div className="scrollbar search-scrollbar naxatw-block naxatw-max-h-[12rem] naxatw-overflow-y-auto">
          <Command className="naxatw-m-0 naxatw-p-0">
            {isLoading && <p>Loading ...</p>}
            <CommandGroup className="">
              <Accordion
                type="multiple"
                defaultValue={multipleAccordion}
                // className="naxatw-py-1"
                // collapsible
              >
                {options.map((option, index) => {
                  return (
                    <AccordionItem
                      value={`item-${option.id}`}
                      key={uuid()}
                      // className="naxatw-py-1"
                    >
                      {/* if the sub category is checked then the accordian trigger is disabled */}
                      <AccordionTrigger
                        className="naxatw-min-h-[1.7rem] !naxatw-py-0 hover:!naxatw-no-underline"
                        onClick={e =>
                          multipleAccordion.includes(`item-${option.id}`)
                            ? e.preventDefault()
                            : null
                        }
                      >
                        <CommandItem
                          key={uuid()}
                          onSelect={() =>
                            handleSelect(
                              option[choose as keyof IAccordianDropDownData],
                              value,
                              onChange,
                            )
                          }
                          className=" naxatw-flex naxatw-w-full naxatw-items-center naxatw-gap-[0.15rem]"
                        >
                          <FlexRow className="naxatw-w-full naxatw-items-center naxatw-gap-2 naxatw-px-3">
                            <Icon
                              name={
                                Array.isArray(value) &&
                                value.includes(
                                  option[
                                    choose as keyof IAccordianDropDownData
                                  ],
                                )
                                  ? 'check_box'
                                  : 'check_box_outline_blank'
                              }
                              className="naxatw-text-[20px]"
                            />

                            <FlexRow className="naxatw-w-full naxatw-items-center naxatw-justify-between">
                              <p className="naxatw-text-sm naxatw-font-medium naxatw-leading-5 naxatw-tracking-[0.00625rem] naxatw-text-matt-100">
                                {option.label}
                              </p>
                              <Icon
                                name="expand_more"
                                className="naxatw-text-base"
                              />
                            </FlexRow>
                          </FlexRow>
                        </CommandItem>
                      </AccordionTrigger>
                      <AccordionContent className="naxatw-flex naxatw-items-start">
                        {option.subCategories.map((subData, subIndex) => {
                          return (
                            <CommandItem
                              key={uuid()}
                              onSelect={() =>
                                handleSelect(
                                  subData[
                                    choose as keyof IAccordianSubCategoriesData
                                  ],
                                  subCategoriesValue,
                                  subCategoriesOnChange,
                                )
                              }
                              className=" naxatw-flex naxatw-w-full naxatw-flex-col !naxatw-items-start "
                            >
                              <FlexRow
                                className=" naxatw-items-center naxatw-gap-1 naxatw-px-8"
                                key={uuid()}
                              >
                                <Icon
                                  name={
                                    Array.isArray(subCategoriesValue) &&
                                    subCategoriesValue.includes(
                                      subData[
                                        choose as keyof IAccordianSubCategoriesData
                                      ],
                                    )
                                      ? 'check_box'
                                      : 'check_box_outline_blank'
                                  }
                                  className="naxatw-text-[20px]"
                                />

                                <p className="naxatw-text-wrap naxatw-text-xs naxatw-font-medium naxatw-leading-5 naxatw-tracking-[0.00625rem] naxatw-text-matt-100">
                                  {subData.label}
                                </p>
                              </FlexRow>
                            </CommandItem>
                          );
                        })}
                      </AccordionContent>
                    </AccordionItem>
                  );
                })}
              </Accordion>
            </CommandGroup>
          </Command>
        </div>
      </PopoverContent>
    </Popover>
  );
}

export default hasErrorBoundary(AccordianDropDown);
