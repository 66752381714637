import { FormFieldProps } from '@Constants/interface/FormInterface/index';

export const UserManagementFormFields: FormFieldProps[] = [
  {
    label: 'First Name',
    inputType: 'text',
    type: 'input',
    placeholder: 'Enter First Name',
    id: 'first_name',
    name: 'first_name',
    required: true,
  },
  {
    label: 'Last Name',
    inputType: 'text',
    type: 'input',
    placeholder: 'Enter Last Name',
    id: 'last_name',
    name: 'last_name',
    required: true,
  },
  {
    label: 'Email',
    inputType: 'text',
    type: 'input',
    placeholder: 'Enter Email',
    id: 'email',
    name: 'email',
    required: true,
  },
  {
    label: 'Username',
    inputType: 'text',
    type: 'input',
    placeholder: 'Enter Username',
    id: 'username',
    name: 'username',
    required: true,
  },
  {
    label: 'Password',
    inputType: 'password',
    type: 'input',
    placeholder: 'Enter Password',
    id: 'password',
    name: 'password',
    required: true,
  },
  {
    label: 'Confirm Password',
    inputType: 'password',
    type: 'input',
    placeholder: 'Enter Confirm Password',
    id: 'confirm_password',
    name: 'confirm_password',
    required: true,
  },
  {
    label: 'Select Role For User',
    inputType: 'text',
    type: 'select',
    placeholder: 'Choose',
    id: 'role',
    name: 'role',
    choose: 'id',
    required: true,
  },
  {
    label: 'Select Programme / Project For User',
    inputType: 'text',
    type: 'accordion-select',
    placeholder: 'Choose',
    id: 'program_project',
    name: 'program_project',
    choose: 'id',
    required: false,
    subGroupName: 'subCategories',
    enableSearchBar: true,
  },
];

export const mappedPermissionName: Record<string, any> = {
  'Can add Programme': 'add programme',
  'Can change Programme': 'change programme',
  'Can delete Programme': 'delete programme',
  'Can view Programme': 'view programme',
  'Can add Project': 'add project',
  'Can change Project': 'change project',
  'Can delete Project': 'delete project',
  'Can view Project': 'view project',
  'Can add 5WH Report': 'add 5whreport',
  'Can change 5WH Report': 'change 5whreport',
  'Can delete 5WH Report': 'delete 5whreport',
  'Can view 5WH Report': 'view 5whreport',
  'Can add Knowledge Library': 'add knowledgelibrary',
  'Can change Knowledge Library': 'change knowledgelibrary',
  'Can delete Knowledge Library': 'delete knowledgelibrary',
  'Can view Knowledge Library': 'view knowledgelibrary',
  'Can add Provincial Priorities': 'add provincial priorities',
  'Can change Provincial Priorities': 'change provincial priorities',
  'Can delete Provincial Priorities': 'delete provincial priorities',
  'Can view Provincial Priorities': 'view provincial priorities',
  'Can add sector': 'add sector',
  'Can change sector': 'change sector',
  'Can view sector': 'view sector',
  'Can delete sector': 'delete sector',
  'Can add Marker': 'add marker',
  'Can change Marker': 'change marker',
  'Can delete Marker': 'delete marker',
  'Can view Marker': 'view marker',
  'Can add organization': 'add organization',
  'Can view organization': 'view organization',
  'Can change organization': 'change organization',
  'Can delete organization': 'delete organization',
};
