/* eslint-disable import/prefer-default-export */
export const findParentIdsBySubcategoryIds = (
  categories: any[],
  subcategoryIds: any[],
  subCategoryKeyName: string = 'subCategories',
): any[] => {
  const parentIds: number[] = [];

  // Iterate over each parent category
  categories?.forEach(category => {
    // Check if any subcategory's id matches one in subcategoryIds
    category[subCategoryKeyName]?.forEach((subcategory: any) => {
      if (subcategoryIds?.includes(subcategory.id)) {
        parentIds.push(category.id); // Add parent id if a match is found
      }
    });
  });

  return parentIds;
};
