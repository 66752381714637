import ChartRenderer from '@Components/common/Charts/ChartRenderer';
import { getPartnersByBudgetUsingProvince } from '@Services/provincialProfile';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';

export default function Partners() {
  const downloadComponentRef = useRef<any>(null);
  const { provinceId } = useParams();
  const chartData = {
    id: `top-partners-by-budget-allocation-province-${provinceId}`,
    name: 'top-partners-by-budget-allocation',
    queryFn: getPartnersByBudgetUsingProvince,
    params: { province: provinceId },
    title: 'Top Partners by Budget Allocated',
    hasDownloadBtn: true,
    chartType: 'verticalBar',
    hasHeader: true,
    fill: ['#B7E7E4'],
    xLabel: 'Budget',
    yLabel: 'Partners',
    height: '22rem',
    currencyConvert: true,
  };

  return (
    <div ref={downloadComponentRef} className="naxatw-min-h-[22rem]">
      <ChartRenderer data={chartData} />
    </div>
  );
}
