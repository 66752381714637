import React, { useState } from 'react';
import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import IconButton from '@Components/common/IconButton';
import { FlexColumn } from '@Components/common/Layouts';
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
import InputLabel from '@Components/common/InputLabel';
import { Input } from '@Components/common/FormUI';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getMarkerCategoriesDropdownList,
  getMarkerValuesById,
  patchMarkerValues,
  postMarkerValues,
} from '@Services/markers';
import { toast } from 'react-toastify';
import DropDown from '@Components/common/DropDown';
import { Button } from '@Components/RadixComponents/Button';
import {
  markersValuesFormState,
  validateForm,
} from '@Constants/FormConstants/markersForm';

type propType = {
  onClose: () => void;
};

const initialState = {
  name: '',
  category: '',
};

const initialErrorState = {
  name: false,
  category: false,
};

const MarkerValuesForm = ({ onClose }: propType) => {
  const navigate = useNavigate();
  const { valueId } = useParams();
  const queryClient = useQueryClient();

  const [formState, setFormState] =
    useState<markersValuesFormState>(initialState);
  const [error, setError] = useState(initialErrorState);

  const {
    data: markerCategoryDropdownOptions,
    isFetching: isMarkerCategoryListFetching,
  } = useQuery({
    queryKey: ['marker-category-dropdown-list'],
    queryFn: () => getMarkerCategoriesDropdownList(),
    select: data => {
      const resp = data?.data;
      const updatedOptions = resp?.map((option: Record<string, any>) => ({
        ...option,
        label: option?.name,
        value: option?.name,
      }));
      return updatedOptions;
    },
    onError: () => toast.error('Error Occured!. Please try again.'),
  });

  // fetch marker to populate value name & category Id if it is an update
  const { isFetching: isMarkerValuesFetching } = useQuery({
    queryKey: ['marker-value-by-id', valueId],
    enabled: !!valueId,
    queryFn: () => getMarkerValuesById(valueId || ''),
    select: data => data?.data,
    onError: () => toast.error('Error Occured!. Please try again.'),
    onSuccess: data => {
      setFormState(data);
    },
  });

  const {
    mutateAsync: addMarkerValuesData,
    isLoading: isAddMarkerValuesDataLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postMarkerValues(payloadData),
    onError: () => toast.error('Could not add marker value. Please try again.'),
  });

  const {
    mutateAsync: updateSubMarkerValuesData,
    isLoading: isUpdateMarkerValuesDataLoading,
  } = useMutation({
    mutationFn: (payload: Record<string, any>) =>
      patchMarkerValues(payload.id, payload.formState),
    onError: () =>
      toast.error('Could not update marker value. Please try again.'),
  });

  const saveMarkerValues = async () => {
    let formError = false;
    const errorState = validateForm(formState);
    setError(errorState);
    Object.values(errorState).forEach(val => {
      if (val) formError = true;
    });

    if (formError) return;

    if (!valueId) {
      await addMarkerValuesData(formState);
    } else {
      await updateSubMarkerValuesData({ id: valueId, formState });
    }

    queryClient.invalidateQueries({ queryKey: ['marker-values-tabledata'] });
    toast.success(
      valueId
        ? 'Marker Value updated successfully'
        : 'Marker Value added successfully',
    );
    navigate('/data-bank/markers/markers-values');
  };

  return (
    <div className="naxatw-absolute naxatw-left-1/2 naxatw-top-1/2 naxatw-flex naxatw-max-h-[90vh] naxatw-w-[90%] -naxatw-translate-x-1/2 -naxatw-translate-y-1/2 naxatw-flex-col naxatw-rounded-lg naxatw-border naxatw-border-gray-300 naxatw-bg-[#fff] naxatw-transition-all naxatw-duration-200 sm:naxatw-max-w-[34.75rem]">
      <div className="naxatw-flex naxatw-items-center naxatw-justify-between naxatw-self-stretch naxatw-px-7 naxatw-py-5 naxatw-shadow-[0px_2px_20px_4px_rgba(0,0,0,0.12)]">
        <BreadCrumb
          heading="Markers / Marker Values"
          overlayStatus={() => onClose()}
        />
        <IconButton
          name="close"
          className="!naxatw-h-9 !naxatw-w-9 naxatw-gap-1 naxatw-rounded-lg hover:naxatw-bg-gray-100"
          iconClassName="naxatw-font-normal naxatw-text-[#757575] naxatw-text-[24px] naxatw-leading-[24px]"
          onClick={() => onClose()}
        />
      </div>
      <div className="naxatw-h-[19.5rem] naxatw-w-full">
        <FlexColumn className="naxatw-h-full naxatw-w-full naxatw-flex-1 naxatw-gap-[0.469rem] naxatw-px-6 naxatw-pt-5">
          {isMarkerCategoryListFetching || isMarkerValuesFetching ? (
            <FormSkeleton className="naxatw-w-full" numRows={2} />
          ) : (
            <>
              <div className="naxatw-flex-1">
                <FlexColumn className="naxatw-mb-3 naxatw-w-full naxatw-gap-[0.469rem]">
                  <InputLabel
                    id="Marker-name"
                    label="Marker Value"
                    // tooltipMessage="Marker Value Name"
                    astric
                  />
                  <Input
                    type="text"
                    placeholder="Marker Value"
                    className="naxatw-w-full"
                    value={formState?.name}
                    onChange={e => {
                      setFormState(prev => ({
                        ...prev,
                        name: e.target.value,
                      }));
                      if (error.name)
                        setError(prev => ({ ...prev, name: false }));
                    }}
                  />
                  {error.name && (
                    <p className="naxatw-pt-1 naxatw-text-sm naxatw-text-red-500">
                      Marker value name is Required
                    </p>
                  )}
                </FlexColumn>
                <FlexColumn className="naxatw-w-full naxatw-gap-[0.469rem]">
                  <InputLabel
                    id="marker-category"
                    label="Marker Category"
                    // tooltipMessage="Marker Category"
                    astric
                  />
                  <DropDown
                    placeholder="Marker Category"
                    options={markerCategoryDropdownOptions || []}
                    value={formState?.category}
                    onChange={value => {
                      setFormState(prev => ({
                        ...prev,
                        category: value,
                      }));
                      if (error.category)
                        setError(prev => ({ ...prev, category: false }));
                    }}
                    choose="id"
                    className="naxatw-w-full"
                  />
                  {error.category && (
                    <p className="naxatw-pt-1 naxatw-text-sm naxatw-text-red-500">
                      Marker category is Required
                    </p>
                  )}
                </FlexColumn>
              </div>
              <div className="naxatw-px-6 naxatw-py-5 naxatw-text-center">
                <Button
                  size="normal"
                  type="button"
                  variant="primary"
                  className="naxatw-w-fit naxatw-px-4"
                  onClick={() => saveMarkerValues()}
                  isLoading={
                    isAddMarkerValuesDataLoading ||
                    isUpdateMarkerValuesDataLoading
                  }
                  disabled={
                    isAddMarkerValuesDataLoading ||
                    isUpdateMarkerValuesDataLoading ||
                    isMarkerCategoryListFetching ||
                    isMarkerValuesFetching
                  }
                >
                  Save
                </Button>
              </div>
            </>
          )}
        </FlexColumn>
      </div>
    </div>
  );
};

export default MarkerValuesForm;
