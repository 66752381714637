import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { downloadTableData } from '@Services/provincialProfile';
import ChartCardHeader from './Header';
import ProgrammeDetailsTable from './Table/ProgrammeDetailsTable';

const ProgrammeDetails = () => {
  const [isDownload, setIsDownload] = useState(false);
  // download table
  const { refetch } = useQuery({
    queryKey: ['programme-details-table-data'],
    queryFn: async () =>
      downloadTableData({
        is_export: true,
      }),
    enabled: isDownload,
    onSuccess: response => {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(response.data);
      a.download = `tableData.csv`;
      a.click();
    },
  });

  return (
    <>
      <div className="naxatw-w-full naxatw-overflow-hidden naxatw-rounded-xl naxatw-border naxatw-border-[#EAECF0] naxatw-bg-white">
        <div className="naxatw-flex naxatw-flex-col">
          <ChartCardHeader
            title="Programme Details"
            needFullScreen={false}
            iconClick={() => {
              setIsDownload(false);
              refetch();
              setIsDownload(false);
            }}
          />
          <div className="naxatw-h-[1px] naxatw-w-full naxatw-bg-[#EAECF0]" />
          <ProgrammeDetailsTable />
        </div>
      </div>
    </>
  );
};

export default ProgrammeDetails;
