/* eslint-disable no-underscore-dangle */
import {
  mapGovernmentStakeHolderColors,
  mapGovernmentStakeHoldersTitle,
} from '@Constants/mainDashboard';

interface IPieChartLegendItem {
  data?: Record<string, any>;
}

const PieChartLegendItem = ({ data }: IPieChartLegendItem) => {
  return (
    <div className="naxatw-mb-2 naxatw-flex naxatw-items-center last:naxatw-mb-0">
      <div className="naxatw-flex naxatw-flex-1 naxatw-items-center naxatw-gap-2">
        <div
          className="naxatw-h-[0.75rem] naxatw-w-[0.75rem] naxatw-rounded-[0.25rem]"
          style={{
            backgroundColor: mapGovernmentStakeHolderColors[data?._id] || '',
          }}
        />
        <p className="naxatw-body-sm naxatw-mr-6 naxatw-text-grey-800">
          {mapGovernmentStakeHoldersTitle[data?._id] || ''}
        </p>
      </div>
      <p className="naxatw-mb-4 naxatw-mr-8 naxatw-text-xs naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-grey-800 md:naxatw-mb-0">
        {data?.value || '-'}
      </p>
      <p className="naxatw-text-xs naxatw-font-bold naxatw-tracking-[-0.015rem] naxatw-text-grey-800">
        {`${data?.percentage}%` || '-'}
      </p>
    </div>
  );
};

export default PieChartLegendItem;
