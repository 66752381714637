/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import dvsLogoNew from '@Assets/images/dvs-logo-new.svg';
import dvsLogoSmall from '@Assets/images/dvsLogoHalf.svg';
import { navbarData } from '@Constants/navbarData';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import NestedNavLink from '@Components/common/Navbar/NestedNavLink';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { checkUserProfile, checkUserProfilePermission } from '@Services/common';
// import Notification from '@Components/common/Navbar/Notification';
import AccountMenu from '@Components/common/Navbar/AccountMenu';
import { setCommonState } from '@Store/actions/common';
import useScrollPosition from '@Hooks/useScrollPosition';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '@Store/hooks';
import Icon from '../Icon';

const Navbar = () => {
  const { pathname } = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);
  const [checkUser, setCheckUser] = useState(false);
  const navigate = useNavigate();
  const splittedPathname = pathname?.split('/');
  const scrollPosition = useScrollPosition();
  const [width, setWidth] = useState(window.innerWidth);
  const token = localStorage.getItem('token');
  const dispatch = useDispatch();
  const userProfile = useTypedSelector(state => state?.common?.userProfile);

  const springs = useSpring({
    // opacity: burgerMenuOpen ? 1 : 0,
    transform: burgerMenuOpen ? 'translateX(0%)' : 'translateX(100%)',
    width: burgerMenuOpen ? '45%' : '0%',
    opacity: burgerMenuOpen ? 1 : 0,
    config: { tension: 150, friction: 20 },
  });

  // check login in user
  useQuery({
    queryKey: ['check-user-profile'],
    queryFn: () => checkUserProfile(),
    enabled: checkUser,
    onSuccess: res => {
      dispatch(setCommonState({ userProfile: res?.data }));
    },
    onError: (error: any) => {
      dispatch(setCommonState({ userProfileError: error?.message }));
    },
  });

  // check permission for logged in user
  useQuery({
    queryKey: ['get-group-id-permission'],
    queryFn: () => checkUserProfilePermission(userProfile?.group),
    enabled: !!userProfile?.group,
    onSuccess: res => {
      dispatch(setCommonState({ userGroupPermission: res?.data }));
    },
  });

  useEffect(() => {
    if (!token) return;
    setCheckUser(true);
  }, [token]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div
        className={`naxatw-flex ${
          scrollPosition > 0 ? 'naxatw-h-[4.25rem]' : 'naxatw-py-3'
        } naxatw-items-center naxatw-justify-between naxatw-px-6 ${
          pathname === '/' ? 'naxatw-bg-[#EFF6FE]' : 'naxatw-border-b-[1px] '
        }`}
      >
        {dropdownOpen && (
          <div className="naxatw-absolute naxatw-left-0 naxatw-top-0 naxatw-z-50 naxatw-h-screen naxatw-w-screen naxatw-bg-[#417EC9] naxatw-opacity-10" />
        )}
        <div
          tabIndex={0}
          role="button"
          className="logo-container naxatw-min-w-[9rem] naxatw-cursor-pointer"
          onClick={() => navigate('/')}
        >
          {scrollPosition > 0 ? (
            <img src={dvsLogoSmall} alt="logo" />
          ) : (
            <img src={dvsLogoNew} alt="logo" />
          )}
        </div>
        {width > 1120 && (
          <div className="naxatw-flex naxatw-items-center naxatw-gap-x-9">
            {navbarData.map((navbarItem): React.ReactNode | null => {
              if (
                (pathname.includes('admin') &&
                  navbarItem.name !== 'Admin Panel') ||
                (!pathname.includes('admin') && pathname !== '/')
              ) {
                return (
                  <div
                    key={navbarItem.id}
                    className="navbar-items naxatw-h-full"
                  >
                    {!navbarItem.children && navbarItem.link ? (
                      <NavLink
                        className={() =>
                          splittedPathname?.length > 0 &&
                          navbarItem?.link?.split('/')?.[1] ===
                            splittedPathname[1]
                            ? 'naxatw-border-b-2 naxatw-border-transparent naxatw-border-b-[#417EC9] naxatw-p-3 naxatw-text-base naxatw-font-medium naxatw-tracking-[-0.5px] naxatw-text-matt-100 naxatw-duration-200'
                            : 'naxatw-border-b-2 naxatw-border-transparent naxatw-p-3 naxatw-text-base naxatw-font-medium naxatw-tracking-[-0.5px] naxatw-text-matt-100 naxatw-duration-200 hover:naxatw-text-[#417EC9]'
                        }
                        to={navbarItem.link}
                      >
                        {navbarItem.name}
                      </NavLink>
                    ) : (
                      <NestedNavLink
                        navbarItem={navbarItem}
                        setDropdownOpen={setDropdownOpen}
                      />
                    )}
                  </div>
                );
              }
              return null;
            })}
          </div>
        )}
        {pathname !== '/' && (
          <FlexRow className="naxatw-items-center naxatw-gap-6">
            <FlexRow className="naxatw-items-center naxatw-gap-4">
              {/* <Notification /> */}
              <AccountMenu />
            </FlexRow>
            {width <= 1120 && (
              <Icon
                name="menu"
                className="naxatw-cursor-pointer"
                onClick={() => setBurgerMenuOpen(true)}
              />
            )}
          </FlexRow>
        )}
      </div>
      <div
        className={`naxatw-absolute naxatw-right-0 naxatw-top-0 naxatw-z-[50] naxatw-h-screen naxatw-w-full naxatw-bg-[#2e2e2e4e] ${burgerMenuOpen ? 'naxatw-block' : 'naxatw-hidden'}`}
      />
      <animated.div
        style={{ ...springs }}
        className="naxatw-absolute naxatw-right-0 naxatw-top-0 naxatw-z-[100] naxatw-h-screen naxatw-w-[45%] naxatw-overflow-hidden naxatw-bg-white"
      >
        <FlexColumn>
          <FlexRow className="naxatw-items-center naxatw-justify-between naxatw-px-6 naxatw-py-4">
            <img src={dvsLogoNew} alt="logo" />
            <Icon name="close" onClick={() => setBurgerMenuOpen(false)} />
          </FlexRow>
          <div className="naxatw-h-[1px] naxatw-w-full naxatw-bg-primary-200" />
          <FlexColumn className="naxatw-p-3">
            {navbarData.map((navbarItem): React.ReactNode | null => {
              if (
                (pathname.includes('admin') &&
                  navbarItem.name !== 'Admin Panel') ||
                (!pathname.includes('admin') && pathname !== '/')
              ) {
                return (
                  <div
                    key={navbarItem.id}
                    className={`navbar-items naxatw-flex naxatw-h-[4rem] naxatw-w-full naxatw-items-center naxatw-rounded-lg naxatw-p-3 ${
                      splittedPathname?.length > 0 &&
                      navbarItem?.link?.split('/')?.[1] === splittedPathname[1]
                        ? ' naxatw-bg-secondary-100'
                        : 'naxatw-bg-transparent hover:naxatw-bg-secondary-100'
                    }`}
                  >
                    {!navbarItem.children && navbarItem.link ? (
                      <NavLink
                        onClick={() => setBurgerMenuOpen(false)}
                        className={() =>
                          splittedPathname?.length > 0 &&
                          navbarItem?.link?.split('/')?.[1] ===
                            splittedPathname[1]
                            ? 'naxatw-p-3 naxatw-text-base naxatw-font-medium naxatw-tracking-[-0.5px] naxatw-text-primary-600 naxatw-duration-200'
                            : 'naxatw-w-full naxatw-p-3 naxatw-text-base naxatw-font-medium naxatw-tracking-[-0.5px] naxatw-text-matt-100 naxatw-duration-200'
                        }
                        to={navbarItem.link}
                      >
                        {navbarItem.name}
                      </NavLink>
                    ) : (
                      <NestedNavLink
                        navbarItem={navbarItem}
                        setDropdownOpen={setDropdownOpen}
                      />
                    )}
                  </div>
                );
              }
              return null;
            })}
          </FlexColumn>
        </FlexColumn>
      </animated.div>
      {/* </div> */}
    </>
  );
};

export default Navbar;
