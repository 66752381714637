import { z } from 'zod';

export const MapLayersValidationSchema = z.object({
  name_en: z.string().trim().min(1, 'Name is Required'),
  type_of_layer: z.string().trim().min(1, 'Layer Type is Required'),
  geometry_type: z.string().trim().min(1, 'Geometry Type is Required'),
  // file_upload: z.string().trim().min(1, 'Document is Required'),
  file_upload: z
    .unknown()
    .refine(value => value !== undefined && value !== null, {
      message: 'Document is required',
    }),
});

export type MapLayersValidationSchemaProps = z.infer<
  typeof MapLayersValidationSchema & {
    [key: string]: any;
  }
>;
