/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable camelcase */
import { Button } from '@Components/RadixComponents/Button';
import dvsLogo from '@Assets/images/dvs-logo-new.svg';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from '@tanstack/react-query';
import { generateReportListStakeholder } from '@Services/report';
import { convertToCurrencySystem } from '@Utils/index';
import html2pdf from 'html2pdf.js';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { IProgrammeDetailsPdfState } from '@Store/slices/adminPdfSlice';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import IconHeader from '@Components/common/PdfDesign/IconHeaders';
// import MarkerCardBox from '@Components/common/PdfDesign/SectorCardBox';
import Icon from '@Components/common/Icon';
import { useParams } from 'react-router-dom';
import DataTable from '@Components/common/DataTable';
import { generateProgramReport } from '@Services/program';
import MarkerCardBox from '@Components/common/PdfDesign/SectorCardBox';

type IReportPdfPopUpProps = {
  // display?: boolean;
  handleClose?: () => void;
  compoenentId?: string;
  checkList: IProgrammeDetailsPdfState;
};

const ProgrammeDetailsPdfPopup = ({
  // display = false,
  compoenentId,
  handleClose,
  checkList,
}: IReportPdfPopUpProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { programmeId } = useParams();

  // const getQueryParams = useRouteParamQuery();
  // const municipalityCode = getQueryParams.get('municipality');
  // const navigate = useNavigate();
  const {
    sectors,
    campaign_goals,
    project_details,
    markers,
    working_areas,
    stakeholders,
  } = checkList;

  const [customIsLoading, setCustomIsLoading] = useState(false);

  function transformStakeholders(data: any[]) {
    const result: any[] = [];

    data?.forEach(stakeholder => {
      stakeholder.stakeholders?.forEach((levelObj: any) => {
        Object?.keys(levelObj)?.forEach(level => {
          const content = levelObj[level]?.map((categoryObj: any) => {
            const category = Object?.keys(categoryObj)[0];
            const items = categoryObj[category];
            return { category, items };
          });

          result.push({ level, content });
        });
      });
    });

    return result;
  }

  const { data: reportListData, isLoading: reportDataIsLoading } = useQuery({
    queryKey: ['programmeReportList', programmeId],
    queryFn: async () => generateProgramReport(programmeId),
    select: res => res.data[0],
  });

  const { data: stakeholderData, isLoading: stakeholderDataIsLoading } =
    useQuery({
      queryKey: ['stakeolderData', programmeId],
      queryFn: async () =>
        generateReportListStakeholder({ programme: programmeId }),
      select: res => transformStakeholders(res.data),
    });

  const handleDownloadPdf = () => {
    const element = contentRef.current;
    if (element) {
      const opt = {
        margin: 0.25,
        filename: 'my-document.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 1.5 },
        jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
        pagebreak: { mode: ['css', 'avoid-all'], avoid: '.no-page-break' },
      };

      // Create PDF
      html2pdf().from(element).set(opt).save();
    }
  };
  const labels = [
    { id: 'program_code', title: 'Programme Code' },
    { id: 'start_date', title: 'Programme Code' },
    { id: 'end_date', title: 'Programme Code' },
    { id: 'allocated_budget', title: 'Total Allocated Budget' },
    { id: 'program_type', title: 'Program Type' },
  ];

  // we might need this later -------------------------------------------------

  // const columns = [
  //   {
  //     header: 'PROJECT',
  //     accessorKey: 'component_name',
  //     // eslint-disable-next-line react/no-unstable-nested-components
  //     //   cell: row => {
  //     //     return `${index + 1}.${Number(row?.row?.id) + 1}  ${row?.row?.original.component}`;
  //     //   },
  //   },
  //   {
  //     header: 'FIRST TIER PARTNER',
  //     accessorKey: 'first_tier_partner',
  //   },
  // ];

  // ---------------------------------------------------------------------------

  return (
    <>
      <div className="naxatw-w-full naxatw-overflow-hidden naxatw-py-10">
        <div className="naxatw-relative naxatw-mx-auto naxatw-h-[93vh] naxatw-w-11/12 naxatw-overflow-hidden naxatw-rounded-2xl naxatw-border naxatw-bg-white naxatw-px-5 naxatw-shadow-sm lg:naxatw-w-3/5 xl:naxatw-w-2/5">
          {reportDataIsLoading || customIsLoading ? (
            <Skeleton className="naxatw-mt-4 naxatw-h-[93vh] naxatw-w-full" />
          ) : (
            <div
              ref={contentRef}
              className="no-scrollbar no-page-break naxatw-flex naxatw-h-full naxatw-w-full naxatw-flex-col naxatw-gap-5 naxatw-overflow-y-auto"
            >
              <FlexColumn className="naxatw-w-full naxatw-gap-5 naxatw-pb-[6rem]">
                <div className="naxatw-py-2">
                  <img src={dvsLogo} alt="" />
                </div>
                <FlexColumn className="naxatw-gap-2">
                  <p className="naxatw-text-xl naxatw-font-medium naxatw-leading-normal naxatw-text-primary-700">
                    {reportListData?.name}
                    {/* Accelerating Investment and Infrastructure in Nepal (AiiN) */}
                  </p>
                </FlexColumn>
                <FlexColumn className="naxatw-col-span-5 naxatw-overflow-hidden naxatw-rounded-xl naxatw-border naxatw-shadow-sm">
                  {labels.map((data, index) => (
                    <FlexRow
                      className={`naxatw-items-center naxatw-gap-3 ${index % 2 !== 0 ? 'naxatw-bg-gray-100' : ''}  naxatw-px-2 naxatw-py-1`}
                      key={uuidv4()}
                    >
                      <p className="naxatw-min-w-[30%] naxatw-text-[0.75rem] naxatw-font-medium naxatw-text-gray-500">
                        {data.title}
                      </p>
                      <p>:</p>
                      <p className="naxatw-text-[0.75rem] naxatw-font-bold naxatw-text-matt-200">
                        {data.id === 'allocated_budget'
                          ? `£ ${convertToCurrencySystem(
                              reportListData[data.id],
                            )}`
                          : reportListData[data.id] || '-'}
                      </p>
                    </FlexRow>
                  ))}
                </FlexColumn>

                <FlexColumn className="naxatw-gap-8">
                  {project_details && (
                    <FlexColumn className="naxatw-gap-3">
                      <IconHeader name="article" title="Programme Details" />

                      <div className="table-content ">
                        <DataTable
                          queryKey="programme details"
                          queryFn={() => {}}
                          columns={[
                            {
                              header: 'PROJECT',
                              accessorKey: 'project_name',
                              //   eslint-disable-next-line react/no-unstable-nested-components
                              cell: row => {
                                // console.log(row)
                                return `${Number(row?.row?.id) + 1}  ${row?.row?.original.project_name}`;
                              },
                            },
                            // ...columns,
                            {
                              header: 'Budget Allocated (£)',
                              accessorKey: 'project_budget',
                              // eslint-disable-next-line react/no-unstable-nested-components
                              cell: row => {
                                return (
                                  // <div className="naxatw-absolute naxatw-right-8">
                                  <p className="naxatw-text-right">
                                    {convertToCurrencySystem(
                                      row?.row?.original?.project_budget,
                                    )}
                                  </p>
                                  // </div>
                                );
                              },
                            },
                          ]}
                          className="!naxatw-border-[0]"
                          demoData={reportListData.project}
                          isPaginated={false}
                          searchInput=""
                          //   tableStyle={{ tableLayout: 'fixed' }}
                          needSorting={false}
                          //   cellClassName="!naxatw-bg-red-400"
                          //   cellClassName="!naxatw-items-end"
                        />
                        {/* {reportListData?.program_details?.map(
                          (tableData: any, index: any) => {
                            return (
                              <div
                                className="naxatw-mb-2 naxatw-py-1 last:naxatw-mb-0"
                                key={tableData.program_id}
                              ></div>
                            );
                          },
                        )} */}
                      </div>
                    </FlexColumn>
                  )}
                  {/* -----------------sectors------------------------- */}
                  {sectors && (
                    <FlexColumn className="naxatw-w-full naxatw-gap-3">
                      <IconHeader name="category" title="Sector" />
                      <FlexColumn className="naxatw-w-full naxatw-gap-2">
                        {reportListData?.sector_group?.sectors?.map(
                          (sector: any) => (
                            <FlexRow
                              className="naxatw-relative naxatw-items-center naxatw-justify-between naxatw-rounded-lg naxatw-border naxatw-p-3 naxatw-shadow-sm"
                              key={sector.sector_id}
                            >
                              <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                                {sector?.sector_name}
                              </p>
                              {/* <div className="naxatw-absolute naxatw-right-[20%] naxatw-h-6 naxatw-w-[2px] naxatw-bg-secondary-200" />
                            <p className="naxatw-text-[0.75rem] naxatw-font-bold naxatw-text-matt-200">
                              &#163;{' '}
                              {convertToCurrencySystem(sector?.budget) || 'N/A'}
                            </p> */}
                            </FlexRow>
                          ),
                        )}
                      </FlexColumn>
                    </FlexColumn>
                  )}
                  {/* ------------------- markers --------------------- */}
                  {/* {markers && (
                    <FlexColumn className="naxatw-w-full naxatw-gap-3">
                      <IconHeader name="donut_small" title="Markers" />
                      <FlexColumn className="naxatw-w-full naxatw-gap-2">
                        {reportListData?.marker_category.map((marker: any) => (
                          <MarkerCardBox
                            marker={marker?.marker}
                            sectorGroupName={marker?.marker_category_name}
                            key={uuidv4()}
                            className="naxatw-min-w-[30%]"
                          />
                        ))}
                      </FlexColumn>
                    </FlexColumn>
                  )} */}
                  {/* ------------------- campaign goals */}
                  {campaign_goals && (
                    <FlexColumn className="naxatw-w-full naxatw-gap-3">
                      <IconHeader name="flag_circle" title="Campaign Goals" />
                      <div className="naxatw-grid naxatw-w-full naxatw-grid-cols-2 naxatw-gap-2">
                        {reportListData?.campaign_goal?.map((goal: any) => (
                          <div
                            className="naxatw-w-full naxatw-min-w-[12rem] naxatw-rounded-lg naxatw-border naxatw-p-5 naxatw-shadow-sm"
                            key={uuidv4()}
                          >
                            <div className="naxatw-flex naxatw-flex-col naxatw-gap-[0.62rem]">
                              <p className="naxatw-text-[1.5rem] naxatw-font-medium naxatw-text-primary-700">
                                {goal.split(':')[0]}
                              </p>
                              <p className="naxatw-whitespace-nowrap naxatw-text-[0.875rem] naxatw-text-matt-200 ">
                                {goal.split(':')[1]}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </FlexColumn>
                  )}
                  {/* ---------------working palikas ------------------ */}
                  {working_areas && (
                    <FlexColumn className="naxatw-gap-2">
                      <IconHeader name="place" title="Working Areas" />
                      <FlexColumn className="naxatw-gap-2">
                        {reportListData?.working_area?.map((area: any) => (
                          <FlexRow
                            className={`naxatw-items-center naxatw-justify-between naxatw-rounded-xl naxatw-border naxatw-p-3 naxatw-shadow-sm ${area === null ? 'naxatw-hidden' : 'naxatw-block'}`}
                            key={uuidv4()}
                          >
                            <FlexRow>
                              <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                                {area?.province_name} &nbsp;
                              </p>
                              {area?.municipality_name && (
                                <>
                                  <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                                    {` / ${area?.district_name} /`} &nbsp;
                                  </p>
                                  <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                                    {area?.municipality_name}
                                  </p>
                                </>
                              )}
                            </FlexRow>
                            <FlexRow className="naxatw-gap-[0.38rem]">
                              {area?.end_date === null ? (
                                <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                                  Ongoing
                                </p>
                              ) : (
                                <>
                                  <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                                    {area?.start_date}
                                  </p>
                                  <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                                    to
                                  </p>
                                  <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                                    {area?.end_date}
                                  </p>
                                </>
                              )}
                            </FlexRow>
                          </FlexRow>
                        ))}
                      </FlexColumn>
                    </FlexColumn>
                  )}
                  {stakeholders && (
                    <FlexColumn className="naxatw-gap-3">
                      <IconHeader name="groups" title="Stakeholders" />
                      <FlexColumn className="naxatw-gap-3">
                        {stakeholderData?.map((stakeholder: any) => (
                          <FlexColumn className="naxatw-gap-2">
                            <p className="naxatw-text-[0.625rem] naxatw-font-medium naxatw-uppercase naxatw-tracking-[0.03125rem] naxatw-text-primary-700">
                              {stakeholder?.level}
                            </p>
                            <FlexColumn className="naxatw-w-full naxatw-gap-1 naxatw-rounded-lg naxatw-border naxatw-bg-white naxatw-p-3 naxatw-shadow-sm">
                              {stakeholder?.content.map(
                                (level: any, index: number) => {
                                  const isLastIndex =
                                    index === stakeholder.content.length - 1;
                                  return (
                                    <React.Fragment key={level?.category}>
                                      <FlexColumn className="naxatw-gap-1">
                                        <p className="naxatw-text-[0.75rem] naxatw-font-bold naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                                          {level?.category}
                                        </p>
                                        <FlexColumn className="naxatw-gap-[0.62rem]">
                                          {level?.items?.map(
                                            (
                                              subStakeholders: any,
                                              subIndex: number,
                                            ) => {
                                              return (
                                                <FlexRow className="naxatw-gap-[0.62rem]">
                                                  {subStakeholders.province && (
                                                    <>
                                                      <p className="naxatw-text-[0.75rem] naxatw-font-semibold naxatw-text-primary-700">
                                                        {
                                                          subStakeholders.province
                                                        }
                                                      </p>
                                                      <div className="naxatw-h-[1rem] naxatw-w-[1px] naxatw-bg-secondary-300" />
                                                    </>
                                                  )}
                                                  {subStakeholders?.names?.map(
                                                    (
                                                      items: any,
                                                      itemsIndex: number,
                                                    ) => {
                                                      const isLastSubIndex =
                                                        itemsIndex ===
                                                        subStakeholders.names
                                                          .length -
                                                          1;
                                                      return (
                                                        <FlexRow className="naxatw-gap-[0.62rem]">
                                                          <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-text-matt-200">
                                                            {items}
                                                          </p>
                                                          <div
                                                            className={` ${isLastSubIndex ? 'naxatw-hidden' : 'naxatw-block'} naxatw-h-[1rem] naxatw-w-[1px] naxatw-bg-secondary-300 `}
                                                          />
                                                        </FlexRow>
                                                      );
                                                    },
                                                  )}
                                                </FlexRow>
                                              );
                                            },
                                          )}
                                        </FlexColumn>
                                      </FlexColumn>
                                      <div
                                        className={` ${isLastIndex ? 'naxatw-hidden' : 'naxatw-block'} naxatw-h-[1px] naxatw-w-full naxatw-bg-neutral-200`}
                                      />
                                    </React.Fragment>
                                  );
                                },
                              )}
                            </FlexColumn>
                          </FlexColumn>
                        ))}
                      </FlexColumn>
                    </FlexColumn>
                  )}
                </FlexColumn>
              </FlexColumn>
            </div>
          )}
          <div className="naxatw-absolute naxatw-bottom-0 naxatw-left-0 naxatw-flex naxatw-h-[5rem] naxatw-w-full naxatw-items-center naxatw-justify-center naxatw-gap-4 naxatw-bg-white naxatw-shadow-[0px_2px_20px_4px_rgba(0,0,0,0.12)]">
            <Button
              size="normal"
              type="button"
              variant="secondary"
              className="naxatw-w-fit naxatw-px-4"
              onClick={handleDownloadPdf}
            >
              <Icon
                name="download"
                className="naxatw-flex naxatw-items-center naxatw-text-[1.5rem]"
              />
              <p>Download Report</p>
            </Button>
            <Button
              variant="link"
              className="naxatw-text-[0.875rem] naxatw-font-semibold naxatw-text-secondary-500"
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgrammeDetailsPdfPopup;
