type BekFootprintIconProps = {
  hover?: boolean;
};
const BekFootprint = ({ hover }: BekFootprintIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="32"
      viewBox="0 0 35 32"
      fill="none"
    >
      <g clipPath="url(#clip0_7845_51921)">
        <path
          d="M17.3445 0.0908203C25.5801 0.117376 32.4948 5.46175 34.2454 12.997C35.7592 19.5151 33.9394 25.1025 29.0906 29.7059C28.6277 30.1454 28.0983 30.5159 27.5901 30.9076C27.2615 31.1612 26.9077 31.1413 26.7068 30.8837C26.49 30.6062 26.5605 30.2543 26.9117 30.002C28.3909 28.9425 29.672 27.6864 30.7122 26.1939C32.8539 23.1201 33.8649 19.713 33.5709 15.9739C33.1559 10.6773 30.6577 6.56514 26.208 3.68781C22.9755 1.59787 19.3838 0.799862 15.5806 1.40799C9.12351 2.44234 4.71638 6.10971 2.67576 12.2893C0.922488 17.6004 1.98669 22.5584 5.41476 26.9879C6.27278 28.0953 7.29441 29.0407 8.42912 29.8639C8.51026 29.9224 8.59141 29.9808 8.66723 30.0432C8.95324 30.2795 9.01709 30.6022 8.83352 30.8585C8.6393 31.1293 8.30274 31.1705 7.98215 30.9434C6.28475 29.7431 4.84142 28.2905 3.6841 26.5657C1.63417 23.5091 0.625841 20.1339 0.77882 16.4572C0.979689 11.6386 2.91122 7.59949 6.50291 4.37694C8.96787 2.16351 11.8665 0.821107 15.1363 0.304595C16.0076 0.166505 16.8962 0.131982 17.3445 0.0908203Z"
          fill={hover ? '#16B1A7' : '#833177'}
        />
        <path
          d="M17.1108 5.1957C12.6611 5.43869 8.91513 7.77428 6.8253 12.4441C4.504 17.6278 6.4076 23.8034 11.1566 26.9423C11.2763 27.022 11.4 27.0963 11.5144 27.1826C11.8058 27.4017 11.8789 27.7337 11.7007 27.9913C11.5184 28.2528 11.1885 28.3152 10.8773 28.1108C10.4436 27.8253 10.0073 27.5385 9.60553 27.2118C7.00089 25.094 5.38729 22.3853 4.88845 19.0645C4.31511 15.2564 5.25693 11.8134 7.69262 8.84049C9.98865 6.03619 12.9924 4.4362 16.6186 4.12284C20.6214 3.77629 24.0907 5.05761 26.9374 7.86191C29.4929 10.3794 30.8151 13.4891 30.7779 17.0861C30.7287 21.813 28.6455 25.4685 24.7039 28.0776C24.4645 28.2356 24.225 28.3139 23.967 28.1373C23.7501 27.9886 23.6597 27.7536 23.7581 27.5279C23.8353 27.3513 24.0029 27.1879 24.1692 27.0804C25.2999 26.3435 26.3002 25.4631 27.125 24.3943C29.2428 21.6497 30.0675 18.548 29.4782 15.1435C28.7426 10.8999 26.3069 7.91237 22.3959 6.14109C20.93 5.47719 19.3603 5.20632 17.1108 5.1957Z"
          fill={hover ? '#16B1A7' : '#833177'}
        />
        <path
          d="M17.7717 21.5968C15.2854 21.6074 13.2555 19.5985 13.2355 17.1075C13.2155 14.6073 15.2362 12.5612 17.7358 12.5479C20.2406 12.536 22.2759 14.5502 22.2892 17.0544C22.3025 19.5547 20.2832 21.5849 17.7717 21.5955V21.5968Z"
          fill={hover ? '#16B1A7' : '#833177'}
        />
        <path
          d="M26.8277 17.5991C26.6601 20.7486 25.0052 23.4865 21.7221 25.202C21.343 25.3999 21.0171 25.3175 20.8601 25.0148C20.6925 24.6895 20.8295 24.3854 21.2566 24.1677C23.248 23.1559 24.5982 21.5904 25.3085 19.4806C26.6255 15.5662 24.4771 11.1168 20.5874 9.68542C16.2628 8.09473 11.6322 10.3188 10.2008 14.6739C9.02752 18.2417 10.6983 22.3181 14.0452 24.0575C14.1729 24.1239 14.3046 24.1823 14.4257 24.2593C14.7184 24.4465 14.8062 24.7612 14.6505 25.0294C14.4935 25.299 14.1849 25.4052 13.8736 25.2299C13.307 24.9099 12.7203 24.6058 12.2148 24.2035C9.95338 22.4057 8.70427 20.0489 8.71491 17.1569C8.72821 13.4949 10.4003 10.7078 13.6635 9.03215C17.5012 7.0617 21.8592 7.97921 24.674 11.2376C26.1147 12.9053 26.8077 14.8758 26.8277 17.5991Z"
          fill={hover ? '#16B1A7' : '#833177'}
        />
      </g>
      <defs>
        <clipPath id="clip0_7845_51921">
          <rect
            width="34"
            height="31"
            fill="white"
            transform="translate(0.764648 0.0908203)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BekFootprint;
