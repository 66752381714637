import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { Button } from '@Components/RadixComponents/Button';
import {
  getBasicInfoByID,
  postBasicInfo,
  patchBasicInfo,
} from '@Services/partner';
import { FC, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import isEmpty from '@Utils/isEmpty';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
import IconButton from '@Components/common/IconButton';
import {
  PartnerFormSchemaProps,
  getSchema,
} from '@Validations/PartnerForm/PartnerForm';
import { FlexColumn } from '@Components/common/Layouts';
import { PartnerFormProps } from '@Constants/interface/FormInterface';
import BasicInfoForm from './BasicInfoForm';

const PartnerForm: FC<PartnerFormProps> = ({ onClose, setPartner }) => {
  const { partnerID } = useParams();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();

  const partnerValidation = getSchema();
  const navigate = useNavigate();

  const formMethods = useForm<PartnerFormSchemaProps>({
    mode: 'onChange',
    resolver: zodResolver(partnerValidation),
    defaultValues: {
      name: '',
      // organization_type: '',
      description: '',
      address: '',
      email: '',
      phone: '',
      website: '',
    },
  });
  const {
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { dirtyFields },
  } = formMethods;

  // fetching basic form data to populate on edit
  const { data: basicInfoData, isFetching: isFetchingBasicInfo } = useQuery({
    enabled: !!partnerID,
    queryKey: ['baicInfo', partnerID],
    queryFn: () => getBasicInfoByID(partnerID),
    select: data => data?.data,
  });

  useEffect(() => {
    if (!partnerID || !basicInfoData) return;
    if (basicInfoData) {
      Object.keys(basicInfoData)?.forEach((key: any) => {
        setValue(key, basicInfoData[key]);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicInfoData]);

  // post basic info data
  const { mutateAsync: postBasicInfoData, isLoading: isPostBasicInfoLoading } =
    useMutation({
      mutationFn: (payloadData: Record<string, any>) =>
        postBasicInfo(payloadData),
      onSuccess: sucessResponse => {
        if (!sucessResponse) return;
        if (pathname?.includes('components')) {
          setPartner(sucessResponse?.data?.id);
        }
      },
    });

  // patch basic info data
  const {
    mutateAsync: patchBasicInfoData,
    isLoading: isPatchBasicInfoLoading,
  } = useMutation({
    mutationFn: (payload: Record<string, any>) =>
      patchBasicInfo(payload, partnerID),
  });

  const getDirtyFieldValues = () => {
    const allValues = getValues();
    const dirtyValues: any = {};
    Object.keys(allValues).forEach(key => {
      if (dirtyFields[key]) {
        dirtyValues[key] = allValues[key];
      }
    });

    if (dirtyFields.PersonDetails) {
      dirtyValues.PersonDetails = allValues?.PersonDetails.filter(
        // @ts-ignore
        (_, index: number) => dirtyFields?.PersonDetails[index],
      );
    }
    return dirtyValues;
  };

  // submit basic info data
  const submitBasicInfo = async (data: Record<string, any>): Promise<any> => {
    if (isEmpty(data)) return null;
    const response = partnerID
      ? await patchBasicInfoData(data)
      : await postBasicInfoData(data);

    return response;
  };

  const handleFormSubmit = async () => {
    try {
      const values = getValues();
      const editFields = getDirtyFieldValues();
      const { ...basicInfoPost } = values; // for post service
      const { ...basicInfoPatch } = editFields; // for patch service

      if (partnerID) {
        await submitBasicInfo(basicInfoPatch);
      } else {
        await submitBasicInfo(basicInfoPost);
      }

      queryClient.invalidateQueries({ queryKey: ['basicInfo'] });
      queryClient.invalidateQueries({ queryKey: ['partner'] });

      toast.success(
        partnerID
          ? 'First Tier Partner updated successfully'
          : 'First Tier Partner added successfully',
      );

      if (pathname?.includes('partners')) {
        queryClient.invalidateQueries({ queryKey: ['partner-tabledata'] });
      } else {
        queryClient.invalidateQueries({
          queryKey: ['getfirstTierPartneOptions'],
        });
      }

      navigate(
        pathname?.includes('partners')
          ? '/data-bank/partners'
          : '/data-bank/projects/add',
      );
      onClose();
      reset();
    } catch (error: any) {
      const caughtError = error?.response?.data?.error?.[0]?.details;
      toast.error(caughtError || 'Error occured while submitting!');
    }
  };

  return (
    <div className="naxatw-absolute naxatw-left-1/2 naxatw-top-1/2 naxatw-flex naxatw-w-[90%] naxatw-translate-x-[-50%] naxatw-translate-y-[calc(-50%+31.5px)] naxatw-items-center naxatw-justify-center sm:naxatw-max-w-[34.75rem]">
      <div className="naxatw-flex naxatw-w-full naxatw-flex-col naxatw-rounded-2xl naxatw-border naxatw-border-gray-300 naxatw-bg-[#fff] naxatw-transition-all naxatw-duration-200 ">
        <div className="naxatw-flex naxatw-items-center naxatw-justify-between naxatw-self-stretch naxatw-px-7 naxatw-py-5 naxatw-shadow-[0px_2px_20px_4px_rgba(0,0,0,0.12)]">
          <BreadCrumb
            heading={`First Tier Partner ${partnerID ? 'Edit' : 'Registration'} Form`}
            overlayStatus={() => onClose()}
          />
          <IconButton
            name="close"
            className="!naxatw-h-9 !naxatw-w-9 naxatw-gap-1 naxatw-rounded-lg hover:naxatw-bg-gray-100"
            iconClassName="naxatw-font-normal naxatw-text-[#757575] naxatw-text-[24px] naxatw-leading-[24px]"
            onClick={() => onClose()}
          />
        </div>

        <div className="naxatw-flex naxatw-w-full naxatw-gap-3">
          <div className="naxatw-w-full">
            <FormProvider {...formMethods}>
              <form
                onSubmit={e => {
                  if (pathname?.includes('components')) {
                    e.stopPropagation();
                    handleSubmit(handleFormSubmit)(e);
                  } else {
                    return handleSubmit(handleFormSubmit)(e);
                  }
                  return null;
                }}
              >
                <div className="naxatw-py-5 naxatw-pl-6 naxatw-pr-4">
                  <FlexColumn className="scrollbar naxatw-h-[calc(100vh-23.8rem)] naxatw-w-full  naxatw-space-y-5 naxatw-overflow-y-scroll md:naxatw-pr-2.5">
                    {isFetchingBasicInfo ? (
                      <div className="!naxatw-h-[calc(100vh-13rem)] naxatw-w-full">
                        <FormSkeleton numRows={5} className="naxatw-w-full" />
                      </div>
                    ) : (
                      <BasicInfoForm />
                    )}
                  </FlexColumn>
                </div>

                <div className="naxatw-flex naxatw-justify-center naxatw-py-5 naxatw-shadow-formshadow">
                  <Button
                    size="normal"
                    type="submit"
                    variant="primary"
                    isLoading={
                      isPostBasicInfoLoading || isPatchBasicInfoLoading
                    }
                    disabled={isFetchingBasicInfo}
                    className="naxatw-mx-auto"
                  >
                    Save
                  </Button>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default hasErrorBoundary(PartnerForm);
