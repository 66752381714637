import { FormFieldProps } from '@Constants/interface/FormInterface';

/* eslint-disable import/prefer-default-export */
export const tooltipInfo = [
  {
    title: 'Select Supplier',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    title: 'Select Programmme',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    title: 'Select Component',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
];

export const ProgramFormFields: FormFieldProps[] = [
  {
    label: 'Programme Code',
    inputType: 'number',
    type: 'input',
    placeholder: 'Enter Code',
    id: 'code',
    name: 'code',
    isVisible: true,
    required: true,
  },
  {
    label: 'Programme Name',
    inputType: 'text',
    type: 'input',
    placeholder: 'Enter Name',
    id: 'name',
    name: 'name',
    isVisible: true,
    required: true,
  },
  {
    label: 'Programme Abbreviation',
    inputType: 'text',
    type: 'input',
    placeholder: 'Enter Programme Abbreviation',
    id: 'abbreviation',
    name: 'abbreviation',
    isVisible: true,
    required: true,
  },

  {
    label: 'Type of Programme',
    inputType: 'text',
    type: 'select',
    placeholder: 'Choose',
    id: 'program_type',
    name: 'program_type',
    choose: 'value',
    isVisible: true,
    required: true,
  },
  {
    label: 'Programme Start Date',
    inputType: 'text',
    type: 'datePicker',
    placeholder: 'Label',
    id: 'start_date',
    name: 'start_date',
    group: 'group-1',
    isVisible: true,
    required: true,
  },
  {
    label: 'Programme End Date',
    inputType: 'text',
    type: 'datePicker',
    placeholder: 'Label',
    id: 'end_date',
    name: 'end_date',
    group: 'group-1',
    disabledDays: '',
    isVisible: true,
    required: true,
  },
  // {
  //   label: 'Programme Extended',
  //   inputType: 'text',
  //   type: 'select',
  //   placeholder: 'Choose',
  //   id: 'is_extension',
  //   name: 'is_extension',
  //   choose: 'value',
  //   isVisible: true,
  //   required: true,
  // },
  // {
  //   label: 'Extended Date',
  //   inputType: 'text',
  //   type: 'datePicker',
  //   placeholder: 'Choose',
  //   id: 'extended_date',
  //   name: 'extended_date',
  //   isVisible: false,
  //   required: true,
  // },
  {
    label:
      'Is this Programme a successor of any previous programme / project ?',
    inputType: 'text',
    type: 'select',
    placeholder: 'Choose',
    id: 'is_successor',
    name: 'is_successor',
    choose: 'value',
    isVisible: true,
    required: true,
  },
  {
    label: 'Name of Previous Programme / Project',
    inputType: 'text',
    type: 'select',
    placeholder: 'Choose',
    id: 'previous_program',
    name: 'previous_program',
    choose: 'value',
    isVisible: true,
    required: true,
    multiple: true,
    checkBox: true,
  },
  // {
  //   label: 'Linkage with Campaign Goals',
  //   inputType: 'text',
  //   type: 'select',
  //   placeholder: 'Choose',
  //   id: 'campaign_goal',
  //   name: 'campaign_goal',
  //   choose: 'value',
  //   isVisible: true,
  // },
];
