/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
import { cn } from '@Utils/index';
import { Button } from '@Components/RadixComponents/Button';
import { FC, useState } from 'react';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@Components/RadixComponents/Popover';
import Icon from '@Components/common/Icon';
import { Calendar } from '@Components/RadixComponents/Calendar';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';

interface MultipleDatePickerProps {
  placeHolder?: string;
  dateIcon?: string;
  startDate: string;
  endDate: string;
  setStartDate: any;
  setEndDate: any;
  clearDateRange?: any;
}

const handleDateChange = (date: any, onDateChange: any) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const formattedDateString = `${year}-${String(month).padStart(
    2,
    '0',
  )}-${String(day).padStart(2, '0')}`;
  if (onDateChange) {
    onDateChange(formattedDateString);
  }
};

const DateRangeIcon = ({ icon }: { icon: string }) => {
  return (
    <Icon
      name={icon}
      className="naxatw-flex naxatw-h-6 naxatw-w-6 naxatw-shrink-0 naxatw-items-center naxatw-justify-center naxatw-text-[24px] naxatw-font-light naxatw-leading-6 naxatw-text-matt-200 group-hover:naxatw-text-secondary-500"
    />
  );
};

const MultipleDatePicker: FC<MultipleDatePickerProps> = ({
  placeHolder = 'Select Date Range',
  dateIcon = 'event',
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  clearDateRange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | string>(
    startDate,
  );
  const [selectedEndDate, setSelectedEndDate] = useState<Date | string>(
    endDate,
  );

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="dropDown"
          size="drop-lg"
          className={cn(
            'naxatw-group naxatw-flex naxatw-w-full naxatw-items-center  naxatw-justify-between naxatw-gap-2 naxatw-font-normal hover:naxatw-border-secondary-500',
          )}
        >
          <div className="naxatw-flex naxatw-items-center naxatw-gap-2">
            <DateRangeIcon icon={dateIcon} />
            <span className="naxatw-text-[0.875rem] naxatw-text-matt-200">
              {startDate && endDate
                ? `${startDate} - ${endDate}`
                : startDate || endDate || placeHolder}
            </span>
          </div>
          <DateRangeIcon icon="arrow_drop_down" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="popover-content !naxatw-w-fit !naxatw-items-start  naxatw-bg-white !naxatw-p-2 !naxatw-px-3"
        align="end"
        // style={{ width: 'var(--radix-popover-trigger-width)' }}
      >
        <div className="naxatw-flex !naxatw-w-fit  naxatw-gap-8 naxatw-bg-white">
          {/* start month */}
          <div className="start-month-container naxatw-w-full">
            <p className="naxatw-mb-2 naxatw-text-[14px] naxatw-font-semibold  naxatw-text-matt-100">
              Start Date
            </p>
            <Calendar
              selected={new Date(selectedStartDate)}
              onSelect={(date: Date) => {
                setSelectedStartDate(date);
              }}
              classNames={{
                months: 'naxatw-flex naxatw-relative naxatw-justify-start',
                day: 'naxatw-rounded-md hover:naxatw-bg-[#eee] naxatw-overflow-hidden naxatw-text-matt-100  naxatw-p-2 naxatw-text-[14px] naxatw-text-center naxatw-w-full naxatw-cursor-pointer',
                weekday: '!naxatw-text-[14px] naxatw-mb-2',
                day_button:
                  'naxatw-w-full naxatw-px-[2px] naxatw-font-normal disabled:naxatw-text-grey-500 ',
                nav: 'naxatw-space-x-1 naxatw-flex naxatw-items-center naxatw-right-[-8px] naxatw-top-[4px]',
                chevron: 'naxatw-fill-matt-100 naxatw-h-[15px] naxatw-w-[15px]',
                dropdown:
                  'naxatw-text-[14px] naxatw-font-semibold naxatw-text-matt-100',
                selected:
                  'naxatw-rounded-md naxatw-bg-[#833177] !naxatw-text-white  hover:!naxatw-bg-[#833177] hover:!naxatw-text-white',
                today:
                  'hover:!naxatw-text-matt-100 naxatw-text-red-500 naxatw-font-bold naxatw-rounded-lg ',
                button_previous:
                  'naxatw-absolute naxatw-top-[6px] naxatw-left-[0]',
                button_next:
                  'naxatw-absolute naxatw-top-[6px] naxatw-right-[0]',
                dropdowns:
                  'naxatw-flex naxatw-align-center naxatw-gap-4 naxatw-justify-center !naxatw-accent-matt-100',
              }}
            />
          </div>

          {/* end  month */}
          <div className="end-month-container naxatw-w-full">
            <p className="naxatw-mb-2 naxatw-text-[14px] naxatw-font-semibold  naxatw-text-matt-100">
              End Date
            </p>
            <Calendar
              selected={new Date(selectedEndDate)}
              disabled={{ before: new Date(`${startDate}`) }}
              onSelect={(date: Date) => {
                setSelectedEndDate(date);
              }}
              classNames={{
                months: 'naxatw-flex naxatw-relative naxatw-justify-start',
                day: 'naxatw-rounded-md hover:naxatw-bg-[#eee] naxatw-overflow-hidden naxatw-text-matt-100  naxatw-p-2 naxatw-text-[14px] naxatw-text-center naxatw-w-full naxatw-cursor-pointer',
                weekday: '!naxatw-text-[14px] naxatw-mb-2',
                day_button:
                  'naxatw-w-full naxatw-px-[2px] naxatw-font-normal disabled:naxatw-text-grey-500 ',
                nav: 'naxatw-space-x-1 naxatw-flex naxatw-items-center naxatw-right-[-8px] naxatw-top-[4px]',
                chevron: 'naxatw-fill-matt-100 naxatw-h-[15px] naxatw-w-[15px]',
                dropdown:
                  'naxatw-text-[14px] naxatw-font-semibold naxatw-text-matt-100',
                selected:
                  'naxatw-rounded-md naxatw-bg-[#833177] !naxatw-text-white  hover:!naxatw-bg-[#833177] hover:!naxatw-text-white',
                today:
                  'hover:!naxatw-text-matt-100 naxatw-text-red-500 naxatw-font-bold naxatw-rounded-lg ',
                button_previous:
                  'naxatw-absolute naxatw-top-[6px] naxatw-left-[0]',
                button_next:
                  'naxatw-absolute naxatw-top-[6px] naxatw-right-[0]',
                dropdowns:
                  'naxatw-flex naxatw-align-center naxatw-gap-4 naxatw-justify-center !naxatw-accent-matt-100',
              }}
            />
          </div>
        </div>
        <div className="btns naxatw-my-2 naxatw-flex naxatw-items-center naxatw-justify-end naxatw-gap-5">
          <span
            className="naxatw-cursor-pointer naxatw-text-[0.875rem] naxatw-font-semibold naxatw-text-secondary-500"
            onClick={() => {
              clearDateRange();
              setSelectedEndDate('');
              setSelectedStartDate('');
            }}
          >
            Clear
          </span>
          <Button
            className="!naxatw-h-8 !naxatw-bg-secondary-500 !naxatw-px-4 !naxatw-py-2"
            onClick={() => {
              if (selectedStartDate) {
                handleDateChange(selectedStartDate, setStartDate);
              }
              if (selectedEndDate) {
                handleDateChange(selectedEndDate, setEndDate);
              }
              setIsOpen(false);
            }}
          >
            Apply
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default hasErrorBoundary(MultipleDatePicker);
