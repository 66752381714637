/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Icon from '@Components/common/Icon';
import { useTypedSelector, useTypedDispatch } from '@Store/hooks';
import useOutsideClick from '@Hooks/useOutsideClick';
import { handleOverlayLayerCheckbox } from '@Store/actions/mainDashboard';

export default function OverlayLayers() {
  const dispatch = useTypedDispatch();
  const [toggleRef, toggle, handleToggle] = useOutsideClick();

  const overlayLayerList = useTypedSelector(
    state => state.mainDashboard.overlayLayerList,
  );

  return (
    <div
      ref={toggleRef}
      onClick={handleToggle}
      title="Overlay"
      className="naxatw-cursor-pointer naxatw-border-b naxatw-border-solid naxatw-border-r-grey-600 naxatw-bg-white naxatw-p-2 naxatw-pb-[0.2rem] hover:naxatw-bg-grey-200"
    >
      <Icon name="layers" className="naxatw-text-[#212121]" />
      {toggle && (
        <div className="naxatw-absolute naxatw-left-[3.2rem] naxatw-top-[2.5rem] naxatw-z-[100] naxatw-w-[10rem] naxatw-gap-4 naxatw-rounded-[4px] naxatw-border-[1px] naxatw-border-[#EAECF0] naxatw-bg-white naxatw-p-3 naxatw-text-[#475467]">
          {overlayLayerList?.map(data => (
            <div
              key={data.id}
              className="naxatw-flex naxatw-items-center naxatw-justify-start naxatw-gap-[0.62rem] "
            >
              <input
                id={data.layer__name_en}
                type="checkbox"
                checked={data.checked}
                onChange={() => dispatch(handleOverlayLayerCheckbox(data.id))}
              />
              <label className="naxatw-text-[0.875rem]" htmlFor={data.value}>
                {data.layer__name_en}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
