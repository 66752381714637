/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import IconButton from '@Components/common/IconButton';
import { useNavigate, useParams } from 'react-router-dom';
import groupFormElements, {
  compareObjects,
  prepareDataForDropdown,
} from '@Utils/index';
import { UserManagementFormFields } from '@Constants/FormConstants/userSecurityFormConstants';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { FormFieldProps } from '@Constants/interface/FormInterface';
import { FormControl } from '@Components/common/FormUI';
import InputLabel from '@Components/common/InputLabel';
import getInputElement from '@Components/common/FormComponent/GetInputElement';
import ErrorMessage from '@Components/common/ErrorMessage';
import { useForm } from 'react-hook-form';
import { Button } from '@Components/RadixComponents/Button';
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
import {
  UserManagementValidationSchema,
  UserManagementValidationSchemaProps,
} from '@Validations/UserSecurity/userManagementValidation';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  getUserById,
  patchUser,
  getUserGroups,
  signUpUserFromAdmin,
  postUserProgramme,
  postUserProject,
  deleteUserProject,
  deleteUserProgramme,
} from '@Services/userSecurity';
import { getProgrammeProjectsDropDown } from '@Services/knowledgeRepository';
import isEmpty from '@Utils/isEmpty';
import { useState } from 'react';

type propType = {
  onClose: () => void;
};

const UserForm = ({ onClose }: propType) => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [actualProgrammeProject, setActualProgrammeProject] = useState<
    Record<string, any>
  >([]);
  const [actualProgramme, setActualProgramme] = useState<Record<string, any>>(
    [],
  );
  const [actualProject, setActualProject] = useState<Record<string, any>>([]);

  const groupedFormFields = groupFormElements(UserManagementFormFields);

  const formMethods = useForm<UserManagementValidationSchemaProps>({
    mode: 'onChange',
    defaultValues: {},
    resolver: zodResolver(UserManagementValidationSchema),
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, dirtyFields, isSubmitting, isDirty },
    register,
    control,
    watch,
    reset,
  } = formMethods;

  const formProps = {
    register,
    control,
    getValues,
    setValue,
    errors,
  };

  const formValues = watch();

  // sign-up & post user-profile
  const { mutateAsync: postUserSignUp } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      signUpUserFromAdmin(payloadData),
  });

  // patch user-profile
  const { mutateAsync: patchUserProfile } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      patchUser(Number(userId) || '', payloadData),
  });

  // post user programme
  const { mutateAsync: postUserProgrammeData } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postUserProgramme(payloadData),
  });

  // post user programme
  const { mutateAsync: postUserProjectData } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postUserProject(payloadData),
  });

  // delete user project data
  const { mutateAsync: deleteUserProjectData } = useMutation({
    mutationFn: id => deleteUserProject(id),
  });

  // delete user programme  data
  const { mutateAsync: deleteUserProgrammeData } = useMutation({
    mutationFn: id => deleteUserProgramme(id),
  });

  const patchUserProfileData = async (editFields: Record<string, any>) => {
    if (isDirty === false) return;
    const { program_project, ...remainingEditFields } = editFields;

    if (!isEmpty(remainingEditFields)) {
      await patchUserProfile(remainingEditFields);
    }

    if (!isEmpty(program_project)) {
      const patchedProgrammeProject = program_project;

      const differenceUserPro = compareObjects(
        actualProgrammeProject,
        patchedProgrammeProject,
      );

      const promises = Object.entries(differenceUserPro)?.map(
        async ([key, value]) => {
          if (key.startsWith('child_')) {
            const projectId = Number(key.split('_')[1]);
            if (value) {
              await postUserProjectData({
                user: userId,
                project: Number(projectId),
              });
            } else {
              const idToDelete: any = actualProject?.find(
                (project: Record<string, any>) => project.id === projectId,
              )?.project_id;

              await deleteUserProjectData(idToDelete);
            }
          } else if (key.startsWith('parent_')) {
            const programmeId = Number(key.split('_')[1]);
            if (value) {
              await postUserProgrammeData({
                user: userId,
                program: Number(programmeId),
              });
            } else {
              const idToDelete: any = actualProgramme?.find(
                (program: Record<string, any>) => program.id === programmeId,
              )?.program_id;
              await deleteUserProgrammeData(idToDelete);
            }
          }
        },
      );
      await Promise.all(promises);
    }
  };

  // get user-profile if edit
  const { isFetching: isUserFetching } = useQuery({
    queryKey: ['user-by-id', userId],
    enabled: !!userId,
    queryFn: () => getUserById(userId || ''),
    select: responseData => responseData?.data?.results[0],
    onError: () => toast.error('Error Occured!. Please try again.'),
    onSuccess: data => {
      if (!data) return;
      const filteredData = {
        first_name: data?.first_name,
        last_name: data?.last_name,
        email: data?.email,
        role: data?.role,
      };
      const { programs, projects } = data;
      const result = programs.reduce(
        (acc: Record<string, any>, program: Record<string, any>) => {
          acc[`parent_${program.id}`] = true;
          return acc;
        },
        projects.reduce(
          (acc: Record<string, any>, project: Record<string, any>) => {
            acc[`child_${project.id}`] = true;
            return acc;
          },
          {},
        ),
      );

      Object.keys(filteredData)?.forEach((key: any) => {
        setValue(key, data[key]);
      });

      setActualProgrammeProject(result);
      setActualProgramme(data.programs);
      setActualProject(data.projects);
      setValue('username', data?.user__username);
      setValue('program_project', result);
    },
  });

  const { data: projectProgrammeData, isLoading: isProjectProgrammeLoading } =
    useQuery({
      queryKey: ['get-project-programme-list'],
      queryFn: () => getProgrammeProjectsDropDown(),
      select: res => {
        if (!res?.data) return [];
        return res.data.map((programData: Record<string, any>) => ({
          id: programData.program__id,
          name: programData.program__name,
          label: programData.program__name,
          subCategories: programData.subcategories.map(
            (subcategory: Record<string, any>) => ({
              id: subcategory.id,
              label: subcategory.label,
            }),
          ),
        }));
      },
    });

  const { data: userGroupsDropdown } = useQuery({
    queryKey: ['user-groups-dropdown'],
    queryFn: () => getUserGroups(),
    onError: () => toast.error('Failed to fetch partner options'),
    select: data => {
      const response = data?.data;
      return prepareDataForDropdown(response, 'name');
    },
  });

  const getDirtyFieldValues = () => {
    const allValues = getValues();
    const dirtyValues: any = {};
    Object.keys(allValues).forEach((key: string) => {
      if (dirtyFields[key as keyof typeof dirtyFields]) {
        dirtyValues[key] = allValues[key as keyof typeof dirtyFields];
      }
    });

    return dirtyValues;
  };

  const dropdownOptions: Record<string, any> = {
    role: userGroupsDropdown,
    program_project: projectProgrammeData,
  };

  // submit basic info
  const submitBasicUserInfo = async (basicInfoData: Record<string, any>) => {
    const responseId = await postUserSignUp(basicInfoData);
    return responseId?.data?.user_profile;
  };

  // submit programme and project info
  const submitProgrammeProjectInfo = async (
    userResponseId: number,
    programmeProjectData: Record<string, any>,
  ) => {
    const programmeIds = Object.keys(programmeProjectData)
      .filter(key => key.startsWith('parent_') && programmeProjectData[key])
      .map(key => key.split('_')[1]);

    const projectIds = Object.keys(programmeProjectData)
      .filter(key => key.startsWith('child_') && programmeProjectData[key])
      .map(key => key.split('_')[1]);

    if (!isEmpty(programmeIds)) {
      await Promise.all(
        programmeIds?.map(async programId => {
          await postUserProgrammeData({
            user: userResponseId,
            program: Number(programId),
          });
        }),
      );
    }
    if (!isEmpty(projectIds)) {
      await Promise.all(
        projectIds?.map(async projectId => {
          await postUserProjectData({
            user: userResponseId,
            project: Number(projectId),
          });
        }),
      );
    }
  };

  const handlePostUser = async (formData: Record<string, any>) => {
    const {
      first_name,
      last_name,
      email,
      username,
      password,
      role,
      program_project: userProgrammeProject,
    } = formData;

    const basicInfoData = {
      first_name,
      last_name,
      email,
      username,
      password,
      role,
    };
    const responseUserId = await submitBasicUserInfo(basicInfoData);
    if (userProgrammeProject && responseUserId) {
      submitProgrammeProjectInfo(responseUserId, userProgrammeProject);
    }
  };

  const handleFormSubmit = async () => {
    try {
      const editFields = getDirtyFieldValues();
      if (userId) {
        await patchUserProfileData(editFields);
      } else {
        await handlePostUser(formValues);
      }
      reset();
      queryClient.invalidateQueries({
        queryKey: ['user-tabledata'],
      });
      navigate('/data-bank/user-security/user-management');
      toast.success(`User ${userId ? 'updated' : 'created'} successfully`);
    } catch (error: any) {
      const caughtError = error?.response?.data?.message;
      toast.error(caughtError || 'User Sign In Failed');
    }
  };

  return (
    <div className="naxatw-absolute naxatw-left-1/2 naxatw-top-1/2 naxatw-flex naxatw-max-h-[90vh] naxatw-w-[90%] -naxatw-translate-x-1/2 naxatw-translate-y-[calc(-50%+31.5px)] naxatw-flex-col naxatw-overflow-hidden naxatw-rounded-lg naxatw-border naxatw-border-gray-300 naxatw-bg-[#fff] naxatw-transition-all naxatw-duration-200 sm:naxatw-max-w-[34.75rem] ">
      <div className="naxatw-flex naxatw-items-center naxatw-justify-between naxatw-self-stretch naxatw-px-7 naxatw-py-5 naxatw-shadow-[0px_2px_20px_4px_rgba(0,0,0,0.12)]">
        <BreadCrumb
          heading={`User Management / ${userId ? 'Edit' : 'Add'}`}
          overlayStatus={() => onClose()}
        />
        <IconButton
          name="close"
          className="!naxatw-h-9 !naxatw-w-9 naxatw-gap-1 naxatw-rounded-lg hover:naxatw-bg-gray-100"
          iconClassName="naxatw-font-normal naxatw-text-[#757575] naxatw-text-[24px] naxatw-leading-[24px]"
          onClick={() => onClose()}
        />
      </div>
      <div className="naxatw-flex naxatw-w-full naxatw-gap-3">
        <div className="naxatw-w-full">
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <FlexColumn className="scrollbar naxatw-h-[calc(100vh-20rem)] naxatw-w-full naxatw-overflow-y-scroll naxatw-px-5 naxatw-pr-2 naxatw-pt-6">
              {isUserFetching || isProjectProgrammeLoading ? (
                <FormSkeleton numRows={8} className="naxatw-w-full" />
              ) : (
                groupedFormFields?.map(
                  (fieldRow: Record<string, any>, index: number) => (
                    <FlexRow key={`${index}`}>
                      {fieldRow?.map((field: FormFieldProps) => {
                        const { id } = field;
                        if (
                          userId &&
                          (id === 'password' || id === 'confirm_password')
                        ) {
                          return null;
                        }
                        return (
                          <FormControl
                            className="naxatw-mb-5 naxatw-w-full naxatw-gap-[0.5rem]"
                            key={`${field.id}- ${field.name}`}
                          >
                            <InputLabel
                              label={field.label || ''}
                              astric={field.required}
                              id={field.id}
                            />
                            {getInputElement(
                              {
                                ...field,
                              },
                              /* @ts-ignore */
                              formProps,
                              dropdownOptions[id] || [],
                            )}
                            <>
                              {
                                /* @ts-ignore */
                                formProps.errors[id] && (
                                  <ErrorMessage
                                    /* @ts-ignore */
                                    message={formProps.errors[id]?.message}
                                  />
                                )
                              }
                            </>
                          </FormControl>
                        );
                      })}
                    </FlexRow>
                  ),
                )
              )}
            </FlexColumn>
            <div className="naxatw-flex naxatw-justify-center naxatw-py-5 naxatw-shadow-formshadow">
              <Button
                size="normal"
                variant="primary"
                className="naxatw-px-4 naxatw-py-2"
                type="submit"
                isLoading={isSubmitting}
                disabled={isSubmitting || isUserFetching}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserForm;

// if (field.id === 'program_project') {
//   return (
//     <FormControl
//       className="naxatw-mb-5 naxatw-w-full naxatw-gap-[0.5rem]"
//       key={`${field.id}- ${field.name}`}
//     >
//       <InputLabel
//         label={field.label}
//         astric={field.required}
//         id={field.id}
//       />
//       <AccordianDropDown
//         options={projectProgrammeData || []}
//         className="naxatw-h-1/2 naxatw-w-full"
//         enableSearchbar={false}
//         placeholder={`${programmeData.length} Programmes & ${projectData.length} Projects`}
//         value={programmeData}
//         subCategoriesValue={projectData}
//         onChange={value => {
//           setProgrammeData(value);
//         }}
//         subCategoriesOnChange={value => {
//           setProjectData(value);
//         }}
//       />
//     </FormControl>
//   );
// }
