/* eslint-disable react-hooks/exhaustive-deps */
import Checkbox from '@Components/common/FormUI/CheckBox';
import Icon from '@Components/common/Icon';
import { ChevronDown } from 'lucide-react';
import { getFilterList } from '@Services/mainDashboard';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@Components/RadixComponents/Accordion';
import isEmpty from '@Utils/isEmpty';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  postFilterParams,
  setMainDashboardStates,
  toggleFilterState,
  resetFilterState,
  setDateRangeState,
  setFilterCount,
} from '@Store/actions/mainDashboard';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { useLocation } from 'react-router-dom';
import { convertFilterData } from '@Utils/convertFilterData';
import { useQuery } from '@tanstack/react-query';
import { useTypedSelector } from '@Store/hooks';
import useDebouncedInput from '@Hooks/useDebouncedInput';
import MultipleDatePicker from '@Components/common/FormUI/MultipleDatePicker/MultipleDatePicker';
import FilterSearchCategory from './FilterSearchCategory';

const categories = ['Programme', 'First Tier Partner', 'Sector', 'Marker'];
const excludeFilterItems = ['First Tier Partner', 'Sector'];
const knowledgeRepoFilters = ['Programme', 'Sector'];
const filColHeight = '224';

const Filters = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const isInitialFilterStateFetch = useRef(true);
  // const [filteredOptionsData, setFilteredOptionsData] = useState<any>([]);
  const [filterHeight, setFilterHeight] = useState(filColHeight || '224');
  const [searchFilterTerm, setSearchFilterTerm] = useState('');
  const [searchPartner, setSearchPartner] = useState('');
  const [seacrhSector, setSearchSector] = useState('');
  const [searchMarker, setSearchMarker] = useState('');
  const [programmeFilterCount, setProgrammeFilterCount] = useState(0);
  const [partnerFilterCount, setPartnerFilterCount] = useState(0);
  const [sectorFilterCount, setSectorFilterCount] = useState(0);
  const [markerFilterCount, setMarkerFilterCount] = useState(0);

  const mappedFilterCount: Record<string, any> = {
    Programme: programmeFilterCount,
    'First Tier Partner': partnerFilterCount,
    Sector: sectorFilterCount,
    Marker: markerFilterCount,
  };

  const filterState = useTypedSelector(
    state => state.mainDashboard.filterState,
  );

  const startDate = useTypedSelector(
    state => state?.mainDashboard?.filterParams?.startDate,
  );
  const endDate = useTypedSelector(
    state => state?.mainDashboard?.filterParams?.endDate,
  );

  const programmeFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.program,
  );

  const projectFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.component,
  );

  const firstTierPartnerParams = useTypedSelector(
    state => state.mainDashboard.filterParams.firstTierPartner,
  );

  const sectorGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.sector,
  );

  const markerGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.subMarkers,
  );

  const [searchFilter, handleFilterChange] = useDebouncedInput({
    ms: 400,
    init: searchFilterTerm,
    onChange: debouncedEvent =>
      setSearchFilterTerm(debouncedEvent.target.value),
  });

  const [searchPartnerTerm, handlePartnerChange] = useDebouncedInput({
    ms: 400,
    init: searchPartner,
    onChange: debouncedEvent => setSearchPartner(debouncedEvent.target.value),
  });

  const [searchSectorTerm, handleSectorChange] = useDebouncedInput({
    ms: 400,
    init: seacrhSector,
    onChange: debouncedEvent => setSearchSector(debouncedEvent.target.value),
  });

  const [searchMarkerTerm, handleMarkerChange] = useDebouncedInput({
    ms: 400,
    init: searchMarker,
    onChange: debouncedEvent => setSearchMarker(debouncedEvent.target.value),
  });

  function updateFilterState(fetchedState: Record<string, any>[]) {
    return filterState?.map(currentCategory => {
      const fetchedCategory = fetchedState.find(
        category => category.id === currentCategory.id,
      );

      if (!fetchedCategory) {
        return currentCategory;
      }
      const currentSubcategoryMap: Record<string, any> = {};
      currentCategory.subcategories?.forEach((subcategory: { id: string }) => {
        currentSubcategoryMap[subcategory.id] = subcategory;
      });

      const updatedSubcategories = fetchedCategory.subcategories
        .map((fetchedSubcategory: Record<string, any>) => {
          const existingSubcategory =
            currentSubcategoryMap[fetchedSubcategory.id];

          if (existingSubcategory) {
            return {
              ...existingSubcategory,
              filterItems: fetchedSubcategory.filterItems?.map(
                (fetchedFilter: Record<string, any>) => {
                  const existingFilter = existingSubcategory?.filterItems.find(
                    (filter: Record<string, any>) =>
                      filter.id === fetchedFilter.id,
                  );

                  return existingFilter || { ...fetchedFilter };
                },
              ),
            };
          }
          return { ...fetchedSubcategory };
        })
        ?.sort((a: Record<string, any>, b: Record<string, any>) => {
          return b.checked - a.checked;
        });

      return {
        ...currentCategory,
        subcategories: updatedSubcategories,
      };
    });
  }

  useQuery({
    queryKey: [
      'filter-list',
      searchFilter,
      searchPartnerTerm,
      searchSectorTerm,
      searchMarkerTerm,
      programmeFilterParams,
      projectFilterParams,
      firstTierPartnerParams,
      sectorGroupParams,
      markerGroupParams,
    ],
    queryFn: () =>
      getFilterList({
        program: searchFilter,
        first_tier_partner: searchPartnerTerm,
        sector: searchSectorTerm,
        marker: searchMarkerTerm,
        program_id: programmeFilterParams?.join(','),
        project_id: projectFilterParams?.join(','),
        first_tier_partner_id: firstTierPartnerParams?.join(','),
        sector_id: sectorGroupParams?.join(','),
        marker_id: markerGroupParams?.join(','),
      }),
    select: (res: any) => res?.data,
    onSuccess: res => {
      const filterData = convertFilterData(res);
      if (pathname?.includes('knowledge-library')) {
        const filteredData = filterData.filter(filterItem =>
          knowledgeRepoFilters?.includes(filterItem.category),
        );
        const updatedFilter = updateFilterState(filteredData);
        dispatch(setMainDashboardStates({ filterState: updatedFilter }));
        return;
      }
      if (isInitialFilterStateFetch.current) {
        dispatch(setMainDashboardStates({ filterState: filterData }));
        isInitialFilterStateFetch.current = false;
        return;
      }
      const updatedFilter = updateFilterState(filterData);
      dispatch(setMainDashboardStates({ filterState: updatedFilter }));
    },
  });

  // check single filter count
  const checkSingleFilter = useCallback(
    (category: string) => {
      const result = filterState?.find(
        filterItem => filterItem.category === category,
      );
      const checkedCount =
        result?.subcategories.filter((i: any) => i.checked).length || 0;
      switch (category) {
        case 'Programme':
          setProgrammeFilterCount(checkedCount);
          break;
        case 'First Tier Partner':
          setPartnerFilterCount(checkedCount);
          break;
        case 'Sector':
          setSectorFilterCount(checkedCount);
          break;
        case 'Marker':
          setMarkerFilterCount(checkedCount);
          break;
        default:
          break;
      }
    },
    [filterState],
  );

  useEffect(() => {
    categories.forEach(category => checkSingleFilter(category));
  }, [filterState, checkSingleFilter]);

  useEffect(() => {
    if (isEmpty(filterState as [])) return;
    dispatch(setFilterCount());
    dispatch(postFilterParams());
  }, [filterState, dispatch, endDate]);

  useEffect(() => {
    if (pathname?.includes('knowledge-library')) {
      setFilterHeight('300');
    }
  }, [pathname]);

  return (
    <div className="filter-col naxatw-h-full naxatw-min-w-[15rem] naxatw-rounded-[0.8rem] naxatw-border-[1.124px] naxatw-border-solid naxatw-border-primary-200 naxatw-bg-white naxatw-shadow-xs">
      {/* ------------------ filter header  --------------------> */}
      <div className="filter-header naxatw-flex naxatw-items-center naxatw-justify-between naxatw-border-b naxatw-border-solid naxatw-border-b-[#E0E0E0] naxatw-px-3 naxatw-py-2">
        <div className="naxatw-flex naxatw-items-center naxatw-gap-2">
          <p className="naxatw-text-base naxatw-font-medium naxatw-tracking-[0.00625rem] naxatw-text-gray-800">
            All Filters
          </p>
        </div>

        <button
          type="button"
          className="naxatw-group naxatw-flex naxatw-items-center naxatw-gap-1 naxatw-px-4 naxatw-py-2"
          onClick={() => {
            dispatch(resetFilterState());
          }}
        >
          <Icon
            name="restart_alt"
            className="naxatw-text-secondary-500 naxatw-duration-300 group-hover:naxatw-text-primary-700"
          />

          <span className="naxatw-mb-1 naxatw-text-[0.875rem] naxatw-font-medium naxatw-leading-[0] naxatw-text-secondary-500 naxatw-duration-300 group-hover:naxatw-text-primary-700">
            Clear All
          </span>
        </button>
      </div>
      {/* ------------------ filter body  --------------------> */}

      <div
        className="filter-body scrollbar naxatw-overflow-y-scroll naxatw-px-4 naxatw-py-3"
        style={{
          maxHeight: `calc(100vh - ${filterHeight}px)`,
        }}
      >
        <div className="naxatw-mb-5">
          <MultipleDatePicker
            startDate={startDate}
            setStartDate={(date: Date) =>
              dispatch(setDateRangeState({ type: 'startDate', date }))
            }
            endDate={endDate}
            setEndDate={(date: Date) =>
              dispatch(setDateRangeState({ type: 'endDate', date }))
            }
            clearDateRange={() =>
              dispatch(setDateRangeState({ type: 'clear' }))
            }
          />
        </div>
        <Accordion type="single" defaultValue="item-1" collapsible>
          {filterState?.map((filterData: Record<string, any>) => {
            return (
              <AccordionItem
                value={`item-${filterData?.id}`}
                key={`${filterData?.id}-id`}
                className="naxatw-border-b-[#E0E0E0] naxatw-py-3 first:naxatw-pt-0"
              >
                <AccordionTrigger className="naxatw-h-[1.5rem] !naxatw-py-0 hover:!naxatw-no-underline">
                  <div className="naxatw-flex naxatw-items-center naxatw-gap-2">
                    <p className="naxatw-text-base naxatw-font-medium naxatw-leading-[0] naxatw-tracking-[0.00625rem] naxatw-text-matt-100">
                      {filterData?.category}
                    </p>
                    {mappedFilterCount[filterData?.category] >= 1 && (
                      <div className="naxatw-flex naxatw-h-5 naxatw-w-5 naxatw-items-center naxatw-justify-center naxatw-rounded-[100px] naxatw-bg-[#F1F1F1] naxatw-p-[1px]">
                        <p className="naxatw-tooltip !naxatw-text-matt-100">
                          {mappedFilterCount[filterData?.category]}
                        </p>
                      </div>
                    )}
                  </div>

                  <ChevronDown
                    className="naxatw-h-4 naxatw-w-4 naxatw-shrink-0 naxatw-transition-transform naxatw-duration-200"
                    style={{
                      strokeWidth: '3px',
                    }}
                  />
                </AccordionTrigger>
                <AccordionContent>
                  {filterData?.category === 'Programme' && (
                    <FilterSearchCategory
                      category={`Search ${filterData?.category}`}
                      value={searchFilter}
                      onChange={handleFilterChange}
                    />
                  )}
                  {filterData?.category === 'First Tier Partner' && (
                    <FilterSearchCategory
                      category={`Search ${filterData?.category}`}
                      value={searchPartnerTerm}
                      onChange={handlePartnerChange}
                    />
                  )}
                  {filterData?.category === 'Sector' && (
                    <FilterSearchCategory
                      category={`Search ${filterData?.category}`}
                      value={searchSectorTerm}
                      onChange={handleSectorChange}
                    />
                  )}
                  {filterData?.category === 'Marker' && (
                    <FilterSearchCategory
                      category={`Search ${filterData?.category}`}
                      value={searchMarkerTerm}
                      onChange={handleMarkerChange}
                    />
                  )}
                  {filterData?.subcategories ? (
                    <Accordion type="single" collapsible>
                      {filterData?.subcategories?.map(
                        (subCatData: Record<string, any>) => (
                          <AccordionItem
                            value={`sub-item-${subCatData?.id}`}
                            key={subCatData?.id}
                            className="!naxatw-border-none naxatw-py-[6px] naxatw-pl-3 first:naxatw-mt-2"
                          >
                            <AccordionTrigger className="naxatw-justify-between !naxatw-py-0 hover:!naxatw-no-underline">
                              <div className="naxatw-flex naxatw-items-center naxatw-gap-2">
                                <Checkbox
                                  className="filter-checkbox"
                                  checked={subCatData?.checked}
                                  onChange={() => {
                                    dispatch(
                                      toggleFilterState({
                                        categoryId: subCatData?.categoryId,
                                        subcategoryId: subCatData?.id,
                                      }),
                                    );
                                  }}
                                />
                                <p
                                  className="naxatw-line-clamp-1 naxatw-text-[0.875rem] naxatw-font-normal naxatw-leading-5 naxatw-text-matt-100"
                                  title={subCatData?.subcategory}
                                >
                                  {subCatData?.subcategory}
                                </p>
                              </div>
                              {excludeFilterItems?.includes(
                                filterData.category,
                              ) ? null : (
                                <ChevronDown
                                  className="naxatw-h-4 naxatw-w-4 naxatw-shrink-0 naxatw-transition-transform naxatw-duration-200"
                                  style={{
                                    strokeWidth: '2px',
                                  }}
                                />
                              )}
                            </AccordionTrigger>

                            {!isEmpty(subCatData?.filterItems) ? (
                              <AccordionContent className="naxatw-mt-2 naxatw-gap-1">
                                {subCatData?.filterItems?.map(
                                  (filterItem: Record<string, any>) => {
                                    return (
                                      <div
                                        key={filterItem?.filter}
                                        className="naxatw-mb-1 naxatw-flex naxatw-items-center naxatw-gap-2 naxatw-pl-[1.625rem] last:naxatw-mb-0"
                                      >
                                        <Checkbox
                                          className="filter-checkbox"
                                          checked={filterItem?.checked}
                                          onChange={() => {
                                            dispatch(
                                              toggleFilterState({
                                                categoryId:
                                                  subCatData?.categoryId,
                                                subcategoryId: subCatData?.id,
                                                filterId: filterItem?.id,
                                              }),
                                            );
                                          }}
                                        />
                                        <p>{filterItem?.filter}</p>
                                      </div>
                                    );
                                  },
                                )}
                              </AccordionContent>
                            ) : null}
                          </AccordionItem>
                        ),
                      )}
                    </Accordion>
                  ) : null}
                </AccordionContent>
              </AccordionItem>
            );
          })}
        </Accordion>
      </div>
    </div>
  );
};

export default hasErrorBoundary(Filters);
