/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Button } from '@Components/RadixComponents/Button';
import ErrorMessage from '@Components/common/ErrorMessage';
import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import RoleSkeleton from '@Components/common/FormComponent/Skeletons/RoleSkeleton';
import { FormControl, Input } from '@Components/common/FormUI';
import Checkbox from '@Components/common/FormUI/CheckBox';
import IconButton from '@Components/common/IconButton';
import InputLabel from '@Components/common/InputLabel';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import {
  getPermissionsList,
  postUserRole,
  postUserPermissions,
  getIndividualUserGroupPermission,
  getIndividualUserGroupRole,
} from '@Services/userSecurity';
import {
  RoleFormValues,
  rolePropType,
} from '@Constants/Types/FormTypes/userSecurity';
import { mappedPermissionName } from '@Constants/FormConstants/userSecurityFormConstants';
import isEmpty from '@Utils/isEmpty';

import { compareObjects } from '@Utils/index';

const RoleForm = ({ onClose }: rolePropType) => {
  const { roleId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [permissionBeforePatch, setActualPermissionBeforePatch] = useState({});

  const {
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    register,
    control,
    setValue,
    reset,
  } = useForm<RoleFormValues>({
    mode: 'onTouched',
    defaultValues: {
      role: '',
      permissionsList: {},
    },
  });

  // fetch permission list
  const { data: rolesLists, isFetching: rolesListsFetching } = useQuery({
    queryKey: ['get-permissions-list'],
    queryFn: () => getPermissionsList(),
    select: (res: any) => res?.data,
  });

  // fetch permissionlist to populate on edit
  const { isFetching: isUserGroupPermissionFetching } = useQuery({
    queryKey: ['get-user-group-permsission'],
    queryFn: () => getIndividualUserGroupPermission(roleId),
    enabled: !!roleId,
    onSuccess: response => {
      if (isEmpty(response?.data)) return;
      const permissionData = response.data;
      if (!permissionData) return;
      const checkedData = permissionData.reduce(
        (acc: Record<string, any>, obj: Record<string, any>) => {
          const [key, value] = Object.entries(obj)[0];
          if (Array.isArray(value) && value.length > 0) {
            value.forEach(item => {
              acc[item.name] = true;
            });
          }
          return acc;
        },
        {},
      );
      setValue('permissionsList', checkedData);
      setActualPermissionBeforePatch(checkedData);
    },
  });

  // fetch role name to populate on edit
  const { isFetching: isUserGroupRoleFetching } = useQuery({
    queryKey: ['get-user-group-role'],
    queryFn: () => getIndividualUserGroupRole(roleId),
    enabled: !!roleId,
    onSuccess: response => {
      if (response?.data) {
        const roleName = response?.data?.[0]?.name;
        setValue('role', roleName);
      }
    },
  });

  // post role form
  const { mutateAsync: postRoleData } = useMutation({
    mutationFn: (payloadData: Record<string, any>) => postUserRole(payloadData),
  });

  // post permsission
  const { mutateAsync: postPermissionsData } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postUserPermissions(payloadData),
  });

  // patch role form
  const patchRoleForm = async (submittedData: Record<string, any>) => {
    if (!isDirty) return;
    const { permissionsList: permissionListAfterPatch } = submittedData;
    const permissionListToPatch = compareObjects(
      permissionBeforePatch,
      permissionListAfterPatch,
    );

    const finalPermissionListToPatch = Object.keys(
      permissionListToPatch,
    ).reduce((acc: Record<string, any>, cur: any) => {
      const newKey = mappedPermissionName[cur];
      acc[newKey] = permissionListToPatch[cur];
      return acc;
    }, {});

    const addPermissions = Object.keys(finalPermissionListToPatch).filter(
      key => finalPermissionListToPatch[key],
    );
    const removePermissions = Object.keys(finalPermissionListToPatch).filter(
      key => !finalPermissionListToPatch[key],
    );

    await postPermissionsData({
      group_id: roleId,
      add_permissions: addPermissions ? addPermissions?.join(',') : '',
      remove_permissions: removePermissions ? removePermissions?.join(',') : '',
    });
  };

  // post role form
  const postRoleForm = async (submittedData: Record<string, any>) => {
    const { role, permissionsList } = submittedData;
    const permissionToPost = Object.keys(permissionsList).filter(
      key => permissionsList[key],
    );

    const mappedPermissionNames = permissionToPost?.map(
      (list: string) => mappedPermissionName[list],
    );

    const responseRoleId = await postRoleData({ name: role });
    await postPermissionsData({
      group_id: responseRoleId?.data?.id,
      add_permissions: mappedPermissionNames?.join(','),
    });
  };

  const onSubmit = async (submittedData: RoleFormValues) => {
    try {
      if (roleId) {
        await patchRoleForm(submittedData);
      } else {
        await postRoleForm(submittedData);
      }

      queryClient.invalidateQueries({
        queryKey: ['role-tabledata'],
      });
      reset();
      toast.success(
        roleId ? 'Role updated successfully' : 'Role added successfully',
      );
      navigate('/data-bank/user-security/role-management');
    } catch (error: any) {
      const caughtError = error?.response?.data?.message;
      toast.error(caughtError || 'Error occured while submitting!');
    }
  };

  return (
    <div className="naxatw-absolute naxatw-left-1/2 naxatw-top-1/2 naxatw-flex naxatw-max-h-[90vh] naxatw-w-[90%] -naxatw-translate-x-1/2 naxatw-translate-y-[calc(-50%+31.5px)] naxatw-flex-col naxatw-overflow-hidden naxatw-rounded-lg naxatw-border naxatw-border-gray-300 naxatw-bg-[#fff] naxatw-transition-all naxatw-duration-200 md:naxatw-min-w-[32rem] xl:naxatw-w-[42.75rem] 2xl:naxatw-w-[55.75rem]">
      <div className="naxatw-flex naxatw-items-center naxatw-justify-between naxatw-self-stretch naxatw-px-7 naxatw-py-5 naxatw-shadow-[0px_2px_20px_4px_rgba(0,0,0,0.12)]">
        <BreadCrumb
          heading={`Role Management / ${roleId ? 'Edit' : 'Add'}`}
          overlayStatus={() => onClose()}
        />
        <IconButton
          name="close"
          className="!naxatw-h-9 !naxatw-w-9 naxatw-gap-1 naxatw-rounded-lg hover:naxatw-bg-gray-100"
          iconClassName="naxatw-font-normal naxatw-text-[#757575] naxatw-text-[24px] naxatw-leading-[24px]"
          onClick={() => onClose()}
        />
      </div>
      <div className="role-management-container">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="naxatw-px-6 naxatw-pt-5">
            <FormControl className="naxatw-mb-1">
              <div className="naxatw-mb-5 naxatw-w-full">
                <div className="naxatw-mb-1">
                  <InputLabel id="Role" label="Role" astric />
                </div>
                <Input
                  type="text"
                  placeholder="Enter Role"
                  className="naxatw-w-full naxatw-max-w-full disabled:naxatw-cursor-not-allowed"
                  {...register('role', {
                    required: {
                      value: true,
                      message: ' Role is Required',
                    },
                  })}
                  disabled={!!roleId}
                />
                {errors?.role?.message && (
                  <ErrorMessage
                    message={errors?.role?.message}
                    className="naxatw-pl-1 naxatw-pt-2"
                  />
                )}
              </div>
            </FormControl>
            <div className="scrollbar naxatw-h-[calc(100vh-26rem)] naxatw-overflow-y-scroll naxatw-pb-5 naxatw-pr-2">
              <div>
                {rolesListsFetching ||
                isUserGroupPermissionFetching ||
                isUserGroupRoleFetching ? (
                  <RoleSkeleton />
                ) : (
                  <div className="naxatw-grid naxatw-h-full naxatw-grid-cols-2 naxatw-gap-5">
                    {rolesLists?.map((itm: Record<string, any>) => (
                      <div className="naxatw-h-full" key={itm.id}>
                        <div className="naxatw-mb-2">
                          <p className="naxatw-table-body !naxatw-leading-normal">
                            {Object.keys(itm) || ''}
                          </p>
                        </div>
                        <FlexColumn className="naxatw-gap-2 naxatw-rounded-lg naxatw-bg-primary-100 naxatw-p-3">
                          {itm[Object.keys(itm)[0]]?.map((listName: any) => {
                            return (
                              <Controller
                                key={listName.index}
                                control={control}
                                name="permissionsList"
                                defaultValue={{}}
                                render={({ field: { onChange, value } }) => (
                                  <Checkbox
                                    onChange={() => {
                                      const selectedName = listName.name;
                                      const isChecked = !value[selectedName];
                                      const newCheckedItems: any = {
                                        ...value,
                                        [selectedName]: isChecked,
                                      };
                                      onChange(newCheckedItems);
                                    }}
                                    label={listName.name}
                                    id={listName.id}
                                    checked={!!value[listName.name]}
                                  />
                                )}
                              />
                            );
                          })}
                        </FlexColumn>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          <FlexRow className="naxatw-justify-center naxatw-py-5 naxatw-shadow-light">
            <Button
              type="submit"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Save
            </Button>
          </FlexRow>
        </form>
      </div>
    </div>
  );
};

export default RoleForm;
