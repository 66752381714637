import { z } from 'zod';

export const ProgramFormValidationSchema = z
  .object({
    code: z
      .string()
      .trim()
      .length(6, 'Programme code is Required and must be upto 6 numbers'),
    name: z
      .string()
      .trim()
      .min(1, 'Programme Name is Required')
      .min(5, 'Name should be atleast 5 characters'),
    abbreviation: z
      .string()
      .trim()
      .optional()
      .refine(value => value === undefined || !value.includes(' '), {
        message: 'Abbreviation cannot contain space',
      }),
    program_type: z
      .string()
      .trim()
      .min(1, { message: 'Programme Type is Required' }),
    start_date: z.string().trim().min(1, { message: 'Start date is Required' }),
    end_date: z.string().trim().min(1, { message: 'End date is Required' }),
    // is_extension: z
    //   .string()
    //   .trim()
    //   .min(1, { message: 'Programme Extended is Required' }),
    // extended_date: z.string().nullable().optional(),
    is_successor: z.string().trim().min(1, { message: 'Value is Required' }),
    previous_program: z.array(z.number()).nullable().optional(),
    campaign_goal: z.string().nullable().optional(),
  })
  .refine(
    data => {
      if (data.is_successor === 'true') {
        return !!data.previous_program;
      }
      return true;
    },
    { message: 'Previous programme is Required', path: ['previous_program'] },
  )
  // .refine(
  //   data => {
  //     if (data.is_extension === 'true') {
  //       return !!data.extended_date;
  //     }
  //     return true;
  //   },
  //   { message: 'Extended date is Required', path: ['extended_date'] },
  // )
  .refine(
    data => {
      if (data.start_date && data.end_date) {
        return data.start_date < data.end_date;
      }
      return true; // No error if one or both dates are missing
    },
    {
      message: 'End date must be after start date.',
      path: ['end_date'],
    },
  );
// .refine(
//   data => {
//     if (data.end_date && data.extended_date) {
//       return data.end_date <= data.extended_date;
//     }
//     return true; // No error if one or both dates are missing
//   },
//   {
//     message: 'Extended date must be after end date.',
//     path: ['extended_date'],
//   },
// );

export type ProgramFormValidationSchemaProps = z.infer<
  typeof ProgramFormValidationSchema & {
    [key: string]: any; // Adding an index signature
  }
>;
