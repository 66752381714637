import { api, authenticated } from '.';

export const getIndicators = (params: Record<string, any>) => {
  return authenticated(api).get(`/indicator/`, params);
};

export const getIndicatorById = (id: string) => {
  return authenticated(api).get(`/indicator/${id}/`);
};

export const getIndicatorDisaggregationById = (id: string) => {
  return authenticated(api).get(`/indicator-disaggregation/?indicator=${id}`);
};

export const getIndicatorProgramComponent = (id: string) => {
  return authenticated(api).get(
    `/indicator-program-component/?indicator=${id}`,
  );
};

export const patchIndicator = (id: string, params: Record<string, any>) => {
  return authenticated(api).patch(`/indicator/${id}/`, params);
};

export const patchIndicatorDisaggregation = (
  id: string,
  params: Record<string, any>,
) => {
  return authenticated(api).patch(`/indicator-disaggregation/${id}/`, params);
};

export const postIndicator = (params: Record<string, any>) => {
  return authenticated(api).post(`/indicator/`, params);
};

export const postIndicatorDisaggregation = (params: Record<string, any>) => {
  return authenticated(api).post(`/indicator-disaggregation/`, params);
};

export const postIndicatorProgramComponent = (payload: Record<string, any>) => {
  return authenticated(api).post(`/indicator-program-component/`, payload);
};

export const deleteIndicatorById = (id: string) => {
  return authenticated(api).delete(`/indicator/${id}/`);
};

export const deleteIndicatorDisaggregationById = (id: number) => {
  return authenticated(api).delete(`/indicator-disaggregation/${id}/`);
};

export const getProgramDropdown = () => {
  return authenticated(api).get(`/program-dropdown/`);
};

export const deleteIndicatorProgramComponent = (id: number) => {
  return authenticated(api).delete(`/indicator-program-component/${id}/`);
};

export const getComponentDropdown = (
  params: { program: number | null } | {},
) => {
  return authenticated(api).get(`/component-dropdown/`, { params });
};

export const getMeasurementUnit = () => {
  return authenticated(api).get(`/choice/?type=measurement_unit`);
};
