/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import DataTable from '@Components/common/DataTable';
import {
  deletePortfolioRegistration,
  getPortfolioRegistration,
} from '@Services/portfolioReporting';
import { FlexRow } from '@Components/common/Layouts';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { useTypedSelector } from '@Store/hooks';
import { useNavigate } from 'react-router-dom';
import Portal from '@Components/common/Layouts/Portal';
import DeleteConfirmationOverlay from '@Components/common/PopupOverlays/DeleteConfirmationOverlay';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { format, parse } from 'date-fns';
// import Modal from '@Components/common/Modal';
// import { Button } from '@Components/RadixComponents/Button';

const PartnerReportingTable = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    useState<boolean>(false);
  const [partnerIDToDelete, setPartnerIDToDelete] = useState<string>('');
  // const [toggleConfirmationModal, setToggleConfirmationModal] = useState(false);
  // const [updateId, setUpdateId] = useState<number | null>(null);

  const searchTerm = useTypedSelector(
    state => state.common.adminSearchState?.['component-update'],
  );

  const {
    mutate: deletePartners,
    isError,
    error,
    isLoading,
  } = useMutation({
    mutationFn: () => deletePortfolioRegistration(partnerIDToDelete),
    onSuccess: () => {
      toast.success('Delete Success');
      setOpenDeleteConfirmation(false);
      queryClient.invalidateQueries({
        queryKey: ['partner-reporting-tabledata'],
      });
    },
  });

  const columns = [
    // {
    //   header: 'PROJECT CODE',
    //   accessorKey: 'project_code',
    //   // cell: ({ cell }: { cell: any }) => cell.row.index + 1,
    // },
    {
      header: 'PROGRAMME',
      accessorKey: 'program',
    },
    {
      header: 'PROJECT',
      accessorKey: 'project',
    },
    {
      header: 'FIRST TIER PARTNER',
      accessorKey: 'first_tier_partner',
    },
    {
      header: 'LAST UPDATED ON',
      accessorKey: 'year_of_reporting',
      cell: ({ row }: any) => {
        const yearOfReporting = row?.original?.year_of_reporting;
        const parsedYear = parse(yearOfReporting, 'M/d/yyyy', new Date());
        const date = format(parsedYear, 'yyyy-MM-dd');
        return <span>{date || '-'}</span>;
      },
    },
    {
      header: '',
      accessorKey: 'icon',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ row }: any) => {
        const cellId = row?.original?.project_data_id;
        return (
          <FlexRow className="naxatw-relative naxatw-mr-6 naxatw-cursor-pointer naxatw-justify-end naxatw-gap-4">
            {/* <ToolTip
              name="update"
              message="Update"
              className="naxatw-text-icon-md naxatw-text-matt-100 naxatw-duration-200"
              iconClick={() => {
                setToggleConfirmationModal(true);
                setUpdateId(cellId);
              }}
            /> */}
            <ToolTip
              name="edit"
              message="Edit"
              className="naxatw-text-icon-md naxatw-text-matt-100 naxatw-duration-200"
              iconClick={() => {
                // setToggleConfirmationModal(true);
                // setUpdateId(cellId);
                navigate(`/data-bank/project-data/edit/${cellId}`);
              }}
            />
            <ToolTip
              name="delete"
              message="Delete"
              className="naxatw-text-icon-md naxatw-text-matt-100 naxatw-duration-200 hover:naxatw-text-red-500"
              iconClick={() => {
                setOpenDeleteConfirmation(prev => !prev);
                setPartnerIDToDelete(cellId);
              }}
            />
          </FlexRow>
        );
      },
    },
  ];
  return (
    <>
      {/* {toggleConfirmationModal && (
        <Portal>
          <Modal
            headerContent={
              <h6 className="naxatw-my-auto naxatw-px-2 !naxatw-leading-none">
                Edit or Update
              </h6>
            }
            // hideCloseButton
            show={toggleConfirmationModal}
            className="naxatw-w-full naxatw-max-w-[32.563rem] !naxatw-rounded-lg"
            title=""
            onClose={() => {
              setToggleConfirmationModal(false);
            }}
          >
            <div>
              <p className="naxatw-body-caption">
                Do you want to edit or update the form ?
              </p>
              <FlexRow className="naxatw-mt-5 naxatw-justify-end naxatw-gap-2">
                <Button
                  variant="link"
                  onClick={() => {
                    navigate(`/data-bank/component-update/edit/${updateId}`);
                  }}
                >
                  Update
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    navigate(`/data-bank/component-update/edit/${updateId}`);
                  }}
                >
                  Edit
                </Button>
              </FlexRow>
            </div>
          </Modal>
        </Portal>
      )} */}

      <div className="naxatw-h-[calc(100%-10rem)] naxatw-w-full lg:naxatw-h-[calc(100%-7.875rem)] ">
        <DataTable
          columns={columns}
          queryKey="partner-reporting-tabledata"
          queryFn={getPortfolioRegistration}
          initialState={{
            paginationState: {
              pageIndex: 0,
              pageSize: 25,
            },
          }}
          searchInput={searchTerm || ''}
        />
        {openDeleteConfirmation && (
          <Portal>
            <DeleteConfirmationOverlay
              onClose={() => setOpenDeleteConfirmation(false)}
              onDelete={() => deletePartners()}
              isError={isError}
              isLoading={isLoading}
              error={error}
            />
          </Portal>
        )}
      </div>
    </>
  );
};

export default PartnerReportingTable;
