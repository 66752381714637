import MainDashboard from '@Components/MainDashboard';

const MainDashboardPage = () => {
  return (
    <div className="naxatw-h-[calc(100vh-80px)] naxatw-w-full naxatw-overflow-x-hidden naxatw-bg-[#FFF]">
      <MainDashboard />
    </div>
    // </main>
  );
};

export default MainDashboardPage;
