/* eslint-disable import/prefer-default-export */
import { api, authenticated } from '.';

export const postMapLayers = (data: any) => {
  return authenticated(api).post(`/vectorlayer/`, data);
};

export const getMapLayers = () => {
  return authenticated(api).get('/vectorlayer/');
};

export const getIndividualMapLayer = (id: any) => {
  return authenticated(api).get(`/vectorlayer/${id}/`);
};

export const patchMapLayer = (params: Record<string, any>) => {
  const { id, ...restData } = params;
  return authenticated(api).patch(`/vectorlayer/${id}/`, restData);
};

export const deleteMapLayer = (id: number) => {
  return authenticated(api).delete(`/vectorlayer/${id}/`);
};
