export type markersValuesFormState = {
  name: string;
  category: string;
};

export const validateForm = (formState: markersValuesFormState) => {
  const error = {
    name: false,
    category: false,
  };

  if (!formState.name) {
    error.name = true;
  }
  if (!formState.category) {
    error.category = true;
  }
  return error;
};
