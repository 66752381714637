export interface IChildLinkData {
  id: number;
  name: string;
  icon: string;
  shortDescription: string;
  link: string;
}

export interface INavbarLinkData {
  id: number;
  name: string;
  link?: string;
  children?: IChildLinkData[];
}

// eslint-disable-next-line import/prefer-default-export
export const navbarData: INavbarLinkData[] = [
  {
    id: 1,
    name: 'BEK Footprint',
    link: '/dashboard/map',
  },
  {
    id: 2,
    name: 'Portfolio Profiles',
    link: '/explore-by/geography/',
  },
  // {
  //   id: 3,
  //   name: 'Portfolio Results',
  //   link: '/portfolio-results',
  // },
  {
    id: 4,
    name: 'Knowledge Library',
    link: '/knowledge-library',
  },
  {
    id: 5,
    name: 'Data Bank',
    link: '/data-bank/report',
  },
];
