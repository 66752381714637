import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
import { Button } from '@Components/RadixComponents/Button';
import { toast } from 'react-toastify';
import isEmpty from '@Utils/isEmpty';
import {
  postProvinicalMinistry,
  deleteProvinicalMinistry,
  getFederalMinistry,
  getProvinicialMinstry,
} from '@Services/goals';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import IconButton from '@Components/common/IconButton';
import { FlexColumn } from '@Components/common/Layouts';
import { Input } from '@Components/common/FormUI';
import InputLabel from '@Components/common/InputLabel';
import ToolTip from '@Components/RadixComponents/ToolTip';
import ErrorMessage from '@Components/common/ErrorMessage';

const ProvincialMinistryForm = ({ onClose }: any) => {
  const { Id } = useParams();
  const navigate = useNavigate();
  const [actualMinistry, setActualMinistry] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const locationParam = queryParams.get('location');
  const queryClient = useQueryClient();
  const [emptyError, setEmptyError] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      ministries: [],
    },
  });

  const { getValues, setValue, handleSubmit, control, reset } = formMethods;

  const watchMinistry = useWatch({
    control,
    name: 'ministries',
  });

  // add new ministry
  const handleAddMinistry = (e: any) => {
    e.preventDefault();
    if (inputRef.current?.value === '') {
      setEmptyError(true);
      return;
    }
    const newValue = inputRef.current?.value;
    /* @ts-ignore */
    setValue('ministries', [...watchMinistry, { name: newValue }]);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  // handleDelete ministry
  const handleDelete = (ministry: Record<string, any>) => {
    const filterredMinistry = watchMinistry?.filter(
      (item: Record<string, any>) => item?.name !== ministry,
    );
    setValue('ministries', filterredMinistry);
  };

  // edit ministry
  const editMinistry = (editItem: any) => {
    const filteredList = watchMinistry?.filter(
      (item: Record<string, any>) => item?.name !== editItem,
    );
    setValue('ministries', filteredList);
    if (inputRef.current) {
      inputRef.current.value = editItem;
    }
  };

  // post Provincial Ministry
  const {
    mutateAsync: postProvinicalMinistryData,
    isLoading: ispostProvinicalMinistryData,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postProvinicalMinistry(payloadData),
  });

  // delete provincial Ministry
  const {
    mutateAsync: deleteProvinicalMinistryData,
    isLoading: isDeleteProvinicalMinistryLoading,
  } = useMutation({
    mutationFn: id => deleteProvinicalMinistry(id),
  });

  // fetch provincial ministry to populate on edit
  const { isFetching: isProvincialMinistryFetching } = useQuery({
    queryKey: ['provinicial-ministry'],
    queryFn: () =>
      Id === 'federal'
        ? getFederalMinistry()
        : getProvinicialMinstry({
            provinceId: Id,
            type: 'Province Level',
          }),
    enabled: !!Id,
    select: (list: any) => list?.data,
    onError: () => toast.error('Error Occured!. Please try again.'),
    onSuccess: data => {
      if (isEmpty(data)) return;
      setActualMinistry(data);
      const setMinistry: any = [];
      data.map((item: Record<string, any>) =>
        setMinistry.push({ id: item.id, name: item.name }),
      );
      setValue('ministries', setMinistry);
    },
  });

  // submit minstries for post
  const submitDataForPost = async (ministries: Record<string, any>) => {
    if (isEmpty(ministries)) return;
    const ministryType = Id === 'federal' ? 'Federal Level' : 'Province Level';
    const stakeHolderType =
      Id === 'federal'
        ? 'Government Stakeholder'
        : 'Provincial Government Stakeholder';

    const actualDataMap = new Map(
      actualMinistry.map((item: Record<string, any>) => [item.id, item]),
    );
    const updatedDataMap = new Map(
      ministries.map((item: Record<string, any>) => [item.id, item]),
    );

    // ministry to be deleted
    const toBeDeleted = actualMinistry.filter(
      (item: Record<string, any>) => !updatedDataMap.has(item.id),
    );

    // ministry to be posted
    const toBePosted = ministries.filter(
      (item: Record<string, any>) => !item.id && !actualDataMap.has(item.id),
    );

    if (!isEmpty(toBePosted)) {
      const postMinistryData = async (ministry: Record<string, any>) => {
        const data: any = {
          name: ministry.name,
          ministry_type: ministryType,
          stakeholder_type: stakeHolderType,
        };
        if (ministryType === 'Province Level') {
          data.province = Number(Id);
        }
        return postProvinicalMinistryData(data);
      };
      await Promise.all(toBePosted.map(postMinistryData));
    }

    if (!isEmpty(toBeDeleted)) {
      await Promise.all(
        toBeDeleted.map((item: Record<string, any>) =>
          deleteProvinicalMinistryData(item.id),
        ),
      );
    }
  };

  // form handler
  const onSubmit = async () => {
    try {
      const { ministries } = getValues();
      if (isEmpty(ministries) && isEmpty(actualMinistry)) {
        setEmptyError(true);
        return;
      }
      if (isEmpty(ministries)) {
        await Promise.all(
          actualMinistry.map((item: Record<string, any>) =>
            deleteProvinicalMinistryData(item.id),
          ),
        );
      } else {
        await submitDataForPost(ministries);
      }

      queryClient.invalidateQueries({ queryKey: ['provinicial-ministry'] });
      reset();
      toast.success('Ministry Updated Successfully');
      navigate(`/data-bank/provincial-profile?location=${locationParam}`);
    } catch (error) {
      toast.error('Error occurred while submitting!');
    }
  };

  return (
    <div
      className="naxatw-absolute naxatw-left-1/2 naxatw-top-1/2 naxatw-flex naxatw-w-[90%] naxatw-max-w-[80%] naxatw-flex-col naxatw-rounded-2xl naxatw-border naxatw-border-gray-300 naxatw-bg-[#fff] naxatw-transition-all naxatw-duration-200 sm:naxatw-w-[34.75rem] xl:naxatw-w-[40%]"
      style={{ transform: 'translate(-50%, calc(-50% + 49px))' }}
    >
      <div className="naxatw-flex naxatw-items-center naxatw-justify-between naxatw-self-stretch naxatw-px-7 naxatw-py-5 naxatw-shadow-[0px_2px_20px_4px_rgba(0,0,0,0.12)]">
        <BreadCrumb
          heading="Provincial Ministries  / Form"
          overlayStatus={() => onClose()}
        />
        <IconButton
          name="close"
          className="!naxatw-h-9 !naxatw-w-9 naxatw-gap-1 naxatw-rounded-lg hover:naxatw-bg-gray-100"
          iconClassName="naxatw-font-normal naxatw-text-[#757575] naxatw-text-[24px] naxatw-leading-[24px]"
          onClick={() => onClose()}
        />
      </div>

      <div className="naxatw-w-full">
        <FlexColumn className="naxatw-relative naxatw-h-full naxatw-w-full">
          {isProvincialMinistryFetching ? (
            <div className="naxatw-w-full naxatw-p-6">
              <FormSkeleton numRows={3} className="naxatw-w-full" />
            </div>
          ) : (
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FlexColumn className="naxatw-w-full naxatw-gap-[0.469rem] naxatw-px-6 naxatw-pt-5">
                  <InputLabel id="ministry-name" label="Ministry Name" />
                  <div className="naxatw-flex naxatw-items-center naxatw-justify-between naxatw-gap-2">
                    <Input
                      type="text"
                      placeholder="Enter Ministry and Save"
                      className="!naxatw-w-full"
                      ref={inputRef}
                    />
                    <Button variant="secondary" onClick={handleAddMinistry}>
                      Save
                    </Button>
                  </div>
                  {emptyError ? (
                    <ErrorMessage
                      message="Enter Ministry to Save"
                      className="naxatw-pl-2"
                    />
                  ) : null}
                </FlexColumn>
                <div className="">
                  {!isEmpty(watchMinistry) ? (
                    <div className="ministry-data scrollbar naxatw-mx-5 naxatw-mt-5 naxatw-max-h-[400px] naxatw-overflow-y-scroll naxatw-rounded-lg naxatw-bg-primary-100 naxatw-p-3">
                      {watchMinistry?.map((ministry: Record<string, any>) => {
                        return (
                          <div key={ministry.name}>
                            <div className="naxatw-flex naxatw-h-9 naxatw-items-center ">
                              <p className="naxatw-body-caption naxatw-flex-1">
                                {ministry.name}
                              </p>
                              <div>
                                <ToolTip
                                  name="edit"
                                  message="Edit"
                                  className="naxatw-mr-2 naxatw-px-1 naxatw-py-2 naxatw-text-base naxatw-text-matt-200 naxatw-duration-200 hover:naxatw-text-red-500"
                                  iconClick={() => editMinistry(ministry.name)}
                                />
                              </div>
                              <ToolTip
                                name="close"
                                message="Delete"
                                className="naxatw-px-1 naxatw-py-2 naxatw-text-base naxatw-text-matt-200 naxatw-duration-200 hover:naxatw-text-red-500"
                                iconClick={() => handleDelete(ministry.name)}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
                <div className="naxatw-px-6 naxatw-py-5 naxatw-text-center">
                  <Button
                    size="normal"
                    type="submit"
                    variant="primary"
                    className="naxatw-w-fit naxatw-px-4"
                    isLoading={
                      ispostProvinicalMinistryData ||
                      isDeleteProvinicalMinistryLoading
                    }
                    disabled={
                      ispostProvinicalMinistryData ||
                      isDeleteProvinicalMinistryLoading
                    }
                  >
                    Create
                  </Button>
                </div>
              </form>
            </div>
          )}
        </FlexColumn>
      </div>
    </div>
  );
};

export default hasErrorBoundary(ProvincialMinistryForm);
