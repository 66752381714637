import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import AdminHeader from '@Components/common/AdminHeader';
import { useNavigate } from 'react-router-dom';
import ComponentUpdateTable from './ComponentUpdateTable';
import AdminHeaderSwitchTab from '../ComponentsHeader';
// import PartnerReportingHeader from './PartnerReportingHeader';

const PartnerReporting = () => {
  const navigate = useNavigate();

  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });

  return (
    <div className="naxatw-h-full  naxatw-space-y-6">
      <AdminHeader
        title="5WH Data Entry"
        onAddNewClick={() => {
          navigate('/data-bank/project-data/registration');
        }}
        hasBulkUpload={false}
        hasBulkUpDownload
      />

      {/* <PartnerReportingHeader /> */}
      <AdminHeaderSwitchTab />
      <animated.div style={{ ...springs }} className="naxatw-h-full">
        <ComponentUpdateTable />
      </animated.div>
    </div>
  );
};

export default PartnerReporting;
