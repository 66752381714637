import { api, fastApi, authenticated } from '.';

export const getTopFiveSectorsByComponent = (params: Record<string, any>) => {
  return authenticated(fastApi).get('/dashboard/top-sectors-project/', {
    params,
  });
};

export const getTopFiveSectorsByProgramme = (params: Record<string, any>) => {
  return authenticated(fastApi).get('/dashboard/top-sectors-program/', {
    params,
  });
};

export const getProgrammesByBudgetAllocated = (params: Record<string, any>) => {
  return authenticated(fastApi).get('/dashboard/top-program-budget/', {
    params,
  });
};

export const getStakeholdersSankeyData = (params: Record<string, any>) => {
  return authenticated(fastApi).get(
    '/infographics/stakeholder-engaged-with-program/',
    { params },
  );
};

export const getTopPartnerByBudget = (params: Record<string, any>) => {
  return authenticated(fastApi).get('/dashboard/top-partner-budget/', {
    params,
  });
};

export const getTopComponentProgram = (params: Record<string, any>) => {
  return authenticated(fastApi).get('/dashboard/top-project-program/', {
    params,
  });
};

export const getFederalWiseCount = (params: Record<string, any>) => {
  return authenticated(fastApi).get('/dashboard/federal_wise_count/', {
    params,
  });
};

export const getPopupData = (params: Record<string, any>) => {
  return authenticated(fastApi).get('/dashboard/program-details-map/', {
    params,
  });
};

export const getGovernmentStakeHoldersDataPro = (params: any) => {
  return authenticated(fastApi).get(
    '/dashboard/stakeholder-type-piechart/?by=project',
    { params },
  );
};

export const getGovernmentStakeHoldersDataProgramme = (params: any) => {
  return authenticated(fastApi).get(
    '/dashboard/stakeholder-type-piechart/?by=programme',
    { params },
  );
};

export const getOverlayLayerList = () => api.get('/layer-list/');

export const getFilterList = (params: any) => {
  return api.get(`/filter-list/`, { params });
};

export const getChartDescription = (params: any) =>
  fastApi.get('/dashboard/chart_description/', { params });

export const getMunicipalityList = (params: Record<string, any>) =>
  authenticated(api).get('/municipality/', { params });

export const getDistrictList = (params: Record<string, any>) =>
  authenticated(api).get('/district/', { params });

export const getProvinceList = (params: Record<string, any>) =>
  authenticated(api).get('/province/', { params });

export const downloadMapCsvData = (params: any) => {
  return authenticated(api).get(`/download-federal-info/?by=${params}`, {
    responseType: 'blob',
  });
};

export const downloadMapPopUpData = (key: string, id: number) => {
  return authenticated(api).get(
    `/download-federal-info/?by=${key}&${key}=${id}`,
    {
      responseType: 'blob',
    },
  );
};
