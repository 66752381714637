import ChartCardHeader from './Header';
import ProvincialProritiesTable from './Table/ProvincialProritiesTable';

const ProvincialPriorities = () => {
  return (
    <>
      <div className="naxatw-w-full naxatw-overflow-hidden naxatw-rounded-xl naxatw-border naxatw-border-[#EAECF0] naxatw-bg-white">
        <div className="naxatw-flex naxatw-flex-col">
          <ChartCardHeader title="Provincial Priorities Alignment with BEK Programme" />
          <div className="naxatw-h-[1px] naxatw-w-full naxatw-bg-[#EAECF0]" />
          <ProvincialProritiesTable />
        </div>
      </div>
    </>
  );
};

export default ProvincialPriorities;
