import { FormFieldProps } from '@Constants/interface/FormInterface';

/* eslint-disable import/prefer-default-export */
export const mapLayersFormFields: FormFieldProps[] = [
  {
    label: 'Name',
    inputType: 'text',
    type: 'input',
    placeholder: 'Enter Map Layer Name',
    id: 'name_en',
    name: 'name_en',
    required: true,
  },
  {
    label: 'Layer Type',
    inputType: 'text',
    type: 'select',
    placeholder: 'Layer Type',
    id: 'type_of_layer',
    name: 'type_of_layer',
    choose: 'value',
    isVisible: true,
    required: true,
  },
  {
    label: 'Geometry Type',
    inputType: 'text',
    type: 'select',
    placeholder: 'Geometry Type',
    id: 'geometry_type',
    name: 'geometry_type',
    choose: 'value',
    isVisible: true,
    required: true,
  },
  {
    label: 'Upload Map Layer',
    inputType: 'file',
    type: 'file-upload',
    placeholder: 'Please upload file (shp file format)',
    id: 'file_upload',
    name: 'file_upload',
    fileAccept: '*',
    multiple: false,
    required: true,
  },
];

export const mapLayersDefaultValues = {
  name_en: '',
  type_of_layer: '',
  geometry_type: '',
  // file_upload: [{ file: null, name: '' }],
  file_upload: [],
};

export const dropDownOptions: any = {
  type_of_layer: [
    {
      id: 1,
      label: 'Shapefile',
      value: 'Shapefile',
    },
    {
      id: 2,
      label: 'Geojson',
      value: 'Geojson',
    },
    {
      id: 3,
      label: 'CSV',
      value: 'CSV',
    },
    {
      id: 4,
      label: 'KML',
      value: 'KML',
    },
    {
      id: 5,
      label: 'OSM',
      value: 'OSM',
    },
    {
      id: 6,
      label: 'WMS',
      value: 'WMS',
    },
  ],
  geometry_type: [
    {
      id: 1,
      label: 'Polygon',
      value: 'Polygon',
    },
    {
      id: 2,
      label: 'LineString',
      value: 'LineString',
    },
    {
      id: 3,
      label: 'Point',
      value: 'Point',
    },
  ],
};
