import { z } from 'zod';

export const KnowledgeRepositoryValidationSchema = z.object({
  title: z.string().trim().min(1, 'Title is Required'),
  thematic_field: z.array(z.number(), {
    message: 'Thematic field is Required',
  }),
  file_type: z.string().trim().min(1, 'File type is Required'),
  // associate_program: z.array(z.number(), {
  //   message: 'Associate programme is Required',
  // }),
  // associate_project: z.array(z.number(), {
  //   message: 'Associate project is Required',
  // }),
  // release_type: z.string().trim().min(1, 'Type of release is Required'),
  published_date: z.string().trim().min(1, 'Published date is Required'),
  // summary: z.string().trim().min(1, 'Published date is Required'),
  // tags: z.string().trim().min(1, 'Tags is Required'),
  documents: z.any().array().min(1, 'Document is Required'),
});

export const KnowledgeRepositoryConceptualValidationSchema = z.object({
  title: z.string().trim().min(1, 'Title is Required'),
  thematic_field: z.array(z.number(), {
    message: 'Thematic field is Required',
  }),
  file_type: z.string().trim().min(1, 'File type is Required'),
  // associate_program: z.array(z.number(), {
  //   message: 'Associate programme is Required',
  // }),
  // associate_project: z.array(z.number(), {
  //   message: 'Associate project is Required',
  // }),
  province: z.number({ message: 'Province is Required' }),
  // release_type: z.string().trim().min(1, 'Type of release is Required'),
  published_date: z.string().trim().min(1, 'Published date is Required'),
  // summary: z.string().trim().min(1, 'Published date is Required'),
  // tags: z.string().trim().min(1, 'Tags is Required'),
  documents: z.any().array().min(1, 'Document is Required'),
});

export type KnowledgeRepositorySchemaProps = z.infer<
  typeof KnowledgeRepositoryValidationSchema & {
    [key: string]: any;
  }
>;
