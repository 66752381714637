/* eslint-disable no-unused-vars */
import { Button } from '@Components/RadixComponents/Button';
import { FormControl, Input } from '@Components/common/FormUI';
import InputLabel from '@Components/common/InputLabel';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { useMutation } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import PortalTemplate from '@Components/common/Layouts/Portal';
import React, { useState } from 'react';
import Icon from '@Components/common/Icon';
import DeleteConfirmationOverlay from '@Components/common/PopupOverlays/DeleteConfirmationOverlay';
import { deleteStakeholderData } from '@Services/portfolioReporting';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import IconButton from '@Components/common/IconButton';
import { useTypedSelector } from '@Store/hooks';

const newOption = {
  id: `new-${uuidv4()}`,
  unit_ministry: '',
  unit_ministry_department: '',
  local_government_stakeholder: '',
};

function getInitialValues(
  formData: Record<string, any>,
  locationId: number,
  type: string,
  key: string,
) {
  const stakeholdersArray = formData?.stakeholder?.[key]?.find(
    (dp: Record<string, any>) => dp.id === locationId,
  )?.stakeholder;
  const selectedTypeIntitialValue = stakeholdersArray?.find(
    (stakeholder: Record<string, any>) => stakeholder?.type === type,
  )?.data;
  return selectedTypeIntitialValue;
}

function getModifiedStakeholderData(
  formValues: Record<string, any>,
  locationId: number,
  updatedValues: Record<string, any>[],
  key: string,
  formType: string,
) {
  const stakeholderData = formValues?.stakeholder?.[key]?.find(
    (location: Record<string, any>) => location.id === locationId,
  );
  const stakeholderTypeData = stakeholderData?.stakeholder?.map(
    (typeData: Record<string, any>) =>
      typeData.type === formType
        ? { ...typeData, data: updatedValues }
        : typeData,
  );
  const newLocationData = formValues?.stakeholder?.[key]?.map(
    (dps: Record<string, any>) => {
      if (dps.id === locationId)
        return { ...dps, stakeholder: stakeholderTypeData };
      return dps;
    },
  );
  return newLocationData;
}

const CommonStakeholder = ({
  locationId,
  dataKey,
  formType,
}: {
  locationId: number;
  dataKey: string;
  formType: string;
}) => {
  const {
    // formState: { errors },
    // register,
    setValue,
    watch,
  } = useFormContext();

  const { stakeholderValidation, stakeholderValidationSubNational } =
    useTypedSelector(state => state.componentFormValidationSlice);

  const formValues = watch();
  const initialDevelopmentPartners = getInitialValues(
    formValues,
    locationId,
    formType,
    dataKey,
  ) || [newOption];
  const [developmentPartners, setDevelopmentPartners] = useState(
    initialDevelopmentPartners,
  );
  const [selectedDP, setSelectedDP] = useState<Record<string, any> | null>(
    null,
  );
  const [confirmDelete, setConfirmDelete] = useState(false);

  const {
    mutateAsync: deleteDP,
    isError,
    error,
    isLoading: isDeleting,
  } = useMutation({
    mutationFn: (id: number) => deleteStakeholderData(id),
    onError: () => {
      toast.error('Error Occured! Try again');
    },
  });

  const handleAddDevelopmentPartner = () => {
    const newPartners = [
      ...developmentPartners,
      {
        id: `new-${uuidv4()}`,
        unit_ministry: '',
        unit_ministry_department: '',
        local_government_stakeholder: '',
      },
    ];
    setDevelopmentPartners(newPartners);
    const newLocationData = getModifiedStakeholderData(
      formValues,
      locationId,
      newPartners,
      dataKey,
      formType,
    );
    setValue('stakeholder', {
      ...formValues?.stakeholder,
      [dataKey]: newLocationData,
    });
  };

  const handleChange = (
    selectedValue: Record<string, any> | string,
    selectedId: number,
    key: string,
  ) => {
    const newDevelopmentPartner = developmentPartners.map(
      (partner: Record<string, any>) => {
        return partner.id === selectedId
          ? { ...partner, [key]: selectedValue, hasChanged: true }
          : partner;
      },
    );
    setDevelopmentPartners(newDevelopmentPartner);
    const newLocationData = getModifiedStakeholderData(
      formValues,
      locationId,
      newDevelopmentPartner,
      dataKey,
      formType,
    );
    setValue('stakeholder', {
      ...formValues?.stakeholder,
      [dataKey]: newLocationData,
    });
  };

  const handleDelete = async (deleteObject: Record<string, any>) => {
    if (String(deleteObject?.id).includes('new')) {
      const newDP = developmentPartners.filter(
        (partner: Record<string, any>) => partner.id !== deleteObject?.id,
      );
      setDevelopmentPartners(newDP);
      const newLocationData = getModifiedStakeholderData(
        formValues,
        locationId,
        newDP,
        dataKey,
        formType,
      );
      setValue('stakeholder', {
        ...formValues?.stakeholder,
        [dataKey]: newLocationData,
      });
      setConfirmDelete(false);
    } else {
      await deleteDP(deleteObject.id);
      const newDP = developmentPartners.filter(
        (partner: Record<string, any>) => partner.id !== deleteObject?.id,
      );
      setDevelopmentPartners(newDP);
      const newLocationData = getModifiedStakeholderData(
        formValues,
        locationId,
        newDP,
        dataKey,
        formType,
      );
      setValue('stakeholder', {
        ...formValues?.stakeholder,
        [dataKey]: newLocationData,
      });
      setConfirmDelete(false);
    }
  };

  return (
    <>
      {confirmDelete && (
        <PortalTemplate>
          <DeleteConfirmationOverlay
            onClose={() => {
              setConfirmDelete(false);
              setSelectedDP(null);
            }}
            onDelete={() => {
              if (selectedDP) {
                handleDelete(selectedDP);
              }
            }}
            isError={isError}
            isLoading={isDeleting}
            error={error}
          />
        </PortalTemplate>
      )}
      <div className="naxatw-space-y-3">
        {developmentPartners.map((partner: Record<string, any>) => {
          return (
            <FlexRow
              key={partner.id}
              className="naxatw-items-end naxatw-gap-x-2"
            >
              <FormControl className="naxatw-w-full naxatw-gap-[0.5rem]">
                <InputLabel label={`${formType} Stakeholder`} />
                <Input
                  type="text"
                  placeholder="Stakeholder Name"
                  value={
                    formType === 'Local Government Stakeholder'
                      ? partner.local_government_stakeholder
                      : partner.other_stakeholder
                  }
                  onChange={e => {
                    const stakeholderType =
                      formType === 'Local Government Stakeholder'
                        ? 'local_government_stakeholder'
                        : 'other_stakeholder';
                    handleChange(e.target.value, partner.id, stakeholderType);
                  }}
                />

                {/* <DropDown
                placeholder="Choose"
                options={ministryList || []}
                value={partner.unit_ministry}
                onChange={selectedValue =>
                  handleChange(selectedValue, partner.id, 'unit_ministry')
                }
                choose="value"
              /> */}
              </FormControl>

              {developmentPartners.length > 1 && (
                <IconButton
                  name="delete"
                  iconClassName="naxatw-text-[#CED5DF] naxatw-duration-200 hover:!naxatw-text-red-600 naxatw-cursor-pointer"
                  onClick={() => {
                    setSelectedDP(partner);
                    setConfirmDelete(true);
                  }}
                />
              )}
            </FlexRow>
          );
        })}
        <FlexColumn>
          <Button
            variant="secondary"
            onClick={e => {
              e.preventDefault();
              handleAddDevelopmentPartner();
            }}
            disabled={
              dataKey === 'locationData'
                ? stakeholderValidationSubNational.find(
                    item => Number(item.id) === locationId,
                  )?.[formType]
                : stakeholderValidation[formType]
            }
          >
            <Icon name="add" />
            Add More Stakeholders
          </Button>
        </FlexColumn>
      </div>
    </>
  );
};

export default CommonStakeholder;
