/* eslint-disable no-unused-vars */
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Text,
  Legend,
} from 'recharts';
import { convertToCurrencySystem } from '@Utils/index';
import { ChartFills } from '../constants';
import { IChartProps } from '../types';

const xAxisTickFormatter = (value: any) => {
  const limit = 15;
  if (value.length < limit) return value;
  return `${value.slice(0, limit)}...`;
};

const CustomizedLabel = (props: any) => {
  const { x, y, payload } = props;
  return (
    <Text
      x={x}
      y={y + 3}
      style={{
        fontSize: '12px',
        fill: 'rgb(71, 84, 103)',
        fontWeight: 500,
      }}
      color="#475467"
      textAnchor="middle"
      dominantBaseline="hanging"
    >
      {xAxisTickFormatter(payload?.value)}
    </Text>
  );
};

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip naxatw-relative naxatw-z-20 naxatw-rounded-xl naxatw-border-2 naxatw-bg-white naxatw-px-3 naxatw-py-2 naxatw-text-sm">
        <p className="label naxatw-font-bold">{payload[0].payload.fullName}</p>
        {payload?.map((dataPoint: any) => {
          if (dataPoint.dataKey !== 'name')
            return (
              <div
                key={dataPoint.dataKey}
                className="naxatw-flex naxatw-w-fit naxatw-items-center naxatw-justify-between naxatw-gap-5"
              >
                <div className="naxatw-flex naxatw-items-center naxatw-justify-center naxatw-gap-1">
                  <div
                    className="naxatw-h-3 naxatw-w-3 naxatw-rounded-sm"
                    style={{ backgroundColor: `${dataPoint?.fill}` }}
                  />
                  <span>Budget</span>
                </div>
                <p className="naxatw-pt-1 naxatw-font-semibold">
                  {convertToCurrencySystem(dataPoint?.value)}
                </p>
              </div>
            );
          return <></>;
        })}
      </div>
    );
  }

  return null;
};

export default function CustomBarChart({
  data,
  fills = ChartFills,
  scrollable = false,
  barSize = 22,
  yAxisLabel = '',
  width = '150%',
  layout = 'horizontal',
  height,
  needLegend = false,
  // height = '24rem',
}: IChartProps) {
  const dataObject = data.length > 0 ? data[0] : {};

  const { name, ...datax } = dataObject;
  const keys = Object.keys(datax);

  return (
    <div style={{ height: height || '24rem' }}>
      <ResponsiveContainer
        width={scrollable && width ? width : '100%'}
        height="100%"
      >
        <BarChart
          data={data}
          layout={layout}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid vertical={false} stroke="#EBEBEB" strokeWidth={0.5} />
          <XAxis
            dataKey="name"
            tickLine={false}
            tick={<CustomizedLabel />}
            interval={0}
            style={{
              strokeWidth: '0.5px',
              stroke: '#BDBDBD',
            }}
            // label={<CustomizedLabel />}
          />

          <YAxis
            yAxisId="left"
            style={{
              strokeWidth: '0.5px',
              fontSize: '12px',
              fill: 'rgb(71, 84, 103)',
              fontWeight: 500,
            }}
            stroke="#BDBDBD"
            tickLine={false}
            tickFormatter={(value: any) =>
              String(convertToCurrencySystem(value))
            }
            label={{
              value: yAxisLabel,
              angle: -90,
              position: 'insideLeft',
              style: {
                textAnchor: 'middle',
                fill: 'rgb(71, 84, 103)',
                fontSize: '12px',
                fontWeight: 500,
              },
            }}
          />
          {keys.length > 1 && needLegend && <Legend />}

          <Tooltip content={<CustomTooltip />} cursor={{ fill: '#f2f9ff' }} />
          {keys.map((key, i) => {
            if (key === 'fullName') return null;
            return (
              <Bar
                key={key}
                yAxisId="left"
                dataKey={key}
                fill={fills[i]}
                barSize={barSize}
                radius={[4, 4, 0, 0]}
              />
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
