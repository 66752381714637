/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable camelcase */
import { Button } from '@Components/RadixComponents/Button';
import dvsLogo from '@Assets/images/dvs-logo-new.svg';
import { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from '@tanstack/react-query';
import {
  generateReportList,
  generateReportListStakeholder,
} from '@Services/report';
import { convertToCurrencySystem } from '@Utils/index';
import html2pdf from 'html2pdf.js';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { IAdminPdfState } from '@Store/slices/adminPdfSlice';

import Icon from '../Icon';
import IconHeader from './IconHeaders';
import { FlexColumn, FlexRow } from '../Layouts';
import MarkerCardBox from './SectorCardBox';

interface IReportPdfPopUpProps {
  // display?: boolean;
  handleClose?: () => void;
  compoenentId?: string;
  checkList: IAdminPdfState;
}

const ReportPdfPopUp = ({
  // display = false,
  compoenentId,
  handleClose,
  checkList,
}: IReportPdfPopUpProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const {
    allocated_budget,
    sectors,
    markers,
    campaign_goals,
    working_areas,
    stakeholders,
  } = checkList;

  function transformStakeholders(data: any[]) {
    const result: any[] = [];

    data?.forEach(stakeholder => {
      stakeholder.stakeholders?.forEach((levelObj: any) => {
        Object?.keys(levelObj)?.forEach(level => {
          const content = levelObj[level]?.map((categoryObj: any) => {
            const category = Object?.keys(categoryObj)[0];
            const items = categoryObj[category];
            return { category, items };
          });

          result.push({ level, content });
        });
      });
    });

    return result;
  }
  const { data: reportListData, isLoading: reportDataIsLoading } = useQuery({
    queryKey: ['reportList', compoenentId],
    queryFn: async () => generateReportList({ project: compoenentId }),
    select: res => res.data[0],
  });
  const { data: stakeholderData, isLoading: stakeholderDataIsLoading } =
    useQuery({
      queryKey: ['stakeolderData', compoenentId],
      queryFn: async () =>
        generateReportListStakeholder({ project: compoenentId }),
      select: res => transformStakeholders(res.data),
    });

  const handleDownloadPdf = () => {
    const element = contentRef.current;
    if (element) {
      const opt = {
        margin: 0.25,
        filename: 'my-document.pdf',
        image: { type: 'jpeg', quality: 0.5 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
        pagebreak: { mode: ['avoid-all'] },
      };

      // Create PDF
      html2pdf().from(element).set(opt).save();
    }
  };
  const labels = [
    { id: 'program_code', title: 'Programme code' },
    { id: 'project_code', title: 'Project code' },
    // { id: 'project_code', title: 'Project code' },
    { id: 'start_date', title: 'Start date' },
    { id: 'end_date', title: 'End date' },
    { id: 'agreement', title: 'Agreement modality' },
  ];

  const budgetLabels = [
    { id: 'on_budget_on_treasury', title: 'On Budget On Treasury' },
    { id: 'on_budget_off_treasury', title: 'On Budget Off Treasury' },
    { id: 'off_budget_on_treasury', title: 'Off Budget On Treasury' },
    { id: 'off_budget_off_treasury', title: 'Off Budget Off Treasury' },
    { id: 'rdel_budget', title: 'RDEL Budget' },
    { id: 'cdel_budget', title: 'CDEL Budget' },
    { id: 'rdel_icf_budget', title: 'RDEL ICF Budget' },
    { id: 'cdel_icf_budget', title: 'CDEL ICF Budget' },
  ];

  return (
    <>
      <div className="naxatw-w-full naxatw-overflow-hidden naxatw-py-10">
        <div className="naxatw-relative naxatw-mx-auto naxatw-h-[93vh] naxatw-w-11/12 naxatw-overflow-hidden naxatw-rounded-2xl naxatw-border naxatw-bg-white naxatw-px-5 naxatw-shadow-sm lg:naxatw-w-[45%]">
          {reportDataIsLoading ? (
            <Skeleton className="naxatw-mt-4 naxatw-h-[93vh] naxatw-w-full" />
          ) : (
            <div
              ref={contentRef}
              className="no-scrollbar naxatw-flex naxatw-h-full naxatw-w-full naxatw-flex-col naxatw-gap-5 naxatw-overflow-y-auto"
            >
              <FlexColumn className="naxatw-w-full naxatw-gap-5 naxatw-pb-[6rem]">
                <div className="naxatw-py-2">
                  <img src={dvsLogo} alt="" />
                </div>
                <FlexColumn className="naxatw-gap-2">
                  <p className="naxatw-text-xl naxatw-font-medium naxatw-leading-normal naxatw-text-primary-700">
                    {reportListData?.program}
                    {/* Accelerating Investment and Infrastructure in Nepal (AiiN) */}
                  </p>
                  <p className="naxatw-text-[0.875rem] naxatw-font-bold naxatw-leading-normal naxatw-tracking-[-0.0175rem] naxatw-text-primary-700">
                    {reportListData?.component}
                    {/* Policy and Institutions Facility (PIF) */}
                  </p>
                </FlexColumn>
                <FlexRow className="naxatw-h-fit naxatw-items-center naxatw-gap-2">
                  <IconHeader name="handshake" title="First tier partner" />
                  <p className="naxatw-text-[0.75rem] naxatw-font-bold naxatw-text-gray-500">
                    :
                  </p>
                  <p className="naxatw-text-[0.75rem] naxatw-font-bold naxatw-text-gray-500">
                    {reportListData?.first_tier_partner}
                  </p>
                </FlexRow>
                <FlexColumn className="naxatw-col-span-7 naxatw-overflow-hidden naxatw-rounded-xl naxatw-border naxatw-shadow-sm">
                  {labels.map((data, index) => (
                    <FlexRow
                      className={`naxatw-items-center naxatw-gap-3 ${index % 2 !== 0 ? 'naxatw-bg-gray-100' : ''}  naxatw-px-2 naxatw-py-1`}
                      key={uuidv4()}
                    >
                      <p className="naxatw-min-w-[30%] naxatw-text-[0.75rem] naxatw-font-medium naxatw-text-gray-500">
                        {data.title}
                      </p>
                      <p>:</p>
                      <p className="naxatw-text-[0.75rem] naxatw-font-bold naxatw-text-matt-200">
                        {reportListData[data.id] || 'N/A'}
                      </p>
                    </FlexRow>
                  ))}
                </FlexColumn>

                <FlexColumn className="naxatw-gap-8">
                  {allocated_budget && (
                    <FlexColumn className="naxatw-gap-3">
                      <FlexRow className="naxatw-items-center naxatw-justify-between">
                        <IconHeader
                          name="currency_pound"
                          title="Allocated budget"
                        />
                        <p className="naxatw-text-[0.75rem] naxatw-font-bold naxatw-leading-normal naxatw-tracking-[-0.015rem] naxatw-text-primary-700">
                          ( Total : &#163;&nbsp;
                          {convertToCurrencySystem(
                            reportListData?.allocated_budget,
                          )}{' '}
                          )
                        </p>
                      </FlexRow>
                      <div className="naxatw-grid naxatw-grid-cols-2 naxatw-gap-x-8 naxatw-gap-y-2">
                        {budgetLabels.map(data => {
                          if (!reportListData[data.id]) return null;
                          return (
                            <FlexRow
                              className="naxatw-gap-1 naxatw-border-b naxatw-border-neutral-200 naxatw-bg-white naxatw-p-2"
                              key={data.id}
                            >
                              <p className="naxatw-min-w-[80%] naxatw-text-[0.75rem] naxatw-font-medium naxatw-text-gray-500">
                                {data.title}
                              </p>
                              <p className="naxatw-text-[0.75rem] naxatw-font-bold naxatw-text-matt-200">
                                &#163; {reportListData[data.id]}
                              </p>
                            </FlexRow>
                          );
                        })}
                      </div>
                    </FlexColumn>
                  )}
                  {sectors && (
                    <FlexColumn className="naxatw-w-full naxatw-gap-3">
                      <IconHeader name="category" title="Sector" />
                      <FlexColumn className="naxatw-w-full naxatw-gap-2">
                        {reportListData?.sector?.map((sector: any) => (
                          <FlexRow
                            className="naxatw-items-center naxatw-justify-between naxatw-border-b naxatw-border-neutral-200 naxatw-p-3"
                            key={sector.id}
                          >
                            <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                              {sector?.sector_name}
                            </p>
                          </FlexRow>
                        ))}
                      </FlexColumn>
                    </FlexColumn>
                  )}
                  {markers && (
                    <FlexColumn className="naxatw-w-full naxatw-gap-3">
                      <IconHeader name="donut_small" title="Markers" />
                      <FlexColumn className="naxatw-w-full naxatw-gap-2">
                        {reportListData?.marker_category.map((marker: any) => (
                          <MarkerCardBox
                            marker={marker?.marker}
                            sectorGroupName={marker?.marker_category_name}
                            key={uuidv4()}
                            className="naxatw-min-w-[30%]"
                          />
                        ))}
                      </FlexColumn>
                    </FlexColumn>
                  )}
                  {campaign_goals && (
                    <FlexColumn className="naxatw-w-full naxatw-gap-3">
                      <IconHeader name="flag_circle" title="Campaign Goals" />
                      <div className="naxatw-grid naxatw-w-full naxatw-grid-cols-2 naxatw-gap-2">
                        {reportListData?.campaign_goal?.map((goal: any) => (
                          <div
                            className="naxatw-w-full naxatw-min-w-[12rem] naxatw-p-5 "
                            key={uuidv4()}
                          >
                            <div className="naxatw-flex naxatw-flex-col naxatw-gap-[0.62rem]">
                              <p className="naxatw-text-[1.5rem] naxatw-font-medium naxatw-text-primary-700">
                                {goal.split(':')[0]}
                              </p>
                              <p className="naxatw-whitespace-nowrap naxatw-text-[0.875rem] naxatw-text-matt-200 ">
                                {goal.split(':')[1]}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </FlexColumn>
                  )}
                  {working_areas && reportListData?.working_area.length > 0 && (
                    <FlexColumn className="naxatw-gap-2">
                      <IconHeader name="place" title="Working Areas" />
                      <FlexColumn className="naxatw-gap-2">
                        {reportListData?.working_area?.map((area: any) => (
                          <FlexRow
                            className={`naxatw-items-center naxatw-justify-between naxatw-rounded-xl naxatw-border naxatw-p-3 naxatw-shadow-sm ${area === null ? 'naxatw-hidden' : 'naxatw-block'}`}
                            key={uuidv4()}
                          >
                            <FlexRow>
                              <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                                {area?.province_name} &nbsp;
                              </p>
                              {area?.municipality_name && (
                                <>
                                  <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                                    {` / ${area?.district_name} /`} &nbsp;
                                  </p>
                                  <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                                    {area?.municipality_name}
                                  </p>
                                </>
                              )}
                            </FlexRow>
                            <FlexRow className="naxatw-gap-[0.38rem]">
                              {area?.end_date === null ? (
                                <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                                  Ongoing
                                </p>
                              ) : (
                                <>
                                  <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                                    {area?.start_date}
                                  </p>
                                  <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                                    to
                                  </p>
                                  <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                                    {area?.end_date}
                                  </p>
                                </>
                              )}
                            </FlexRow>
                          </FlexRow>
                        ))}
                      </FlexColumn>
                    </FlexColumn>
                  )}
                  {stakeholders && (stakeholderData as any)?.length > 0 && (
                    <FlexColumn className="naxatw-gap-3">
                      <IconHeader name="groups" title="Stakeholders" />
                      <FlexColumn className="naxatw-gap-3">
                        {stakeholderData?.map((stakeholder: any) => (
                          <FlexColumn className="naxatw-gap-2">
                            <p className="naxatw-text-[0.625rem] naxatw-font-medium naxatw-uppercase naxatw-tracking-[0.03125rem] naxatw-text-primary-700">
                              {stakeholder?.level}
                            </p>
                            <FlexColumn className="naxatw-w-full naxatw-gap-1 naxatw-rounded-lg naxatw-border naxatw-bg-white naxatw-p-3 naxatw-shadow-sm">
                              {stakeholder?.content.map(
                                (level: any, index: number) => {
                                  const isLastIndex =
                                    index === stakeholder.content.length - 1;
                                  return (
                                    <>
                                      <FlexColumn className="naxatw-gap-1">
                                        <p className="naxatw-text-[0.75rem] naxatw-font-bold naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                                          {level?.category}
                                        </p>
                                        <FlexRow className="naxatw-gap-[0.62rem]">
                                          {level?.items?.map(
                                            (
                                              subStakeholders: any,
                                              subIndex: number,
                                            ) => {
                                              return (
                                                <FlexRow className="naxatw-gap-[0.62rem]">
                                                  {subStakeholders.province && (
                                                    <>
                                                      <p className="naxatw-text-[0.75rem] naxatw-font-semibold naxatw-text-primary-700">
                                                        {
                                                          subStakeholders.province
                                                        }
                                                      </p>
                                                      <div className="naxatw-h-[1rem] naxatw-w-[1px] naxatw-bg-secondary-300" />
                                                    </>
                                                  )}
                                                  {subStakeholders?.names?.map(
                                                    (
                                                      items: any,
                                                      itemsIndex: number,
                                                    ) => {
                                                      const isLastSubIndex =
                                                        itemsIndex ===
                                                        subStakeholders.names
                                                          .length -
                                                          1;
                                                      return (
                                                        <FlexRow className="naxatw-gap-[0.62rem]">
                                                          <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-text-matt-200">
                                                            {items}
                                                          </p>
                                                          <div
                                                            className={` ${isLastSubIndex ? 'naxatw-hidden' : 'naxatw-block'} naxatw-h-[1rem] naxatw-w-[1px] naxatw-bg-secondary-300 `}
                                                          />
                                                        </FlexRow>
                                                      );
                                                    },
                                                  )}
                                                </FlexRow>
                                              );
                                            },
                                          )}
                                        </FlexRow>
                                      </FlexColumn>
                                      <div
                                        className={` ${isLastIndex ? 'naxatw-hidden' : 'naxatw-block'} naxatw-h-[1px] naxatw-w-full naxatw-bg-neutral-200`}
                                      />
                                    </>
                                  );
                                },
                              )}
                            </FlexColumn>
                          </FlexColumn>
                        ))}
                      </FlexColumn>
                    </FlexColumn>
                  )}
                </FlexColumn>
              </FlexColumn>
            </div>
          )}
          <div className="naxatw-absolute naxatw-bottom-0 naxatw-left-0 naxatw-flex naxatw-h-[5rem] naxatw-w-full naxatw-items-center naxatw-justify-center naxatw-gap-4 naxatw-bg-white naxatw-shadow-[0px_2px_20px_4px_rgba(0,0,0,0.12)]">
            <Button
              size="normal"
              type="button"
              variant="secondary"
              className="naxatw-w-fit naxatw-px-4"
              onClick={handleDownloadPdf}
            >
              <Icon
                name="download"
                className="naxatw-flex naxatw-items-center naxatw-text-[1.5rem]"
              />
              <p>Download Report</p>
            </Button>
            <Button
              variant="link"
              className="naxatw-text-[0.875rem] naxatw-font-semibold naxatw-text-secondary-500"
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportPdfPopUp;
