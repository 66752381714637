import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Tab = {
  selectedTab: Record<string, any>;
};
const initialState: Tab = {
  selectedTab: {
    sectors: 'Project',
    stakeHolders: 'Stakeholders Type',
    govermentStakeHolders: 'Project',
  },
};
export const setMainDashboardChartTabs = createSlice({
  name: 'provincialProfileSlice',
  initialState,
  reducers: {
    setSectorsSelectedTab: (state, action: PayloadAction<string>) => {
      // eslint-disable-next-line no-param-reassign
      state.selectedTab.sectors = action.payload;
    },

    setStakeHoldersSelectedTab: (state, action: PayloadAction<string>) => {
      // eslint-disable-next-line no-param-reassign
      state.selectedTab.stakeHolders = action.payload;
    },

    setGovernmentStakeHoldersSelectedTab: (
      state,
      action: PayloadAction<string>,
    ) => {
      // eslint-disable-next-line no-param-reassign
      state.selectedTab.govermentStakeHolders = action.payload;
    },
  },
});

export default setMainDashboardChartTabs.reducer;
