import SwitchTab from '@Components/common/FormUI/SwitchTab';
import { setShowFilterColumn } from '@Store/actions/mainDashboard';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { dashboardTabOptions } from '@Constants/mainDashboard';
import Icon from '@Components/common/Icon';

const DashboardMainTab = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useTypedDispatch();

  const showFilterColumn = useTypedSelector(
    state => state.mainDashboard.showFilterColumn,
  );

  const filterCount = useTypedSelector(
    state => state?.mainDashboard?.filterCount,
  );

  return (
    <div className="tab-filter-container naxatw-flex naxatw-w-full naxatw-justify-between naxatw-gap-x-4 naxatw-whitespace-nowrap lg:naxatw-w-fit lg:naxatw-justify-normal">
      <SwitchTab
        title="VIEW AS"
        options={dashboardTabOptions || []}
        activeLabel={pathname?.split('/')?.[2] || ''}
        wrapperClassName="!naxatw-gap-[0.625rem] !naxatw-cursor-pointer"
        defaultBg={false}
        defaultBehaviour={false}
        className="naxatw-text-sm naxatw-leading-5 naxatw-text-[#484848]"
        titleClassName="naxatw-text-sm"
        onChange={(label: string) => {
          navigate(`/dashboard/${label}`);
        }}
      />
      <button
        className={`filter-btn naxatw-solid naxatw-relative naxatw-flex naxatw-items-center naxatw-gap-2 naxatw-rounded-xl naxatw-border  naxatw-p-2 naxatw-shadow-xs hover:naxatw-bg-grey-100 ${filterCount ? 'naxatw-border-[#833177] ' : 'naxatw-border-primary-200'}`}
        type="button"
        onClick={() =>
          dispatch(
            setShowFilterColumn({
              key: 'showFilterColumn',
              value: !showFilterColumn,
            }),
          )
        }
      >
        <Icon
          name={showFilterColumn ? 'visibility_off' : 'visibility'}
          className="naxatw-text-matt-200"
        />
        <p className="naxatw-matt-200 naxatw-text-xs  naxatw-font-medium">
          {showFilterColumn ? 'Hide Filter' : 'Show Filter'}
        </p>
        {filterCount >= 1 && (
          <div className="filter-count naxatw-absolute naxatw-right-[-8px] naxatw-top-[-12px] naxatw-flex naxatw-h-6 naxatw-w-6 naxatw-items-center naxatw-justify-center naxatw-rounded-[6.25rem]  naxatw-border  naxatw-border-solid naxatw-border-primary-200 naxatw-bg-[#833177] naxatw-p-[0.0625rem]">
            <span className="naxatw-f-full naxatw-w-full naxatw-text-xs naxatw-font-bold naxatw-leading-4 naxatw-text-white">
              {filterCount || 0}
            </span>
          </div>
        )}
      </button>
    </div>
  );
};

export default DashboardMainTab;
