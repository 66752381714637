/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { useMapLibreGLMap } from '@Components/common/MapLibreComponents';
import VectorTileLayer from '@Components/common/MapLibreComponents/Layers/VectorTileLayer';
import MapContainer from '@Components/common/MapLibreComponents/MapContainer';
import {
  defaultLayerStyle,
  fillByProvinceStyle,
  pointStyles,
  polygonStyles,
  roadStyles,
} from '@Constants/map';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import MapTools from '@Components/common/MapTools';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { getExpression } from '@Constants/mainDashboard';
import switchTile from '@Utils/switchVectorTile';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  contextualIndicatorByFederal,
  getOverlayPopupData,
} from '@Services/contextualIndicators';
import { setActiveViewBy } from '@Store/actions/exploreByIndicators';
import { Map } from 'maplibre-gl';
import AsyncPopup from '@Components/common/MapLibreComponents/AsyncPopup';
import MapAdditionalTools from '@Components/MainDashboard/MapSection/MapAdditionalTools';
import BaseLayerSwitcher from '@Components/common/MapLibreComponents/BaseLayerSwitcher';
import OverlayPopup from '@Components/MainDashboard/MapSection/MapAdditionalTools/OverlayPopup';
import { contextualIndicatorsOptions } from '@Constants/exploreByIndicators';
import OverlayLayerLegend from '@Components/common/MapLibreComponents/OverlayLayerLegend';
import NoDataAvailable from '@Components/MainDashboard/MapSection/Map/NodataAvailable';
import {
  handleOverlayLayerCheckbox,
  toggleOverlayPopup,
  setMainDashboardStates,
} from '@Store/actions/mainDashboard';
import { getOverlayLayerList } from '@Services/mainDashboard';
import Legend, { StaticLegendForProvince } from './Legend';

const BASEURL = process.env.API_URL_V1;

const MapSection = () => {
  const dispatch = useTypedDispatch();
  const queryClient = useQueryClient();
  const mapRef = useRef(null);
  const [showNoDataAvailable, setShowNoDataAvailable] = useState(false);
  const activeViewBy = useTypedSelector(
    state => state.exploreByIndicators.map.activeViewBy,
  );
  const selectedIndicator = useTypedSelector(
    state => state.exploreByIndicators.selectedIndicator,
  );
  const overlayLayerList = useTypedSelector(
    state => state.mainDashboard.overlayLayerList,
  );
  const [hoveredFeature, setHoveredFeature] = useState<Record<
    string,
    any
  > | null>(null);
  const [selectedFeature, setSelectedFeature] = useState<Record<
    string,
    any
  > | null>(null);
  const [selectedBaseLayer, setSelectedBaseLayer] = useState('mapbox-light');

  const [selectedOverlayLayerId, setSelectedOverlayLayerId] = useState<
    number | null
  >(null);
  const [selectedOverlayFeatureId, setSelectedOverlayFeatureId] = useState<
    number | null
  >(null);
  const [overlayPopupData, setOverlayerPopupData] = useState<
    Record<string, any>[]
  >([]);

  const { map, isMapLoaded } = useMapLibreGLMap({
    mapOptions: {
      center: [84.1258, 28.3974],
      zoom: 6,
      minZoom: 6,
      maxZoom: 19,
      maxBounds: [
        [73.916016, 23.785345],
        [94.669189, 32.879587],
      ],
      preserveDrawingBuffer: true,
    },
  });

  const { data: countMapping, isLoading } = useQuery({
    queryKey: [
      'contextual-indicator-by-federal',
      activeViewBy,
      selectedIndicator,
    ],
    queryFn: async () =>
      contextualIndicatorByFederal({
        indicator: selectedIndicator,
        by: activeViewBy,
      }),
    enabled: !!selectedIndicator,
    select: res => res.data,
  });

  // fetch layers and set them to state
  useQuery({
    queryKey: ['overlay-layers'],
    queryFn: getOverlayLayerList,
    select: res => res.data.data,
    onSuccess: res => {
      const modifiedResponse = res?.map((layer: Record<string, any>) => ({
        ...layer,
        checked: false,
      }));
      dispatch(setMainDashboardStates({ overlayLayerList: modifiedResponse }));
    },
    // enabled: overlayLayerList === null,
  });

  useQuery({
    queryKey: ['overlay-layer-popup', selectedOverlayLayerId],
    queryFn: () => getOverlayPopupData(selectedOverlayLayerId as number),
    select: res => res.data,
    onSuccess: res => {
      setOverlayerPopupData(res);
    },
    enabled: !!selectedOverlayLayerId,
  });

  // code block to change the fill and outline on hover
  useEffect(() => {
    if (!map || !countMapping || !isMapLoaded) return;
    const fillData = getExpression(
      countMapping,
      selectedFeature?.id,
      '',
      selectedIndicator || '',
      `${activeViewBy}_id`,
      true,
    );
    if (
      !map.getLayer(
        `vectorTile-${activeViewBy}-${selectedIndicator || 'no-indicators'}`,
      )
    )
      return;
    map.setPaintProperty(
      `vectorTile-${activeViewBy}-${selectedIndicator || 'no-indicators'}`,
      'fill-color',
      fillData,
    );

    // to set the outline to green of the map when hovered
    if (hoveredFeature?.isHovered) {
      map.setPaintProperty(
        `vectorTile-${activeViewBy}-${selectedIndicator || 'no-indicators'}-outline`,
        'line-color',
        ['match', ['get', 'id'], hoveredFeature?.id, '#DE0A26', '#808080'],
      );
      map.setPaintProperty(
        `vectorTile-${activeViewBy}-${selectedIndicator || 'no-indicators'}-outline`,
        'line-opacity',
        ['match', ['get', 'id'], hoveredFeature?.id, 1.5, 0.1],
      );
    } else {
      map.setPaintProperty(
        `vectorTile-${activeViewBy}-${selectedIndicator || 'no-indicators'}-outline`,
        'line-color',
        '#808080',
      );
    }
  }, [
    map,
    countMapping,
    activeViewBy,
    selectedIndicator,
    hoveredFeature,
    selectedFeature,
    isMapLoaded,
  ]);

  // set back to view by province cause there is no fill for municipality if indicator is not selected
  useEffect(() => {
    setShowNoDataAvailable(false);
    if (selectedIndicator) return;
    dispatch(setActiveViewBy('province'));
  }, [selectedIndicator, dispatch]);

  useEffect(
    () => () => {
      queryClient.invalidateQueries({
        queryKey: ['overlay-layers'],
      });
      dispatch(toggleOverlayPopup(false));
    },
    [dispatch, queryClient],
  );

  const getPopupUI = useCallback(() => {
    const selectedOverlay = overlayPopupData?.filter(
      (feature: any) => feature?.attr_data__id === selectedOverlayFeatureId,
    );
    if (selectedOverlay.length)
      return (
        <div className="naxatw-bg-white">
          <div className="-naxatw-mt-2 naxatw-flex naxatw-items-center naxatw-justify-start naxatw-gap-1">
            <div className="naxatw-h-3 naxatw-w-3 naxatw-rounded-full naxatw-bg-[#5DB8B2]" />
            <h6 className="naxatw-text-[16px] naxatw-font-bold naxatw-text-[#5DB8B2]">
              {selectedOverlay[0]?.attr_data__name || 'No Data Found'}
            </h6>
          </div>
        </div>
      );
    if (!selectedIndicator || !countMapping || !selectedFeature?.id)
      return <div className="naxatw-text-gray-800">No Data Found</div>;
    const data = countMapping?.find(
      (count: any) => count?.[`${activeViewBy}_id`] === selectedFeature?.id,
    );

    return (
      <div className="naxatw-bg-white">
        <div className="naxatw-flex naxatw-items-center naxatw-justify-start naxatw-gap-1">
          <div className="naxatw-h-3 naxatw-w-3 naxatw-rounded-full naxatw-bg-[#5DB8B2]" />

          <h6 className="naxatw-text-[16px] naxatw-font-bold naxatw-text-[#5DB8B2]">
            {selectedFeature?.name || 'No Data Found'}
          </h6>
        </div>
        <div className="naxatw-flex naxatw-flex-col naxatw-py-3 naxatw-text-gray-600">
          <p className="naxatw-capitalize">
            {selectedIndicator?.replace(/_/g, ' ')} :{' '}
            {data?.[selectedIndicator]}
          </p>
        </div>
      </div>
    );
  }, [
    overlayPopupData,
    selectedIndicator,
    countMapping,
    selectedFeature?.id,
    selectedFeature?.name,
    selectedOverlayFeatureId,
    activeViewBy,
  ]);

  const getIndicatorLabelByValue = (value: string) => {
    return contextualIndicatorsOptions?.find(
      indicator => indicator.value === value,
    )?.label;
  };

  const isOverlayLayerApplied = useMemo(
    () => overlayLayerList?.some(layer => layer.checked),
    [overlayLayerList],
  );

  // show data not available if data is empty
  useEffect(() => {
    if (
      !map ||
      !isMapLoaded ||
      isLoading ||
      !countMapping ||
      !selectedIndicator
    )
      return () => {};
    if (countMapping?.length === 0) {
      setShowNoDataAvailable(true);
    }

    return () => {};
  }, [map, isMapLoaded, isLoading, countMapping, selectedIndicator]);

  return (
    <>
      <div style={{ width: '100%', height: '100%' }} ref={mapRef}>
        <MapContainer
          style={{ width: '100%', height: '100%' }}
          map={map}
          isMapLoaded={isMapLoaded}
        >
          <MapAdditionalTools
            mapRef={mapRef}
            isFetching={selectedIndicator ? isLoading : false}
            sourceIdToLoad={
              selectedIndicator
                ? `vectorTile-${activeViewBy}-${selectedIndicator}`
                : `vectorTile-${activeViewBy}-no-indicators`
            }
          />
          <BaseLayerSwitcher
            activeLayer={selectedBaseLayer}
            isMapLoaded={isMapLoaded as boolean}
            onBaseLayerChange={layerId => {
              setSelectedBaseLayer(layerId);
            }}
          />

          {overlayLayerList &&
            overlayLayerList?.map(layer => (
              <VectorTileLayer
                key={layer.id}
                id={`vectorTile-${layer.layer__name_en}-overlay`}
                isMapLoaded={isMapLoaded}
                url={`${BASEURL}/feature_collection_tile/{z}/{x}/{y}.pbf?feature=${layer.id}`}
                layerOptions={
                  layer?.geometry_type === 'LineString'
                    ? {
                        ...roadStyles,
                        paint: {
                          ...roadStyles?.paint,
                          'line-color':
                            layer?.vector_layer_style__style_json?.[
                              'line-color'
                            ] || `#0664D2`,
                        },
                      }
                    : layer?.geometry_type === 'Point'
                      ? {
                          ...pointStyles,
                          paint: {
                            ...pointStyles?.paint,
                            'circle-color':
                              layer?.vector_layer_style__style_json?.[
                                'circle-color'
                              ] || `#0664D2`,
                          },
                        }
                      : layer?.geometry_type === 'Polygon'
                        ? {
                            ...polygonStyles,
                            paint: {
                              ...polygonStyles?.paint,
                              'fill-color':
                                layer?.vector_layer_style__style_json?.[
                                  'fill-color'
                                ] || `#0664D2`,
                            },
                          }
                        : { ...roadStyles }
                }
                visibleOnMap={layer.checked}
              />
            ))}

          <VectorTileLayer
            id={`vectorTile-${activeViewBy}-${selectedIndicator || 'no-indicators'}`}
            isMapLoaded={isMapLoaded}
            url={`${BASEURL}/${switchTile(activeViewBy)}/{z}/{x}/{y}.pbf`}
            layerOptions={
              selectedIndicator ? defaultLayerStyle : fillByProvinceStyle
            }
            onHover={feature => {
              setHoveredFeature(feature);
            }}
            interactions={['select']}
            showOutline
            showNamePopup
          />

          <Legend
            heading={getIndicatorLabelByValue(selectedIndicator || '')}
            data={countMapping}
            countKey={selectedIndicator || ''}
            className={`${selectedIndicator ? '' : 'naxatw-hidden'}`}
            decimalSupport
          />
          <StaticLegendForProvince
            className={`${selectedIndicator ? 'naxatw-hidden' : ''}`}
          />

          {isOverlayLayerApplied && <OverlayLayerLegend />}

          <AsyncPopup
            map={map as Map}
            isMapLoaded={isMapLoaded}
            popupUI={getPopupUI}
            showPopup={clicked => {
              if (clicked?.source?.includes('no-indicators')) return false;
              return true;
            }}
            fetchPopupData={(properties: Record<string, any>) => {
              setSelectedFeature(properties);
              if (properties?.layer?.includes('overlay')) {
                setSelectedOverlayLayerId(properties?.feature_id);
                setSelectedOverlayFeatureId(properties?.id);
              }
            }}
            hideButton
            onClose={() => {
              setSelectedFeature(null);
              setSelectedOverlayFeatureId(null);
              setSelectedOverlayLayerId(null);
              setOverlayerPopupData([]);
            }}
          />

          {showNoDataAvailable && (
            <NoDataAvailable
              hasCloseBtn
              onClose={() => setShowNoDataAvailable(false)}
              text={`No Data available for ${activeViewBy} level for this indicator`}
            />
          )}

          {/* --------------- map tools ---------------  */}
          <MapTools map={map} />
        </MapContainer>
      </div>
    </>
  );
};

export default hasErrorBoundary(MapSection);
