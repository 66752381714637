/* eslint-disable no-nested-ternary */
import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ProvinceIntitailState {
  selectedFeature: Record<string, any> | null;
  selectedCountOption: string;
  searchedText: string;
  activeBaseLayer: string;
}

const initialState: ProvinceIntitailState = {
  selectedFeature: null,
  selectedCountOption: 'programme',
  searchedText: '',
  activeBaseLayer: 'mapbox-light',
};

const setProvincialProfileStates: CaseReducer<
  ProvinceIntitailState,
  PayloadAction<Record<string, any> | null>
> = (state, action) => ({
  ...state,
  ...action.payload,
});

const setSelectedFeature: CaseReducer<
  ProvinceIntitailState,
  PayloadAction<Record<string, any> | null>
> = (state, action) => {
  const district = action?.payload || {};
  return {
    ...state,
    selectedFeature: state?.selectedFeature
      ? state.selectedFeature?.id === district?.id
        ? null
        : district
      : district,
  };
};

const setSelectedCountOption: CaseReducer<
  ProvinceIntitailState,
  PayloadAction<string>
> = (state, action) => {
  const option = action?.payload ?? 'programme';
  return {
    ...state,
    selectedCountOption: option,
  };
};

const setSearchedText: CaseReducer<
  ProvinceIntitailState,
  PayloadAction<string>
> = (state, action) => {
  const text = action?.payload ?? '';
  return {
    ...state,
    searchedText: text,
  };
};

const provincialProfileSlice = createSlice({
  name: 'provincialProfileSlice',
  initialState,
  reducers: {
    setProvincialProfileStates,
    setSelectedFeature,
    setSelectedCountOption,
    setSearchedText,
  },
});

export { provincialProfileSlice };

export default provincialProfileSlice.reducer;
