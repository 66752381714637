/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export interface IAdminPdfState {
  allocated_budget: boolean;
  sectors: boolean;
  markers: boolean;
  campaign_goals: boolean;
  working_areas: boolean;
  stakeholders: boolean;
}

export interface IProvincialProfilePdfState {
  provincial_priorities: boolean;
  sectors: boolean;
  programme_details: boolean;
  partner_budget: boolean;
  working_palikas: boolean;
  stakeholders: boolean;
}
export interface IProgrammeDetailsPdfState {
  project_details: boolean;
  sectors: boolean;
  markers: boolean;
  campaign_goals: boolean;
  working_areas: boolean;
  stakeholders: boolean;
}

interface IInitialState {
  report: IAdminPdfState;
  provincialProfile: IProvincialProfilePdfState;
  programmeDetails: IProgrammeDetailsPdfState;
}
const initialState: IInitialState = {
  report: {
    allocated_budget: true,
    sectors: true,
    markers: true,
    campaign_goals: true,
    working_areas: true,
    stakeholders: true,
  },
  provincialProfile: {
    partner_budget: true,
    sectors: true,
    programme_details: true,
    provincial_priorities: true,
    working_palikas: true,
    stakeholders: true,
  },
  programmeDetails: {
    project_details: true,
    sectors: true,
    markers: true,
    campaign_goals: true,
    working_areas: true,
    stakeholders: true,
  },
};

const adminPdfSlice = createSlice({
  name: 'adminPdf',
  initialState,
  reducers: {
    setCheckBox: (state, action) => {
      const { key, value } = action.payload;
      state.report[key as keyof IAdminPdfState] = value;
    },
    resetCheckBox: state => {
      state.report = {
        allocated_budget: true,
        sectors: true,
        markers: true,
        campaign_goals: true,
        working_areas: true,
        stakeholders: true,
      };
    },
    setCheckBoxProvincialProfile: (state, action) => {
      const { key, value } = action.payload;
      state.provincialProfile[key as keyof IProvincialProfilePdfState] = value;
    },
    resetCheckBoxProvincialProfile: state => {
      state.provincialProfile = {
        partner_budget: true,
        sectors: true,
        programme_details: true,
        provincial_priorities: true,
        working_palikas: true,
        stakeholders: true,
      };
    },
    setCheckBoxProgrammeDetails: (state, action) => {
      const { key, value } = action.payload;
      state.programmeDetails[key as keyof IProgrammeDetailsPdfState] = value;
    },
    resetCheckBoxProgrammeDetails: state => {
      state.programmeDetails = {
        project_details: true,
        sectors: true,
        markers: true,
        campaign_goals: true,
        working_areas: true,
        stakeholders: true,
      };
    },
  },
});

export { adminPdfSlice };
export default adminPdfSlice.reducer;
