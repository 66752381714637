import { api, authenticated, fastApi } from '.';

export const getBbox = (params: Record<string, any>) =>
  api.get('/federal-extent/', { params });

export const getFederalWiseCount = (params: Record<string, any>) =>
  authenticated(fastApi).get('/infographics/federal_wise_count/', { params });

export const getProvincialCountData = (params: Record<string, any>) =>
  authenticated(fastApi).get('/dashboard/overall_count/', { params });

export const getProgrammeDetails = (params: Record<string, any>) =>
  authenticated(fastApi).get('/dashboard/program-details-by-province/', {
    params,
  });

export const getProgrammeDetailsByProvince = (params: Record<string, any>) =>
  authenticated(fastApi).get('/dashboard/program-details-by-province/', {
    params,
  });

export const getProgrammeStakeholderSankeyData = (
  params: Record<string, any>,
) =>
  authenticated(fastApi).get('/infographics/program-stakeholder-sankey/', {
    params,
  });

export const getSectorDpSankeyData = (params: Record<string, any>) =>
  authenticated(fastApi).get('/infographics/sector-dp-sankey/', { params });

export const getProvincialPrioritiesHeaderData = (
  params: Record<string, any>,
) => api.get('program-by-province/', { params });

export const getProvincialPrioritiesGoalsData = (params: Record<string, any>) =>
  api.get('provincial-priorities/', { params });

export const getIndicatorsByProgrammeData = (params: Record<string, any>) =>
  authenticated(fastApi).get('/infographics/indicator-by-program/', { params });

export const getPartnersByBudgetUsingProvince = (params: Record<string, any>) =>
  authenticated(fastApi).get('/dashboard/top-partner-budget/', { params });

export const getSectorsByBudgetUsingProvince = (params: Record<string, any>) =>
  authenticated(fastApi).get('/infographics/top-sector-by-budget/', { params });

export const getProgrammeDataByProgrammeId = (params: Record<string, any>) =>
  authenticated(fastApi).get('/infographics/program-detail-page/', { params });

export const getProgrammeProgressDataByProgrammeId = (
  params: Record<string, any>,
) => authenticated(api).get('/program-progress/', { params });

export const getProgrammeProgressDataByComponentId = (
  params: Record<string, any>,
) => authenticated(api).get('/project-progress/', { params });

export const getSectorWiseCountData = (params: Record<string, any>) =>
  authenticated(fastApi).get('/infographics/project-wise-sector/', {
    params,
  });

export const getComponentDetailCount = (params: Record<string, any>) =>
  authenticated(fastApi).get('/infographics/project-detail-count/', {
    params,
  });

export const getCampaignGoals = (params: Record<string, any>) =>
  authenticated(api).get('/get-campaign-goals-list', { params });

export const getProvincialProfileReport = (params: Record<string, any>) =>
  authenticated(fastApi).get('/core/generate-province-report/', { params });

export const downloadTableData = (params: Record<string, any>) => {
  return authenticated(fastApi).get('/dashboard/program-details-by-province/', {
    params,
    responseType: 'blob',
  });
};
