/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  ColumnSort,
  ColumnDef,
} from '@tanstack/react-table';
import prepareQueryParam from '@Utils/prepareQueryParam';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@Components/RadixComponents/Table';
import Icon from '@Components/common/Icon';
import useIntersectionObserver from '@Hooks/useIntersectionObserver';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { toast } from 'react-toastify';
import { useLocation, useSearchParams } from 'react-router-dom';
import { excludeVerticalTopPathNames } from '@Constants/index';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import TableSkeleton from '@Components/common/DataTable/TableSkeleton';
import ReportFilterPopOver from '../ReportFilterPopOver/ReportFilterPopOver';

interface ColumnData {
  [x: string]: any;
  component_budget: any;
  component_name: any;
  component_id: any;
  component?: any;
  header: string;
  accessorKey: string;
  cell?: any;
}

interface DataTableProps {
  columns: ColumnDef<ColumnData>[];
  queryKey: string;
  queryFn: (params: any) => Promise<any> | void;
  queryFnParams?: Record<string, any> | any;
  searchInput: string;
  className?: string;
  demoData?: any;
  onRowClick?: any;
  isPaginated?: boolean;
  needSorting?: boolean;
  cellClassName?: string;
  tableStyle?: any;
}

const excludeFilterParams = ['pdfIcon'];

export default function ReportDataTable({
  columns,
  queryKey,
  queryFn,
  searchInput,
  queryFnParams, // data,
  className,
  demoData,
  onRowClick,
  isPaginated = true,
  needSorting = true, // sorting, by defalut sorting is available, if not needed pass false
  cellClassName,
  tableStyle,
}: DataTableProps) {
  const [sorting, setSorting] = useState<ColumnSort[]>([
    { id: 'id', desc: false },
  ]);
  const defaultData = React.useMemo(() => [], []);
  const [isIntersecting, _, viewRef] = useIntersectionObserver();
  const { pathname } = useLocation();
  const [searchFilterParams, setSearchFilterParams] = useSearchParams();
  const [filterParams, setFilterParams] = useSearchParams();
  const isFilterActive = filterParams.get('filter') === 'true';

  const { data, isLoading, fetchNextPage, isError, error, hasNextPage } =
    useInfiniteQuery({
      queryKey: [queryKey, searchInput, queryFnParams],
      queryFn: async ({ pageParam = 1 }) => {
        const res = await queryFn({
          page: pageParam,
          search: searchInput,
          page_size: 15,
          ...(queryFnParams ? prepareQueryParam(queryFnParams) : {}),
        });
        return res?.data;
      },
      getNextPageParam: lastPage => {
        return lastPage?.next_page ?? undefined;
      },
    });

  const table = useReactTable({
    data: data
      ? isPaginated
        ? data?.pages?.reduce(
            (acc: Record<string, any>[], page: Record<string, any>) => {
              return [...acc, ...(page.results || page)];
            },
            [],
          )
        : (data?.pages[0] ?? demoData ?? defaultData)
      : [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      globalFilter: searchInput,
    },
    onSortingChange: setSorting,
    manualPagination: true,
    debugTable: true,
  });

  useEffect(() => {
    if (isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  }, [isIntersecting, fetchNextPage, hasNextPage]);

  if (isError) {
    // @ts-ignore
    const caughtError = error?.response?.data?.detail;
    toast.error(caughtError || (error as Error).message);
  }
  if (isLoading) {
    return <TableSkeleton />;
  }

  return (
    <FlexColumn className="naxatw-h-full naxatw-gap-2">
      <Table className={`naxatw-w-full ${className}`} style={tableStyle}>
        <TableHeader className="!naxatw-h-[3rem]">
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id} className="table-head-row">
              {headerGroup.headers.map(header => {
                return (
                  <TableHead
                    key={header.id}
                    onClick={() =>
                      isFilterActive
                        ? header.column.getToggleSortingHandler()
                        : null
                    }
                    className="!naxatw-bg-grey-100"
                  >
                    {!header.isPlaceholder && (
                      <FlexRow
                        className={`table-head-child naxatw-group naxatw-cursor-pointer naxatw-items-center naxatw-gap-3 naxatw-px-3 naxatw-py-0  hover:naxatw-text-secondary-500 xl:naxatw-px-6  ${
                          header.column.getIsSorted() !== false
                            ? 'naxatw-text-secondary-500'
                            : 'naxatw-text-matt-100'
                        }`}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}

                        {isFilterActive &&
                        !excludeFilterParams.includes(header.id) ? (
                          <ReportFilterPopOver
                            onClick={() => {
                              searchFilterParams.set(
                                'filtercategory',
                                header?.id || '',
                              );
                              setSearchFilterParams(searchFilterParams);
                            }}
                          />
                        ) : (
                          <>
                            {/* @ts-ignore */}
                            {header.column.columnDef.accessorKey.startsWith(
                              'icon',
                            ) ||
                            header.column.columnDef.header === '' ? null : (
                              <div
                                className={`naxatw-flex naxatw-flex-col naxatw-items-center naxatw-justify-center naxatw-gap-1 ${header.id === 'pdfIcon' ? 'naxatw-hidden' : 'naxatw-block'} ${needSorting ? 'naxatw-block' : 'naxatw-hidden'}`}
                              >
                                {(header.column.getIsSorted() === 'asc' ||
                                  header.column.getIsSorted() === false) && (
                                  <Icon
                                    name="expand_less"
                                    className={`!naxatw-flex !naxatw-h-[6px] !naxatw-items-center !naxatw-justify-start !naxatw-text-base group-hover:naxatw-text-secondary-500 ${
                                      header.column.getIsSorted() !== false
                                        ? 'naxatw-text-secondary-500'
                                        : 'naxatw-text-matt-100'
                                    }`}
                                  />
                                )}
                                {(header.column.getIsSorted() === 'desc' ||
                                  header.column.getIsSorted() === false) && (
                                  <Icon
                                    name="expand_more"
                                    className={`!naxatw-text-icon-sm !naxatw-flex !naxatw-h-[6px] !naxatw-items-center !naxatw-justify-start !naxatw-text-base group-hover:naxatw-text-secondary-500 ${
                                      header.column.getIsSorted() !== false
                                        ? 'naxatw-text-secondary-500'
                                        : 'naxatw-text-matt-100'
                                    }`}
                                  />
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </FlexRow>
                    )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>

        <TableBody>
          {table.getRowModel()?.rows?.length ? (
            table.getRowModel()?.rows.map(row => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
                onClick={() => onRowClick(row) || null}
                className="table-body-row naxatw-w-full naxatw-cursor-pointer"
              >
                {row.getVisibleCells().map(cell => {
                  if (cell.column.id === 'stakeholder') {
                    const stakeholders = cell.getValue() as any[];
                    return (
                      <TableCell
                        key={cell.id}
                        className="table-body-row-child naxatw-align-top"
                      >
                        {/* Create columns for each stakeholder */}
                        <FlexColumn className="naxatw-gap-4">
                          {stakeholders?.map((item: any, index: number) => (
                            <FlexColumn key={index} className="naxatw-gap-2">
                              <p className="naxatw-text-nowrap naxatw-text-sm naxatw-font-bold">
                                {item?.stakeholder_type}
                              </p>
                              {item?.stakeholders?.map(
                                (stakeholder: any, idx: number) => (
                                  <p
                                    className="text-wrap-ellipsis naxatw-text-sm naxatw-font-normal naxatw-leading-[1rem]"
                                    key={idx}
                                  >
                                    {stakeholder}
                                  </p>
                                ),
                              )}
                            </FlexColumn>
                          ))}
                        </FlexColumn>
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell
                      key={cell.id}
                      className={`${cellClassName} ${cell.column.id === 'blankId' ? '!naxatw-w-4' : ''} table-body-row-child ${excludeVerticalTopPathNames?.some(path => pathname.includes(path)) ? '' : 'naxatw-align-top'}`}
                      // @ts-ignore
                      columnId={cell.column.id}
                    >
                      {/* @ts-ignore */}
                      {cell.getValue() !== null ? (
                        Array.isArray(cell.getValue()) ? (
                          (cell.getValue() as any[]).length > 0 ? (
                            <ul className="naxatw-flex naxatw-flex-col naxatw-items-start naxatw-justify-start naxatw-gap-2">
                              {(cell.getValue() as any[]).map(
                                (item: any, index: number) => (
                                  <li
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={index}
                                    className={`naxatw-flex naxatw-items-center naxatw-gap-1
                                      naxatw-leading-[1.2rem] ${
                                        // @ts-ignore
                                        cell.column.columnDef.accessorKey ===
                                        'sector'
                                          ? 'text-wrap-ellipsis'
                                          : ''
                                      }`}
                                  >
                                    <p>-</p>
                                    <p className="text-wrap-ellipsis">{item}</p>
                                  </li>
                                ),
                              )}
                            </ul>
                          ) : (
                            <div className="!naxatw-min-w-[8rem] naxatw-text-center naxatw-font-bold">
                              <div className="naxatw-mx-auto naxatw-h-[1px] naxatw-w-[0.625rem] naxatw-bg-black" />
                            </div>
                          )
                        ) : (
                          <div
                            className={`naxatw-flex  naxatw-bg-center ${cell.column.id === 'icon' || cell.column.id === 'icons' ? 'naxatw-justify-end' : 'naxatw-justify-center'}`}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </div>
                        )
                      ) : (
                        '-'
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                className="naxatw-text-center"
              >
                No Data found.
              </TableCell>
            </TableRow>
          )}
          {isPaginated &&
            hasNextPage &&
            Array.from({ length: 3 }).map((__, rowindex: number) => (
              <TableRow
                className="flex"
                ref={viewRef}
                key={`${rowindex.toString()}_loaderrows`}
              >
                {Array.from({ length: columns?.length || 7 }).map(
                  (___, index: number) => (
                    <TableCell
                      key={`${index.toString()}_loadercolumn`}
                      className="last:!naxatw-pr-6 last:naxatw-text-right"
                    >
                      <Skeleton className=" naxatw-h-5 naxatw-w-14" />
                    </TableCell>
                  ),
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </FlexColumn>
  );
}
