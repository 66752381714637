/* eslint-disable no-underscore-dangle */
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import {
  mapGovernmentStakeHolderColors,
  mapGovernmentStakeHoldersTitle,
} from '@Constants/mainDashboard';
import { IChartProps } from '../types';

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip naxatw-relative naxatw-z-20 naxatw-rounded-xl naxatw-border-2 naxatw-bg-white naxatw-px-3 naxatw-py-2 naxatw-text-sm">
        {payload.map((item: Record<string, any>) => {
          return (
            <div key={item.name} className="naxatw-flex naxatw-items-center">
              <p className="naxatw-text-xs naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-grey-800">
                {mapGovernmentStakeHoldersTitle[item.name] || '-'}
              </p>
              <span className="colon-separator naxatw-mx-1">:</span>
              <p className="naxatw-text-xs naxatw-font-bold  naxatw-tracking-[-0.015rem] naxatw-text-grey-800">
                {item.value || '-'}%
              </p>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
};

const renderLabel = (label: any) => {
  return `${label.value}%`;
};

const CustomPieChart = ({ data }: IChartProps) => {
  const COLORS = data.map(
    (pieData: Record<string, any>) =>
      mapGovernmentStakeHolderColors[pieData._id] || '#8884d8',
  );

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={600} height={400}>
        <Pie
          data={data}
          dataKey="percentage"
          nameKey="_id"
          cx="50%"
          cy="50%"
          outerRadius={150}
          labelLine={false}
          label={renderLabel}
        >
          {data.map((singleObj: Record<string, any>, index: number) => (
            <Cell
              key={singleObj.value}
              fill={COLORS[index]}
              style={{ outline: 'none' }}
            />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip data={data} />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default CustomPieChart;
