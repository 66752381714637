/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-key */
import { FormControl } from '@Components/common/FormUI';
import InputLabel from '@Components/common/InputLabel';
import groupFormElements from '@Utils/index';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { FormFieldProps } from '@Constants/interface/FormInterface';
import getInputElement from '@Components/common/FormComponent/GetInputElement';
import { useFormContext } from 'react-hook-form';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import ErrorMessage from '@Components/common/ErrorMessage';
import { ComponentBasicFormValidationSchemaProps } from '@Validations/ComponentForm';
import {
  ComponentFormFields,
  includeFieldButtons,
} from '@Constants/FormConstants/componentFormConstants';
import { useQuery } from '@tanstack/react-query';
import {
  getFirstTierPartner,
  getProgramList,
  getAgreementModality,
} from '@Services/components';
import { useState } from 'react';
import Portal from '@Components/common/Layouts/Portal';
import ProgramForm from '@Components/AdminPanel/Programs/ProgramForm';
import { useLocation, useNavigate } from 'react-router-dom';
import PartnersForm from '@Components/AdminPanel/Partners/PartnersForm';
import CustomAddButton from './CustomAddButton';

const BasicDetailForm = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const groupedFormFields = groupFormElements(ComponentFormFields);
  const [openProgrammeForm, setProgrmmeForm] = useState<boolean>(false);
  const [openPartnerForm, setPartnerForm] = useState<boolean>(false);
  const {
    formState: { errors },
    register,
    getValues,
    control,
    setValue,
    watch,
  } = useFormContext<ComponentBasicFormValidationSchemaProps>();
  const formProps = {
    register,
    control,
    getValues,
    setValue,
    errors,
  };
  const disableEndDate = watch('start_date');

  const { data: firstTierPartnerDropDown } = useQuery({
    queryKey: ['getfirstTierPartneOptions'],
    queryFn: () => getFirstTierPartner(),
    select: (data: Record<string, any>) =>
      data?.data.map((options: Record<string, any>) => ({
        id: options?.id,
        label: options?.name,
        value: options?.id,
      })),
  });

  const { data: programTypeDropDown } = useQuery({
    queryKey: ['get-program-type-options'],
    queryFn: () => getProgramList(),
    select: (data: Record<string, any>) =>
      data?.data.map((options: Record<string, any>) => ({
        id: options?.id,
        label: options?.name,
        value: options?.id,
      })),
  });

  const { data: agreementDropDown } = useQuery({
    queryKey: ['getAgreement'],
    queryFn: () => getAgreementModality(),
    select: (data: Record<string, any>) =>
      data?.data.map((options: Record<string, any>) => ({
        id: options?.id,
        label: options?.name,
        value: options?.id,
      })),
  });

  const dropDownOptions: any = {
    is_extension: [
      { id: 1, label: 'Yes', value: 'true' },
      { id: 2, label: 'No', value: 'false' },
    ],
    program: programTypeDropDown,
    first_tier_partner: firstTierPartnerDropDown,
    aggrement_modality: agreementDropDown,
  };

  return (
    <div className="basic-info-form-container scrollbar naxatw-h-[calc(100vh-440px)] naxatw-w-full naxatw-overflow-y-auto naxatw-px-6 lg:naxatw-h-[calc(100vh-290px)]">
      <FlexColumn className="naxatw-mb-5 naxatw-w-full naxatw-space-y-5">
        {groupedFormFields?.map((fieldRow: Record<string, any>) => (
          <FlexRow className="naxatw-gap-x-5">
            {fieldRow?.map((field: FormFieldProps) => {
              const { id } = field;
              if (id === 'end_date') {
                field.disabledDays = disableEndDate || '';
              }

              if (!field?.isVisible) return null;
              return (
                <FormControl
                  className="naxatw-w-full naxatw-gap-[0.5rem]"
                  key={`${field.id}- ${field.name}`}
                >
                  <InputLabel
                    label={field.label}
                    astric={field.required}
                    id={field.id}
                  />
                  <div
                    className={`${includeFieldButtons.includes(field.name) ? 'naxatw-flex naxatw-items-center naxatw-gap-3' : ''}`}
                  >
                    {getInputElement(
                      {
                        ...field,
                      },
                      /* @ts-ignore */
                      formProps,

                      dropDownOptions[id] || [],
                    )}
                    {field.name === 'program' && pathname?.includes('add') ? (
                      <CustomAddButton
                        onClick={() => {
                          setProgrmmeForm(true);
                        }}
                        message="Add New Programme"
                      />
                    ) : null}
                    {field.name === 'first_tier_partner' &&
                    pathname?.includes('add') ? (
                      <CustomAddButton
                        onClick={() => {
                          setPartnerForm(true);
                        }}
                        message="Add New Partner"
                      />
                    ) : null}
                  </div>

                  <>
                    {
                      /* @ts-ignore */
                      formProps.errors[id] && (
                        <ErrorMessage
                          /* @ts-ignore */
                          message={formProps.errors[id]?.message}
                        />
                      )
                    }
                  </>
                </FormControl>
              );
            })}
          </FlexRow>
        ))}

        {openProgrammeForm && (
          <Portal>
            <ProgramForm
              onClose={() => {
                navigate('/data-bank/projects/add');
                setProgrmmeForm(false);
              }}
              setProgramme={(id: number) => setValue('program', id)}
            />
          </Portal>
        )}

        {openPartnerForm && (
          <Portal>
            <PartnersForm
              onClose={() => {
                navigate('/data-bank/projects/add');
                setPartnerForm(false);
              }}
              setPartner={(id: number) => setValue('first_tier_partner', id)}
            />
          </Portal>
        )}
      </FlexColumn>
    </div>
  );
};

export default hasErrorBoundary(BasicDetailForm);
