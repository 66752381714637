import { createSlice } from '@reduxjs/toolkit';
import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

interface DataBankState {
  report: Record<string, any>;
}

const initialState: DataBankState = {
  report: {
    filterParams: null,
  },
};

const setFilterState: CaseReducer<
  DataBankState,
  PayloadAction<Partial<DataBankState>>
> = (state, action) => {
  return {
    ...state,
    report: {
      filterParams: action.payload,
    },
  };
};

const clearFilterState: CaseReducer<
  DataBankState,
  PayloadAction<Partial<DataBankState>>
> = state => {
  return {
    ...state,
    report: {
      filterParams: null,
    },
  };
};

const dataBankSlice = createSlice({
  name: 'databankforms',
  initialState,
  reducers: {
    setFilterState,
    clearFilterState,
  },
});

export { dataBankSlice };

export default dataBankSlice.reducer;
