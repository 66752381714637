/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import avatarLogo from '@Assets/images/avatar-images.svg';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@Components/RadixComponents/DropDownMenu';
import Icon from '@Components/common/Icon';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import Flex from '@Components/common/Layouts/Flex';
import { getInitialsFromString } from '@Utils/index';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTypedSelector } from '@Store/hooks';
import { setCommonState } from '@Store/actions/common';
import { useDispatch } from 'react-redux';
import { Button } from 'react-day-picker';
import Portal from '@Components/common/Layouts/Portal';
import AccountSettings from '@Components/AccountSettings';
import { useSpring, animated } from '@react-spring/web';

const AccountMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [accountSettingsOpen, setAccountSettingsOpen] = useState(false);
  const userProfile = useTypedSelector(state => state?.common?.userProfile);

  const handleLogout = () => {
    localStorage.removeItem('token');
    dispatch(setCommonState({ userProfile: {}, userProfileError: null }));
    navigate('/');
  };
  const hasProfilePicture = false;

  const accountSettingsAnimation = useSpring({
    transform: accountSettingsOpen ? 'scale(1)' : 'scale(0)',
    config: { tension: 110, friction: 18 },
  });
  return (
    <>
      {accountSettingsOpen && (
        <Portal>
          <animated.div style={{ ...accountSettingsAnimation }}>
            <AccountSettings onClose={() => setAccountSettingsOpen(false)} />
          </animated.div>
        </Portal>
      )}
      <DropdownMenu>
        <DropdownMenuTrigger className="naxatw-outline-none">
          <div className="naxatw-relative">
            <div className="naxatw-h-8 naxatw-w-8 naxatw-rounded-full naxatw-bg-[#417EC9] naxatw-pt-1.5">
              <p className="naxatw-text-base naxatw-font-medium naxatw-uppercase naxatw-leading-5 naxatw-text-white">
                {getInitialsFromString(userProfile?.username || '')}
              </p>
            </div>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="end"
          className="naxatw-w-[15rem] naxatw-py-2 naxatw-shadow-[0px_2px_20px_4px_rgba(0,0,0,0.12)] sm:naxatw-w-[17.5rem]"
        >
          <FlexRow className="naxatw-gap-3 naxatw-border-b-[1px] naxatw-border-[#D7D7D7] naxatw-px-3 naxatw-py-4">
            <div>
              {hasProfilePicture ? (
                <div className="naxatw-h-[2.8rem] naxatw-w-[2.8rem] naxatw-items-center naxatw-justify-center naxatw-rounded-full sm:naxatw-h-[3.5rem] sm:naxatw-w-[3.5rem]">
                  <img
                    src={avatarLogo}
                    alt="profile"
                    className="naxatw-w-full"
                  />
                </div>
              ) : (
                <Flex className="naxatw-h-[2.8rem] naxatw-w-[2.8rem] naxatw-items-center naxatw-justify-center naxatw-rounded-full naxatw-bg-[#417EC9] sm:naxatw-h-[3.5rem] sm:naxatw-w-[3.5rem]">
                  <p className="naxatw-text-[1.5rem] naxatw-uppercase naxatw-text-white sm:naxatw-text-[1.727rem]">
                    {getInitialsFromString(userProfile?.username || '')}
                  </p>
                </Flex>
              )}
            </div>
            <div className="naxatw-line-clamp-3 naxatw-text-[0.875rem]">
              <p className="naxatw-line-clamp-1 naxatw-text-[1.063rem] naxatw-font-bold naxatw-uppercase naxatw-text-[#475467]">
                {userProfile?.username || ''}
              </p>
              <p className="naxatw-text-[0.875rem] naxatw-text-[#667085]">
                {userProfile?.group_name || ''}
              </p>
            </div>
          </FlexRow>
          <FlexColumn className="naxatw-mt-3">
            <Button
              onClick={() => setAccountSettingsOpen(true)}
              // to={`/account-settings?username=${getInitialsFromString(userProfile?.username || '')}`}
              className=""
            >
              <FlexRow className="naxatw-items-center naxatw-gap-2 naxatw-p-3 hover:naxatw-bg-[#F5FAFF]">
                <Icon name="settings" className="naxatw-text-[#475467]" />
                <p className="naxatw-pb-1 naxatw-text-[0.938rem] naxatw-text-[#475467]">
                  Account settings
                </p>
              </FlexRow>
            </Button>
          </FlexColumn>
          <FlexRow
            className="naxatw-cursor-pointer naxatw-items-center naxatw-gap-2 naxatw-p-3 hover:naxatw-bg-[#F5FAFF]
          "
            onClick={handleLogout}
          >
            <Icon name="logout" className="naxatw-text-[#475467]" />
            <p className="naxatw-pb-1 naxatw-text-[0.938rem] naxatw-text-[#475467]">
              Logout
            </p>
          </FlexRow>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};

export default AccountMenu;
