import React, { useEffect, useState } from 'react';
import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import Icon from '@Components/common/Icon';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import userImage from '@Assets/images/userImage.png';
import InputLabel from '@Components/common/InputLabel';
import { FormControl, Input } from '@Components/common/FormUI';
import { Button } from '@Components/RadixComponents/Button';
import { useForm } from 'react-hook-form';
import { useTypedSelector } from '@Store/hooks';
import { useMutation } from '@tanstack/react-query';
import { updateUserPassword, updateUserProfile } from '@Services/common';
import { toast } from 'react-toastify';
import { zodResolver } from '@hookform/resolvers/zod';
import { accountSettingsPasswordValidation } from '@Validations/accountSettingsValidation';

interface IAccountSettingsProps {
  onClose: () => void;
}

const AccountSettings = ({ onClose }: IAccountSettingsProps) => {
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [oldPwVisibility, setOldPwVisibility] = useState(false);
  const [pwVisibility, setPwVisibility] = useState(false);
  const [confirmPwVisibility, setConfirmPwVisibility] = useState(false);
  const userProfile = useTypedSelector(state => state?.common?.userProfile);
  const [firstName, setFirstName] = useState(userProfile.first_name);
  const [lastName, setLastName] = useState(userProfile.last_name);

  const pwInitialState = {
    oldPassword: '',
    password: '',
    confirmPassword: '',
  };
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: zodResolver(accountSettingsPasswordValidation),
    defaultValues: pwInitialState,
  });
  const { mutate: accountNameMutate, isLoading: userProfileIsUpdating } =
    useMutation({
      mutationKey: ['accountName', userProfile?.id],
      mutationFn: () =>
        updateUserProfile({ first_name: firstName, last_name: lastName }),
      onSuccess: () => {
        toast.success('Profile Updated Successfully');
        onClose();
      },
    });
  const { mutate: accountPasswordMutate, isLoading: passwordIsUpdating } =
    useMutation({
      mutationKey: ['accountPassword', userProfile?.id],
      mutationFn: () => {
        const { oldPassword, password, confirmPassword } = getValues();
        return updateUserPassword({
          old_password: oldPassword,
          new_password: password,
          confirm_password: confirmPassword,
        });
      },
      onSuccess: () => {
        toast.success('Password Updated Successfully');
        setValue('oldPassword', '');
        setValue('password', '');
        setValue('confirmPassword', '');
        onClose();
      },
    });

  function handleNameSubmission() {
    accountNameMutate();
  }
  function handlePasswordSubmission() {
    accountPasswordMutate();
  }
  useEffect(() => {
    setFirstName(userProfile.first_name);
    setLastName(userProfile.last_name);
    setValue('oldPassword', '');
    setValue('password', '');
    setValue('confirmPassword', '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changePasswordOpen]);

  return (
    <>
      <div className="naxatw-relative naxatw-h-screen naxatw-w-full">
        <div className="naxatw-absolute naxatw-left-1/2 naxatw-top-1/2 naxatw-h-3/5  naxatw-w-11/12 naxatw-translate-x-[-50%] naxatw-translate-y-[-50%] naxatw-overflow-hidden naxatw-rounded-[1.25rem] naxatw-bg-white lg:naxatw-w-[60%] xl:naxatw-w-[45%]">
          <FlexRow className="naxatw-items-center naxatw-justify-between naxatw-self-stretch naxatw-px-7 naxatw-py-5 naxatw-shadow-[0px_2px_20px_4px_rgba(0,0,0,0.12)]">
            <BreadCrumb heading="Account Settings" />
            <Icon
              name="close"
              className="naxatw-flex naxatw-items-center naxatw-text-2xl naxatw-text-gray-600
              "
              onClick={() => onClose()}
            />
          </FlexRow>
          <div className="naxatw-grid naxatw-grid-cols-12 naxatw-gap-10 naxatw-px-7 naxatw-pt-5">
            <FlexColumn className="naxatw-col-span-5 naxatw-h-screen naxatw-rounded-lg naxatw-border">
              <Button
                variant="link"
                className={`naxatw-flex naxatw-cursor-pointer naxatw-select-none naxatw-items-center !naxatw-justify-start naxatw-gap-3 naxatw-rounded-t-lg ${!changePasswordOpen ? 'naxatw-bg-primary-100' : 'naxatw-bg-transparent'} naxatw-px-4 naxatw-py-3 naxatw-ease-in hover:naxatw-bg-secondary-100`}
                onClick={() => setChangePasswordOpen(false)}
              >
                <Icon
                  name="person"
                  className={`naxatw-items-center naxatw-text-[1.5rem] ${!changePasswordOpen ? 'naxatw-text-secondary-500 ' : 'naxatw-text-gray-800'}`}
                />
                <p
                  className={`naxatw-text-[0.875rem] naxatw-leading-normal naxatw-tracking-[-0.0175rem] ${!changePasswordOpen ? 'naxatw-font-bold naxatw-text-secondary-500' : 'naxatw-font-normal naxatw-text-gray-800'}`}
                >
                  Profile
                </p>
              </Button>
              <Button
                className={`naxatw-flex naxatw-cursor-pointer naxatw-select-none naxatw-items-center !naxatw-justify-start naxatw-gap-3 naxatw-px-4 naxatw-py-3 naxatw-ease-in hover:naxatw-bg-secondary-100 ${changePasswordOpen ? 'naxatw-bg-primary-100' : 'naxatw-bg-transparent'}`}
                variant="link"
                onClick={() => setChangePasswordOpen(true)}
              >
                <Icon
                  name="lock"
                  className={`naxatw-items-center naxatw-text-[1.5rem] ${changePasswordOpen ? 'naxatw-text-secondary-500 ' : 'naxatw-text-gray-800'}`}
                />
                <p
                  className={`naxatw-text-[0.875rem] naxatw-leading-normal naxatw-tracking-[-0.0175rem] ${changePasswordOpen ? '!naxatw-font-bold naxatw-text-secondary-500' : 'naxatw-font-normal naxatw-text-gray-800'} naxatw-font-normal`}
                >
                  Change Password
                </p>
              </Button>
            </FlexColumn>
            {!changePasswordOpen ? (
              <FlexColumn className="naxatw-col-span-7 naxatw-w-full naxatw-gap-6">
                <FlexRow className="naxatw-items-center naxatw-gap-3">
                  <img
                    src={userImage}
                    alt="User"
                    className="naxatw-h-[3.75rem] naxatw-w-[3.71131rem] naxatw-object-contain"
                  />
                  <p className="naxatw-text-base naxatw-leading-5 naxatw-text-gray-800">
                    {userProfile?.username}
                  </p>
                </FlexRow>
                <FlexColumn className="naxatw-gap-32">
                  <FlexColumn className="naxatw-w-full naxatw-gap-4">
                    <FlexColumn className="naxatw-gap-1">
                      <InputLabel label="First Name" />
                      <Input
                        type="text"
                        placeholder="First Name"
                        className="naxatw-w-full"
                        value={firstName}
                        onChange={e => setFirstName(e.target.value)}
                      />
                    </FlexColumn>
                    <FlexColumn className="naxatw-gap-1">
                      <InputLabel label="Last Name" />
                      <Input
                        type="text"
                        placeholder="Last Name"
                        className="naxatw-w-full"
                        value={lastName}
                        onChange={e => setLastName(e.target.value)}
                      />
                    </FlexColumn>
                  </FlexColumn>
                  <Button
                    variant="primary"
                    className="naxatw-mx-auto naxatw-w-1/5"
                    onClick={() => handleNameSubmission()}
                    disabled={
                      firstName === userProfile.first_name &&
                      lastName === userProfile.last_name
                    }
                    isLoading={userProfileIsUpdating}
                  >
                    Save
                  </Button>
                </FlexColumn>
              </FlexColumn>
            ) : (
              <form
                className="naxatw-col-span-7 naxatw-flex naxatw-flex-col naxatw-gap-20"
                onSubmit={handleSubmit(handlePasswordSubmission)}
              >
                <FlexColumn className="naxatw-gap-4">
                  <FormControl className="naxatw-relative">
                    <InputLabel label="Old Password" />
                    <Input
                      id="password"
                      placeholder="Enter your Old Password"
                      type={oldPwVisibility ? 'text' : 'password'}
                      className="naxatw-mt-2"
                      {...register('oldPassword')}
                    />
                    <Icon
                      name={oldPwVisibility ? 'visibility' : 'visibility_off'}
                      onClick={() => setOldPwVisibility(!oldPwVisibility)}
                      className="naxatw-absolute naxatw-right-4 naxatw-top-[56%] naxatw-select-none naxatw-text-xl"
                    />
                    {errors.password && (
                      <p className="naxatw-text-sm naxatw-text-red-500">
                        {errors.oldPassword?.message}
                      </p>
                    )}
                  </FormControl>
                  <FormControl className="naxatw-relative">
                    <InputLabel label="Enter New Password" />
                    <Input
                      id="password"
                      placeholder="Enter your Password"
                      type={pwVisibility ? 'text' : 'password'}
                      className="naxatw-mt-2"
                      {...register('password')}
                    />
                    <Icon
                      name={pwVisibility ? 'visibility' : 'visibility_off'}
                      onClick={() => setPwVisibility(!pwVisibility)}
                      className="naxatw-absolute naxatw-right-4 naxatw-top-[56%] naxatw-select-none naxatw-text-xl"
                    />
                    {errors.password && (
                      <p className="naxatw-text-sm naxatw-text-red-500">
                        {errors.password.message}
                      </p>
                    )}
                  </FormControl>

                  <FormControl className="naxatw-relative">
                    <InputLabel label="Comfirm Password" />
                    <Input
                      id="confirm-password"
                      placeholder="Enter Password Again"
                      type={confirmPwVisibility ? 'text' : 'password'}
                      className="naxatw-mt-2"
                      {...register('confirmPassword')}
                    />
                    <Icon
                      name={
                        confirmPwVisibility ? 'visibility' : 'visibility_off'
                      }
                      onClick={() =>
                        setConfirmPwVisibility(!confirmPwVisibility)
                      }
                      className="naxatw-absolute naxatw-right-4 naxatw-top-[56%] naxatw-select-none naxatw-text-xl"
                    />
                    {errors.confirmPassword && (
                      <p className="naxatw-text-sm naxatw-text-red-500">
                        {errors.confirmPassword.message}
                      </p>
                    )}
                  </FormControl>
                </FlexColumn>
                <Button
                  variant="primary"
                  className="naxatw-mx-auto naxatw-w-1/5"
                  isLoading={passwordIsUpdating}
                  disabled={passwordIsUpdating}
                >
                  Save
                </Button>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountSettings;
