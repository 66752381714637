/* eslint-disable no-nested-ternary */
import { useMapLibreGLMap } from '@Components/common/MapLibreComponents';
import MapContainer from '@Components/common/MapLibreComponents/MapContainer';
import BaseLayerSwitcher from '@Components/common/MapLibreComponents/BaseLayerSwitcher';
import VectorTileLayer from '@Components/common/MapLibreComponents/Layers/VectorTileLayer';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getFederalWiseCount,
  getOverlayLayerList,
} from '@Services/mainDashboard';
import { getBbox } from '@Services/provincialProfile';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import switchTile from '@Utils/switchVectorTile';
import { setSelectedFeature } from '@Store/actions/provincialProfile';
import {
  setMainDashboardStates,
  toggleOverlayPopup,
  setMapStates,
} from '@Store/actions/mainDashboard';
import {
  DashboardLegendDropdownOptions,
  defaultLayerStyle,
  pointStyles,
  polygonStyles,
  roadStyles,
} from '@Constants/map';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { getExpression } from '@Constants/mainDashboard';
import LegendWithFilter from '@Components/common/MapLibreComponents/LegendWithFIlter';
import 'maplibre-gl/dist/maplibre-gl.css';
import '@Components/common/MapLibreComponents/map.css';
import RangeSlider from '@Components/RadixComponents/RangelSlider';
import OverlayLayerLegend from '@Components/common/MapLibreComponents/OverlayLayerLegend';
import MapTools from '../MapTools';
import MapHeader from '../MapHeader';
import MapInfoButton from '../MapInfoButton';
import MapPopup from '../MapPopup';
import MapAdditionalTools from '../MapAdditionalTools';
import SearchFilter from '../SearchFilter';
import NoDataAvailable from './NodataAvailable';
import DownloadPopover from '../MapAdditionalTools/DownloadPopover/DownloadPopover';

const BASEURL = process.env.API_URL_V1;

const Map = () => {
  const dispatch = useTypedDispatch();
  const mapRef = useRef(null);
  const queryClient = useQueryClient();
  const [showNoDataAvailable, setShowNoDataAvailable] = useState(false);

  const { map, isMapLoaded } = useMapLibreGLMap({
    mapOptions: {
      center: [83.6667, 28.2],
      zoom: 6,
      minZoom: 6,
      maxZoom: 19,
      preserveDrawingBuffer: true,
      maxBounds: [
        [73.916016, 23.785345],
        [94.669189, 32.879587],
      ],
    },
  });

  const activeViewBy = useTypedSelector(
    state => state.mainDashboard.map.activeViewBy,
  );
  const selectedFeature = useTypedSelector(
    state => state.provincialProfile.selectedFeature,
  );
  const activeStatus = useTypedSelector(
    state => state.mainDashboard.map.activeStatus,
  );
  const activeFilterOption = useTypedSelector(
    state => state.mainDashboard.map.activeFilterOption,
  );
  const showDownloadPopover = useTypedSelector(
    state => state.mainDashboard.map.showDownloadPopover,
  );
  const activeBaseLayer = useTypedSelector(
    state => state.mainDashboard.activeBaseLayer,
  );
  const overlayLayerList = useTypedSelector(
    state => state.mainDashboard.overlayLayerList,
  );
  // show filter
  const programmeFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.program,
  );

  const componentFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.component,
  );

  const firstTierPartnerParams = useTypedSelector(
    state => state.mainDashboard.filterParams.firstTierPartner,
  );

  const sectorGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.sector,
  );

  const markerGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.markerGroup,
  );

  const subMarkersParams = useTypedSelector(
    state => state.mainDashboard.filterParams.subMarkers,
  );

  const startDate = useTypedSelector(
    state => state.mainDashboard.filterParams.startDate,
  );

  const endDate = useTypedSelector(
    state => state.mainDashboard.filterParams.endDate,
  );

  const [hoveredFeature, setHoveredFeature] = useState<Record<
    string,
    any
  > | null>(null);

  const [fillOpacity, setFillOpacity] = useState(0.7);

  const { data: zoomToExtent, isFetching: isLoading } = useQuery({
    queryKey: ['municipal-zoom-to-extent', selectedFeature?.id, activeViewBy],
    queryFn: async () =>
      getBbox({
        [`${activeViewBy}`]: selectedFeature?.id,
      }),
    enabled: !!selectedFeature?.id,
    select: res => res.data?.extent,
  });

  const { data: countMaping, isFetching } = useQuery({
    queryKey: [
      'province-program-count-mapping',
      activeStatus,
      activeViewBy,
      activeFilterOption,
      // show filter
      programmeFilterParams,
      componentFilterParams,
      firstTierPartnerParams,
      sectorGroupParams,
      markerGroupParams,
      subMarkersParams,
      startDate,
      endDate,
    ],
    queryFn: async () =>
      getFederalWiseCount({
        federal_type: activeViewBy,
        by: activeFilterOption,
        // show filter
        program: programmeFilterParams?.join(','),
        project: componentFilterParams?.join(','),
        first_tier_partner: firstTierPartnerParams?.join(','),
        sector: sectorGroupParams?.join(','),
        marker: markerGroupParams?.join(','),
        marker_category: subMarkersParams?.join(','),
        ...(startDate && endDate
          ? {
              start_date: startDate,
              end_date: endDate,
            }
          : {}),
      }),
    select: res => res.data,
  });

  // fetch layers and set them to state
  useQuery({
    queryKey: ['overlay-layers'],
    queryFn: getOverlayLayerList,
    select: res => res.data.data,
    onSuccess: res => {
      const modifiedResponse = res?.map((layer: Record<string, any>) => ({
        ...layer,
        checked: false,
      }));
      dispatch(setMainDashboardStates({ overlayLayerList: modifiedResponse }));
    },
    // enabled: overlayLayerList === null,
  });

  // to reset the map when clicked outside the feature
  const handleMapClick = (clickInfo: Record<string, any>) => {
    if (clickInfo.hasFeature) return;
    dispatch(setSelectedFeature(null));
    map?.fitBounds([
      [79.05859375, 25.745610382199022],
      [88.033447265625, 31.44867367928756],
    ]);
  };

  useEffect(() => {
    if (!map || selectedFeature) return;
    map?.fitBounds([
      [79.05859375, 25.745610382199022],
      [88.033447265625, 31.44867367928756],
    ]);
  }, [selectedFeature, map]);

  // code block to change the fill and outline on hover
  useEffect(() => {
    if (!map || !countMaping || !isMapLoaded) return;
    const fillData = getExpression(countMaping, selectedFeature?.id);

    if (
      !map.getLayer(`vectorTile-${activeViewBy}`) &&
      !map.getLayer(`vectorTile-${activeViewBy}-outline`)
    )
      return;
    map.setPaintProperty(`vectorTile-${activeViewBy}`, 'fill-color', fillData);

    // to set the outline to green of the map when hovered
    if (hoveredFeature?.isHovered) {
      map.setPaintProperty(`vectorTile-${activeViewBy}-outline`, 'line-color', [
        'match',
        ['get', 'id'],
        hoveredFeature?.id,
        '#DE0A26',
        '#808080',
      ]);
      map.setPaintProperty(
        `vectorTile-${activeViewBy}-outline`,
        'line-opacity',
        ['match', ['get', 'id'], hoveredFeature?.id, 1.5, 0.1],
      );
    } else {
      map.setPaintProperty(
        `vectorTile-${activeViewBy}-outline`,
        'line-color',
        '#808080',
      );
    }
  }, [
    map,
    hoveredFeature,
    countMaping,
    selectedFeature,
    activeViewBy,
    activeStatus,
    isMapLoaded,
  ]);

  // set outline to the province and district
  useEffect(() => {
    if (!map || !isMapLoaded || activeViewBy === 'province') return;
    map.setPaintProperty(
      `common-vectorTile-province-outline`,
      'line-color',
      '#6E6E6E',
    );
    map.setPaintProperty(`common-vectorTile-province-outline`, 'line-width', 5);
  }, [map, isMapLoaded, activeViewBy]);

  // change the fill opacity when changing the slider
  useEffect(() => {
    if (!map) return;
    if (
      !map.getLayer(`vectorTile-${activeViewBy}`) &&
      !map.getLayer(`vectorTile-${activeViewBy}-outline`)
    )
      return;
    map.setPaintProperty(
      `vectorTile-${activeViewBy}`,
      'fill-opacity',
      fillOpacity,
    );
  }, [activeViewBy, fillOpacity, map]);

  useEffect(() => {
    if (!map || !selectedFeature) return;
    if (isLoading || !zoomToExtent) return;
    map.fitBounds(zoomToExtent, {
      padding: 120,
    });
  }, [selectedFeature, map, isLoading, zoomToExtent, dispatch]);

  useEffect(() => {
    if (!map) return;
    map.fitBounds([
      [79.05859375, 25.745610382199022],
      [88.033447265625, 31.44867367928756],
    ]);
  }, [activeViewBy, map]);

  useEffect(
    () => () => {
      dispatch(setSelectedFeature(null));
      queryClient.invalidateQueries({
        queryKey: ['overlay-layers'],
      });
      dispatch(toggleOverlayPopup(false));
    },
    [dispatch, queryClient],
  );

  const isOverlayLayerApplied = useMemo(
    () => overlayLayerList?.some(layer => layer.checked),
    [overlayLayerList],
  );

  // show data not available if data is empty
  useEffect(() => {
    if (!map || !isMapLoaded || isLoading || !countMaping) return () => {};
    if (countMaping?.length === 0) {
      setShowNoDataAvailable(true);
    }
    const resetNoDataAvailable = setTimeout(() => {
      setShowNoDataAvailable(false);
    }, 2000);

    return () => {
      return clearTimeout(resetNoDataAvailable);
    };
  }, [map, isMapLoaded, isLoading, countMaping]);

  return (
    <div style={{ width: '100%', height: '100%' }} ref={mapRef}>
      <MapContainer
        style={{ width: '100%', height: '100%' }}
        map={map}
        isMapLoaded={isMapLoaded}
      >
        <MapHeader />
        <MapInfoButton />
        <SearchFilter />
        <MapAdditionalTools
          // mapRef={mapRef}
          isFetching={isFetching}
          sourceIdToLoad={`vectorTile-${activeViewBy}`}
        />
        <BaseLayerSwitcher
          activeLayer={activeBaseLayer}
          isMapLoaded={isMapLoaded as boolean}
          onBaseLayerChange={layerId => {
            dispatch(setMainDashboardStates({ activeBaseLayer: layerId }));
          }}
        />
        <VectorTileLayer
          id={`vectorTile-${activeViewBy}`}
          isMapLoaded={isMapLoaded}
          url={`${BASEURL}/${switchTile(activeViewBy)}/{z}/{x}/{y}.pbf`}
          layerOptions={defaultLayerStyle}
          onFeatureSelect={feature => {
            dispatch(setSelectedFeature(feature));
          }}
          onHover={feature => {
            setHoveredFeature(feature);
          }}
          onMapClick={handleMapClick}
          interactions={['select']}
          showOutline
          showNamePopup
        />
        {/* for province boundary outline */}
        {activeViewBy === 'district' && (
          <VectorTileLayer
            id="common-vectorTile-province"
            isMapLoaded={isMapLoaded}
            url={`${BASEURL}/province_tile/{z}/{x}/{y}.pbf`}
            layerOptions={{
              type: 'line',
              layout: {},
              paint: {
                'line-color': '#4E4E4E',
                'line-width': 1.5,
              },
            }}
          />
        )}
        {/* for district boundary outline */}
        {activeViewBy === 'municipality' && (
          <VectorTileLayer
            id="common-vectorTile-district"
            isMapLoaded={isMapLoaded}
            url={`${BASEURL}/district_tile/{z}/{x}/{y}.pbf`}
            layerOptions={{
              type: 'line',
              layout: {},
              paint: {
                'line-color': '#4E4E4E',
                'line-width': 1,
              },
            }}
          />
        )}
        {overlayLayerList &&
          overlayLayerList?.map(layer => (
            <VectorTileLayer
              key={layer.id}
              id={`vectorTile-${layer.layer__name_en}`}
              isMapLoaded={isMapLoaded}
              url={`${BASEURL}/feature_collection_tile/{z}/{x}/{y}.pbf?feature=${layer.id}`}
              layerOptions={
                layer?.geometry_type === 'LineString'
                  ? {
                      ...roadStyles,
                      paint: {
                        ...roadStyles?.paint,
                        'line-color':
                          layer?.vector_layer_style__style_json?.[
                            'line-color'
                          ] || `#0664D2`,
                      },
                    }
                  : layer?.geometry_type === 'Point'
                    ? {
                        ...pointStyles,
                        paint: {
                          ...pointStyles?.paint,
                          'circle-color':
                            layer?.vector_layer_style__style_json?.[
                              'circle-color'
                            ] || `#0664D2`,
                        },
                      }
                    : layer?.geometry_type === 'Polygon'
                      ? {
                          ...polygonStyles,
                          paint: {
                            ...polygonStyles?.paint,
                            'fill-color':
                              layer?.vector_layer_style__style_json?.[
                                'fill-color'
                              ] || `#0664D2`,
                          },
                        }
                      : { ...roadStyles }
              }
              visibleOnMap={layer.checked}
            />
          ))}

        {/* --------------- map legend ---------------  */}
        <LegendWithFilter
          data={countMaping}
          datakey="count"
          selectedOption={activeFilterOption || 'program'}
          filterOptions={DashboardLegendDropdownOptions}
        />
        {isOverlayLayerApplied && <OverlayLayerLegend />}
        {/* --------------- map tools ---------------  */}
        <MapTools map={map} />
        <MapPopup
          hoveredMunicipality={selectedFeature}
          selectedKey={activeViewBy}
          className=" naxatw-top-[15%]"
        />

        {showDownloadPopover && (
          <DownloadPopover
            mapRef={mapRef}
            onClickOutside={() =>
              dispatch(
                setMapStates({
                  key: 'showDownloadPopover',
                  value: false,
                }),
              )
            }
          />
        )}
        {showNoDataAvailable && <NoDataAvailable />}
        <div className="actions naxatw-absolute naxatw-bottom-5 naxatw-right-36 naxatw-z-10 naxatw-w-28">
          <RangeSlider
            className="naxatw-h-8"
            max={100}
            step={1}
            value={[fillOpacity * 100]}
            onValueChange={(value: number[]) => setFillOpacity(value[0] / 100)}
          />
        </div>
      </MapContainer>
    </div>
  );
};

export default hasErrorBoundary(Map);
