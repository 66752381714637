/* eslint-disable no-nested-ternary */
import { getPublicKnowledgeRepositoryData } from '@Services/publicKnowledgeRepository';
import { useTypedSelector } from '@Store/hooks';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { useQuery } from '@tanstack/react-query';
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import isEmpty from '@Utils/isEmpty';
import KnowledgeCard from './KnowledgeCard';

const CardSection = ({ activeTab }: { activeTab: string }) => {
  const searchText = useTypedSelector(
    state => state.knowledgeRepository.publicSearchText,
  );
  const selectedDate = useTypedSelector(
    state => state.knowledgeRepository.publicSelectedDate,
  );
  const selectedFilterOptions = useTypedSelector(
    state => state.knowledgeRepository.publicFilterOptions,
  );

  const programmeFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.program,
  );

  const projectFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.component,
  );

  const firstTierPartnerParams = useTypedSelector(
    state => state.mainDashboard.filterParams.firstTierPartner,
  );

  const sectorGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.sector,
  );

  const startDate = useTypedSelector(
    state => state.mainDashboard.filterParams.startDate,
  );
  const endDate = useTypedSelector(
    state => state.mainDashboard.filterParams.endDate,
  );

  const { data: cardData, isLoading } = useQuery({
    queryFn: async () =>
      getPublicKnowledgeRepositoryData({
        limit: 10,
        search: searchText,
        published_date: selectedDate,
        is_contextual_indicator: !!activeTab?.includes('contextual'),
        program: programmeFilterParams?.join(','),
        project: projectFilterParams?.join(','),
        first_tier_partner: firstTierPartnerParams?.join(','),
        sector: sectorGroupParams?.join(','),
        start_date: startDate,
        end_date: endDate,
        ...selectedFilterOptions,
      }),
    queryKey: [
      'public-knowledge-repository-card-data',
      searchText,
      selectedDate,
      selectedFilterOptions,
      activeTab,
      programmeFilterParams,
      projectFilterParams,
      firstTierPartnerParams,
      sectorGroupParams,
      startDate,
      endDate,
    ],
    select: res => res.data?.results,
  });

  return (
    <div className="scrollbar naxatw-w-full naxatw-overflow-x-auto naxatw-rounded-lg naxatw-bg-white  lg:naxatw-h-[calc(100vh-236px)]">
      {isLoading ? (
        <div className="naxatw-flex naxatw-flex-col naxatw-gap-1 naxatw-bg-white naxatw-px-3 naxatw-py-2">
          <Skeleton className=" naxatw-h-[12rem] naxatw-w-full" />
          <Skeleton className=" naxatw-h-[12rem] naxatw-w-full" />
          <Skeleton className=" naxatw-h-[12rem] naxatw-w-full" />
        </div>
      ) : (
        <div className="naxatw-h-full">
          {isEmpty(cardData) ? (
            <div className="no-chart naxatw-flex naxatw-h-full naxatw-w-full naxatw-items-center naxatw-justify-center ">
              <NoChartDataComponent />
            </div>
          ) : (
            <div>
              {cardData?.map((repository: Record<string, any>) => (
                <KnowledgeCard key={repository?.id} repository={repository} />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default hasErrorBoundary(CardSection);
