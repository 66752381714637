import ToolTip from '@Components/RadixComponents/ToolTip';

interface ChartCardHeaderProps {
  title: string;
  needFullScreen?: boolean;
  iconClick?: any;
}

const ChartCardHeader = ({
  title,
  needFullScreen = true,
  iconClick,
}: ChartCardHeaderProps) => {
  return (
    <>
      <div className="naxatw-flex naxatw-items-center naxatw-justify-between naxatw-px-[1.5rem] naxatw-py-[1.25rem]">
        <p className="naxatw-text-[1.125rem] naxatw-font-bold naxatw-text-matt-100">
          {title}
        </p>
        <div className="naxatw-flex naxatw-items-center naxatw-gap-4">
          <ToolTip
            name="open_in_full"
            className={`naxatw-text-[#41588F] ${
              needFullScreen ? 'naxatw-block' : 'naxatw-hidden'
            }`}
            message="Full Screen"
          />
          <ToolTip
            name="download"
            className="naxatw-text-[#41588F]"
            message="Download"
            iconClick={iconClick || {}}
          />
        </div>
      </div>
    </>
  );
};

export default ChartCardHeader;
