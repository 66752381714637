/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import useRouteParamQuery from '@Hooks/useRouteParamQuery';
import {
  descriptionData,
  mapWikikpediaByProvince,
} from '@Constants/exploreByGeography';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { useRef } from 'react';
import useSeeMoreButton from '@Hooks/useSeeMoreButton';

interface IDescritptionCardProps {
  cardHeadersData: Record<string, any>;
  isLoading?: boolean;
  activeProvinceName?: Record<string, any>;
}

export default function DescriptionCard({
  cardHeadersData,
  isLoading,
  activeProvinceName,
}: IDescritptionCardProps) {
  const updatesRef = useRef(null);
  const getQueryParams = useRouteParamQuery();

  const getMunicipality = getQueryParams.get('municipality') || null;
  const wikipediaUrl = activeProvinceName
    ? mapWikikpediaByProvince[activeProvinceName?.name]
    : {};

  const { isOpen, setIsOpen, showSeeMoreBtn } = useSeeMoreButton(updatesRef);

  return (
    <>
      {isLoading ? (
        <div className="naxatw-grid naxatw-grid-cols-2 naxatw-gap-3">
          <Skeleton className="naxatw-h-[7rem] naxatw-w-[14rem]" />
          <Skeleton className="naxatw-h-[7rem] naxatw-w-[14rem]" />
          <Skeleton className="naxatw-h-[7rem] naxatw-w-[14rem]" />
          <Skeleton className="naxatw-h-[7rem] naxatw-w-[14rem]" />
          <Skeleton className="naxatw-h-[7rem] naxatw-w-[14rem]" />
          <Skeleton className="naxatw-h-[7rem] naxatw-w-[14rem]" />
        </div>
      ) : (
        <div className=" naxatw-w-full">
          {!getMunicipality && (
            <div className="naxatw-flex naxatw-flex-col naxatw-gap-8">
              <span className=" naxatw-text-ellipsis naxatw-text-[14px] naxatw-text-[#484848]">
                <p className="naxatw-text-ellipsis naxatw-text-[14px] naxatw-text-[#484848]">
                  {' '}
                  {cardHeadersData?.description || 'N/A'}
                </p>
                <br />

                <p
                  className="naxatw-cursor-pointer naxatw-text-sm naxatw-font-bold naxatw-leading-normal naxatw-text-[#06367D] naxatw-underline hover:naxatw-text-[#63589e]"
                  onClick={() => {
                    if (wikipediaUrl) {
                      window.open(
                        wikipediaUrl,
                        '_blank',
                        'noopener,noreferrer',
                      );
                    }
                  }}
                >
                  More Information
                </p>
              </span>
              <div className="naxatw-grid naxatw-grid-cols-2 naxatw-gap-y-8">
                {descriptionData?.data.map((cardHeaders: any) => (
                  <div
                    className=" naxatw-flex naxatw-flex-[1_0_0] naxatw-flex-col naxatw-gap-2"
                    key={cardHeaders.name}
                  >
                    <p className=" naxatw-text-sm naxatw-text-[#475467]">
                      {cardHeaders.name}
                    </p>
                    <p className=" naxatw-text-base naxatw-font-medium naxatw-text-[#06367D]">
                      {`${cardHeadersData[cardHeaders.value] || 'N/A'}`}
                    </p>
                  </div>
                ))}
              </div>
              <div className="province-update">
                <p className="naxatw-mb-1 naxatw-text-[0.875rem] naxatw-font-bold naxatw-text-primary-600">
                  Updates
                </p>
                <p
                  className={`${isOpen ? '' : 'naxatw-line-clamp-3'} naxatw-text-[0.875rem] naxatw-leading-[1.25rem] naxatw-text-[#484848]`}
                  ref={updatesRef}
                >
                  {cardHeadersData?.update || '-'}
                </p>
                {showSeeMoreBtn && (
                  <button
                    type="button"
                    className="naxatw-text-[0.875rem] naxatw-font-bold naxatw-leading-[1.25rem] naxatw-text-[#41588F]"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    See {isOpen ? 'Less' : 'More'}
                  </button>
                )}
              </div>
            </div>
          )}
          {getMunicipality && (
            <div className="naxatw-grid naxatw-grid-cols-2 naxatw-gap-y-8">
              <div className=" naxatw-flex naxatw-flex-[1_0_0] naxatw-flex-col naxatw-gap-2">
                <p className=" naxatw-text-sm naxatw-text-[#475467]">Mayor</p>
                <p className=" naxatw-text-base naxatw-font-medium naxatw-text-[#06367D]">
                  Jokh Bahadur Mahar
                </p>
              </div>
              <div className=" naxatw-flex naxatw-flex-[1_0_0] naxatw-flex-col naxatw-gap-2">
                <p className=" naxatw-text-sm naxatw-text-[#475467]">
                  Deputy Mayor
                </p>
                <p className=" naxatw-text-base naxatw-font-medium naxatw-text-[#06367D]">
                  Mahadev Pantha
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
