/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
import { namesWithoutTooltip, pathnameWithOthers } from '@Constants/index';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import {
  adminSidebarData,
  programManagerSidebarData,
  generalUserSidebarData,
  pefUserSidebarData,
} from '@Constants/adminSidebarData';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Icon from '@Components/common/Icon';
import { setCollapseSidebar } from '@Store/actions/common';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { IRoute } from '@Routes/types';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import {
  Arrow,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@radix-ui/react-tooltip';
import SubSidebar from './SubSidebar';

function getSideBarLinks(role: string) {
  if (!role) return [];
  switch (role) {
    case 'System Admin':
      return adminSidebarData;
    case 'Program Manager':
      return programManagerSidebarData;
    case 'Programme Manager':
      return programManagerSidebarData;
    case 'General User':
      return generalUserSidebarData;
    case 'Provincial Priority Entry User':
      return pefUserSidebarData;
    case 'PEF User':
      return pefUserSidebarData;
    default:
      return generalUserSidebarData;
  }
}

const Sidebar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const [activeTab, setActiveTab] = useState('');
  const [activeHoverTab, setActiveHoverTab] = useState('');
  const [showMobileNav, setShowMobileNav] = useState(false);
  const collapseSidebar = useTypedSelector(
    state => state.common.collapseSidebar,
  );
  const reportFilterState = useTypedSelector(
    state => state?.databankforms?.report?.filterParams,
  );

  const userProfile = useTypedSelector(state => state?.common?.userProfile);

  const sidebarLinks = userProfile?.group_name
    ? getSideBarLinks(userProfile.group_name)
    : [];

  const handleActiveTab = (tabName: string) => {
    const activeTabData = adminSidebarData.find(sidebarTab => {
      return tabName === sidebarTab.path;
    });
    if (activeTabData) {
      setActiveTab(activeTabData.path);
    }
  };

  const toggleSidebar = () => {
    dispatch(setCollapseSidebar(!collapseSidebar));
  };

  useEffect(() => {
    if (!pathname || pathname === '/') {
      navigate('/dashboard');
    }
  }, [pathname, navigate]);

  useEffect(() => {
    const activeTabData = adminSidebarData.find(sidebarTab => {
      if (sidebarTab.children) {
        return sidebarTab.children.some(child =>
          pathname.includes(`${sidebarTab.path}/${child.path}`),
        );
      }
      return pathname.includes(sidebarTab.path);
    });
    if (activeTabData) {
      setActiveTab(activeTabData.path);
    }
    setShowMobileNav(false);
  }, [pathname]);

  return (
    <div
      className={`${
        collapseSidebar
          ? 'naxatw-w-[100vw] md:naxatw-w-[14.625rem] md:naxatw-min-w-[10rem] md:naxatw-translate-x-0'
          : 'md:naxatw-w-[3.25rem] md:naxatw-min-w-[5.75rem]'
      }
    naxatw-left-0 naxatw-top-0 naxatw-z-40 naxatw-hidden naxatw-h-[calc(100vh-63px)] naxatw-bg-primary-700 naxatw-duration-100 naxatw-ease-in-out sm:naxatw-translate-x-0 md:naxatw-grid`}
    >
      <FlexColumn
        className="scrollbar naxatw-h-[calc(100%-60px)] naxatw-flex-col-reverse 
        naxatw-justify-between naxatw-overflow-y-auto md:naxatw-flex-col"
      >
        <div className="naxatw-mt-2 naxatw-font-medium">
          {sidebarLinks?.map(({ name, icon, path, children }: IRoute) => (
            <div
              key={name}
              className={`${
                activeHoverTab === name && pathname.includes(`${path}`)
                  ? 'naxatw-bg-[#41588F]'
                  : ''
              }`}
            >
              <TooltipProvider delayDuration={100}>
                <Tooltip>
                  <TooltipTrigger className="naxatw-w-full">
                    <NavLink
                      to={
                        path === 'provincial-profile'
                          ? `${path}?location=Lumbini Province`
                          : path === 'report'
                            ? reportFilterState
                              ? `${path}?filter=true`
                              : `${path}?filter=false`
                            : path
                      }
                      onClick={() => {
                        if (name === 'Others') return;
                        handleActiveTab(
                          children?.length
                            ? `${path}/${children[0].path}`
                            : path,
                        );
                        setActiveHoverTab(
                          activeHoverTab === name ? '' : name || '',
                        );
                      }}
                      className={`${
                        activeTab === path ||
                        (children?.length &&
                          activeTab === `${path}/${children[0].path}`) ||
                        (pathnameWithOthers?.includes(pathname) &&
                          name === 'Others')
                          ? 'naxatw-bg-[#417EC9] naxatw-text-white'
                          : 'naxatw-border-white naxatw-text-white hover:naxatw-bg-[#41588F]'
                      } ${
                        !collapseSidebar
                          ? 'naxatw-flex-col '
                          : 'naxatw-items-center'
                      }  } naxatw-group naxatw-flex naxatw-justify-between
                  naxatw-px-4 naxatw-py-4 hover:naxatw-text-white xl:naxatw-px-2 xl:naxatw-py-3`}
                    >
                      <FlexRow className="naxatw-items-center naxatw-justify-center">
                        <div
                          className={`${collapseSidebar ? 'naxatw-flex' : ''}`}
                        >
                          <i
                            className={`material-symbols-outlined  ${`${collapseSidebar ? '' : 'naxatw-mb-2'}`} naxatw-text-xl`}
                          >
                            {icon}
                          </i>
                          <p
                            className={`${collapseSidebar ? 'naxatw-hidden' : ''} naxatw-text-xs naxatw-font-medium naxatw-leading-[0.875rem]`}
                          >
                            {name}
                          </p>
                        </div>

                        <div
                          className={`${
                            collapseSidebar ? 'naxatw-pl-2' : 'naxatw-hidden'
                          } naxatw-whitespace-nowrap naxatw-text-base naxatw-font-medium`}
                        >
                          {name}
                        </div>
                      </FlexRow>
                      {children && collapseSidebar && (
                        <i className="material-icons-outlined">
                          {activeHoverTab === name
                            ? 'expand_less'
                            : 'expand_more'}
                        </i>
                      )}
                    </NavLink>
                  </TooltipTrigger>
                  {namesWithoutTooltip?.includes(name as string) ? (
                    <TooltipContent side="right">
                      <SubSidebar setActiveTab={setActiveTab} />
                    </TooltipContent>
                  ) : (
                    <TooltipContent
                      className={`${collapseSidebar && 'naxatw-hidden'}`}
                      side="right"
                    >
                      <div className="message naxatw-rounded-sm naxatw-bg-[#417EC9] naxatw-px-3 naxatw-py-1 naxatw-text-sm naxatw-font-medium naxatw-text-white">
                        {name}
                      </div>
                      <Arrow
                        className="TooltipArrow naxatw-rounded"
                        style={{ fill: '#417EC9' }}
                      />
                    </TooltipContent>
                  )}
                </Tooltip>
              </TooltipProvider>
              <div>
                {children &&
                  collapseSidebar &&
                  (activeHoverTab === name || showMobileNav) &&
                  children.map(child => (
                    <NavLink
                      key={child.name}
                      to={`${path}/${child.path}`}
                      className={`hover:naxatw-bg-primary-5 naxatw-group naxatw-flex naxatw-items-center naxatw-bg-[#06367D] naxatw-p-2 ${
                        pathname.includes(`${path}/${child.path}`)
                          ? 'naxatw-bg-[#417EC9]'
                          : 'hover:naxatw-bg-[#41588F]'
                      }`}
                    >
                      <span
                        className={`${
                          collapseSidebar ? 'naxatw-ml-11' : 'naxatw-ml-4'
                        } naxatw-text-white`}
                      >
                        {collapseSidebar
                          ? child.name
                          : child?.name?.split(' ')[0]}
                      </span>
                    </NavLink>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </FlexColumn>
      <FlexRow className="naxatw-fixed naxatw-bottom-0 naxatw-w-full naxatw-justify-end">
        <Icon
          onClick={toggleSidebar}
          name="keyboard_tab"
          className={`${
            collapseSidebar ? 'naxatw-rotate-180' : ''
          } naxatw-mx-4 naxatw-mb-5 !naxatw-text-[1.5rem] naxatw-text-white hover:naxatw-text-gray-200`}
        />
      </FlexRow>
    </div>
  );
};

export default hasErrorBoundary(Sidebar);
