import { useSpring, animated } from '@react-spring/web';
import { useParams, useSearchParams } from 'react-router-dom';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import IconHeader from '@Components/common/PdfDesign/IconHeaders';
import { useQuery } from '@tanstack/react-query';
import { getMarkers, getWorkingAreas } from '@Services/program';
import { v4 as uuidv4 } from 'uuid';
import useScrollPosition from '@Hooks/useScrollPosition';
import Skeleton from '@Components/RadixComponents/Skeleton';
import React from 'react';
import { generateReportListStakeholder } from '@Services/report';

import ProgrammeDetailsHeader from './Header';
import StatusBar from './StatusBar';
import ComponentsTable from './ComponentsTable';
import CampaignGoals from './CampaignGoals';
// import KeyStakeHolders from './KeyStakeHolders';
// import AssociatedDocuments from './AssociatedDocuments';
import SectorsComponent from './Sectors';
import MapSection from './MapSection';
import MarkersCardBox from './MarkerCardBox';

const ProgrammeDetails = () => {
  const scrollPosition = useScrollPosition();
  const [searchParams] = useSearchParams();

  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });
  const { programmeId } = useParams();
  const componentId = searchParams.get('componentId');
  function transformStakeholders(data: any[]) {
    const result: any[] = [];

    data?.forEach(stakeholder => {
      stakeholder.stakeholders?.forEach((levelObj: any) => {
        Object?.keys(levelObj)?.forEach(level => {
          const content = levelObj[level]?.map((categoryObj: any) => {
            const category = Object?.keys(categoryObj)[0];
            const items = categoryObj[category];
            return { category, items };
          });

          result.push({ level, content });
        });
      });
    });

    return result;
  }

  const { data: workingAreas, isLoading: workingAreasLoading } = useQuery({
    queryKey: ['workingAreas', programmeId, componentId],
    queryFn: () =>
      getWorkingAreas(
        componentId ? { project: componentId } : { program: programmeId },
      ),
    select: res => res.data[0].data,
  });
  const { data: markersData, isLoading: markersDataIsLoading } = useQuery({
    queryKey: ['markers', programmeId, componentId],
    queryFn: () => getMarkers(componentId || ''),
    select: res => res.data,
    enabled: !!componentId,
  });

  const { data: stakeholderData, isLoading: stakeholderDataIsLoading } =
    useQuery({
      queryKey: ['stakeolderData', programmeId, componentId],
      queryFn: async () =>
        generateReportListStakeholder(
          componentId ? { component: componentId } : { programme: programmeId },
        ),
      select: res => transformStakeholders(res.data),
    });

  return (
    <>
      <animated.div style={{ ...springs }} className="naxatw-px-5">
        <div className="naxatw-mx-auto naxatw-flex naxatw-w-full naxatw-max-w-[90rem] naxatw-flex-col naxatw-gap-5 naxatw-py-4">
          <div
            className={`${scrollPosition > 0 ? 'naxatw-fixed naxatw-top-[6.8vh] naxatw-pt-10' : 'naxatw-relative'} naxatw-z-[45] naxatw-w-full naxatw-max-w-[80rem] naxatw-bg-white naxatw-pb-5 naxatw-pr-12 lg:naxatw-max-w-[90rem] 2xl:naxatw-pr-0`}
          >
            {' '}
            <ProgrammeDetailsHeader />
          </div>
          <div
            className={`${scrollPosition > 0 ? 'naxatw-mt-[7rem]' : 'naxatw-mt-0'} naxatw-flex naxatw-flex-col naxatw-gap-5`}
          >
            <StatusBar />

            {/* --------------------- working areas -------------------------- */}

            {/* -------------------------------- markers ------------------------- */}
            {componentId &&
              (markersDataIsLoading ? (
                <div className="naxatw-grid naxatw-grid-cols-2 naxatw-gap-3">
                  <Skeleton className="naxatw-h-[3rem] naxatw-w-full naxatw-rounded-lg" />
                  <Skeleton className="naxatw-h-[3rem] naxatw-w-full naxatw-rounded-lg" />
                  <Skeleton className="naxatw-h-[3rem] naxatw-w-full naxatw-rounded-lg" />
                  <Skeleton className="naxatw-h-[3rem] naxatw-w-full naxatw-rounded-lg" />
                </div>
              ) : (
                <FlexColumn className="naxatw-w-full naxatw-gap-3">
                  <IconHeader
                    name="donut_small"
                    title="Markers"
                    titleClassName="naxatw-text-[1.125rem] naxatw-font-bold !naxatw-text-matt-100"
                    iconClassName="!naxatw-text-2xl !naxatw-text-matt-100"
                    className="naxatw-gap-2"
                  />
                  <div className="naxatw-grid naxatw-w-full naxatw-grid-cols-2 naxatw-gap-2">
                    {markersData?.map((marker: any) => (
                      <MarkersCardBox
                        marker={marker?.marker_value}
                        markerName={marker?.marker_category}
                        key={uuidv4()}
                        className="naxatw-min-w-[30%]"
                      />
                    ))}
                  </div>
                </FlexColumn>
              ))}
            {!componentId && <ComponentsTable />}
            {componentId && <SectorsComponent />}
            <CampaignGoals />

            {stakeholderDataIsLoading ? (
              <FlexColumn className="naxatw-gap-2">
                <Skeleton className="naxatw-h-[3rem] naxatw-w-full naxatw-rounded-lg" />
                <Skeleton className="naxatw-h-[3rem] naxatw-w-full naxatw-rounded-lg" />
                <Skeleton className="naxatw-h-[3rem] naxatw-w-full naxatw-rounded-lg" />
                <Skeleton className="naxatw-h-[3rem] naxatw-w-full naxatw-rounded-lg" />
              </FlexColumn>
            ) : (
              <FlexColumn className="naxatw-gap-3">
                <IconHeader
                  name="groups"
                  title="Stakeholders"
                  titleClassName="naxatw-text-[1.125rem] naxatw-font-bold !naxatw-text-matt-100"
                  iconClassName="!naxatw-text-2xl !naxatw-text-matt-100"
                  className="naxatw-gap-2"
                />
                <FlexColumn className="naxatw-gap-3">
                  {stakeholderData?.map((stakeholder: any) => (
                    <FlexColumn
                      className="naxatw-gap-2"
                      key={stakeholder?.level}
                    >
                      <p className="naxatw-text-[0.625rem] naxatw-font-medium naxatw-uppercase naxatw-tracking-[0.03125rem] naxatw-text-primary-700">
                        {stakeholder?.level}
                      </p>
                      <FlexColumn className="naxatw-w-full naxatw-gap-1 naxatw-rounded-lg naxatw-border naxatw-bg-white naxatw-p-3 naxatw-shadow-sm">
                        {stakeholder?.content?.map(
                          (level: any, index: number) => {
                            const isLastIndex =
                              index === stakeholder.content.length - 1;
                            return (
                              <React.Fragment key={level?.category}>
                                <FlexColumn className="naxatw-gap-1">
                                  <p className="naxatw-text-[0.75rem] naxatw-font-bold naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                                    {level?.category}
                                  </p>
                                  <FlexColumn className="naxatw-gap-[0.62rem]">
                                    {level?.items?.map(
                                      (subStakeholders: any) => {
                                        return (
                                          <FlexRow
                                            className="naxatw-gap-[0.62rem]"
                                            key={subStakeholders.province}
                                          >
                                            {subStakeholders?.province && (
                                              <>
                                                <p className="naxatw-text-[0.75rem] naxatw-font-semibold naxatw-text-primary-700">
                                                  {subStakeholders?.province}
                                                </p>
                                                <div className="naxatw-h-[1rem] naxatw-w-[1px] naxatw-bg-secondary-300" />
                                              </>
                                            )}
                                            {subStakeholders?.names?.map(
                                              (
                                                items: any,
                                                itemsIndex: number,
                                              ) => {
                                                const isLastSubIndex =
                                                  itemsIndex ===
                                                  subStakeholders.names.length -
                                                    1;
                                                return (
                                                  <FlexRow
                                                    className="naxatw-gap-[0.62rem]"
                                                    key={items}
                                                  >
                                                    <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-text-matt-200">
                                                      {items}
                                                    </p>
                                                    <div
                                                      className={` ${isLastSubIndex ? 'naxatw-hidden' : 'naxatw-block'} naxatw-h-[1rem] naxatw-w-[1px] naxatw-bg-secondary-300 `}
                                                    />
                                                  </FlexRow>
                                                );
                                              },
                                            )}
                                          </FlexRow>
                                        );
                                      },
                                    )}
                                  </FlexColumn>
                                </FlexColumn>
                                <div
                                  className={` ${isLastIndex ? 'naxatw-hidden' : 'naxatw-block'} naxatw-h-[1px] naxatw-w-full naxatw-bg-neutral-200`}
                                />
                              </React.Fragment>
                            );
                          },
                        )}
                      </FlexColumn>
                    </FlexColumn>
                  ))}
                </FlexColumn>
              </FlexColumn>
            )}

            {workingAreasLoading ? (
              <div className="naxatw-grid naxatw-grid-cols-2 naxatw-gap-3">
                <Skeleton className="naxatw-h-[3rem] naxatw-w-full naxatw-rounded-lg" />
                <Skeleton className="naxatw-h-[3rem] naxatw-w-full naxatw-rounded-lg" />
                <Skeleton className="naxatw-h-[3rem] naxatw-w-full naxatw-rounded-lg" />
                <Skeleton className="naxatw-h-[3rem] naxatw-w-full naxatw-rounded-lg" />
              </div>
            ) : (
              <FlexColumn className="naxatw-gap-2">
                <IconHeader
                  name="place"
                  title="Working Areas"
                  titleClassName="naxatw-text-[1.125rem] naxatw-font-bold !naxatw-text-matt-100"
                  iconClassName="!naxatw-text-2xl !naxatw-text-matt-100"
                  className="naxatw-gap-2"
                />
                <div className="naxatw-grid naxatw-grid-cols-2 naxatw-gap-3">
                  {workingAreas?.map(
                    (area: any) =>
                      area.province !== null && (
                        <FlexRow
                          className={`naxatw-items-center naxatw-rounded-lg naxatw-border naxatw-p-3 naxatw-shadow-sm ${area === null ? 'naxatw-hidden' : 'naxatw-block'}`}
                          key={uuidv4()}
                        >
                          <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                            {area?.province} &nbsp;
                          </p>
                          <>
                            {area?.district && (
                              <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                                {` / ${area?.district}`} &nbsp;
                              </p>
                            )}
                            {area?.municipality && (
                              <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-200">
                                {` / ${area?.municipality}`}
                              </p>
                            )}
                          </>
                        </FlexRow>
                      ),
                  )}
                </div>
              </FlexColumn>
            )}
            <div className="naxatw-h-[35rem] naxatw-overflow-hidden naxatw-rounded-xl naxatw-border naxatw-border-gray-200 naxatw-bg-white naxatw-shadow-sm">
              <MapSection lat={28.3949} lon={84.124} zoom={6} />
            </div>
            {/* <KeyStakeHolders /> */}
          </div>
          {/* <AssociatedDocuments /> */}
        </div>
      </animated.div>
    </>
  );
};

export default ProgrammeDetails;
