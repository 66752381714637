import React, { useEffect, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import AdminHeader from '@Components/common/AdminHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import Portal from '@Components/common/Layouts/Portal';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import SectorTable from './SectorTable';
import SectorForm from './SectorForm';

const Sector = () => {
  const navigate = useNavigate();
  const [toggleForm, setToggleForm] = useState<boolean>(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes('edit') || pathname.includes('add')) {
      setToggleForm(true);
    } else {
      setToggleForm(false);
    }
  }, [pathname]);
  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });
  return (
    <animated.div
      style={{ ...springs }}
      className="naxatw-flex naxatw-h-full naxatw-flex-col naxatw-space-y-6"
    >
      <AdminHeader
        title="Sectors"
        registerButtonText="Add"
        onAddNewClick={() => {
          navigate('/data-bank/sectors/sector/add');
        }}
      />
      <SectorTable />
      {toggleForm && (
        <Portal>
          <SectorForm
            onClose={() => {
              navigate('/data-bank/sectors/sector');
            }}
          />
        </Portal>
      )}
    </animated.div>
  );
};

export default hasErrorBoundary(Sector);
