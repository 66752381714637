import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { provinceFillColor } from '@Constants/map';
import getRangeValueWithColorCode, {
  getRangeValueWithColorCodeDecimalSupport,
} from '@Utils/choropleth';
import convertToCurrencySystem from '@Utils/convertToCurrencySystem';
import { cn } from '@Utils/index';

interface ILegendProps {
  className?: string;
  data: any[];
  countKey?: string;
  decimalSupport?: Boolean;
  hide0?: Boolean;
  heading?: string;
}

const Legend = ({
  className,
  data,
  countKey = 'count',
  decimalSupport = false,
  hide0 = false,
  heading = '',
}: ILegendProps) => {
  const legends = decimalSupport
    ? getRangeValueWithColorCodeDecimalSupport(data, countKey)
    : getRangeValueWithColorCode(data, countKey);
  return (
    <FlexColumn
      gap={3}
      className={cn(
        'naxatw-absolute naxatw-bottom-5 naxatw-left-6 naxatw-z-10 naxatw-w-[175px] naxatw-rounded-lg naxatw-border naxatw-border-solid  naxatw-border-[#D7D7D7] naxatw-bg-white naxatw-px-3 naxatw-py-2 naxatw-shadow-[0px_2px_8px_0px_rgba(16,24,40,0.08)]',
        className,
      )}
    >
      <h6 className="naxatw-text-sm">{heading}</h6>
      <FlexColumn>
        <FlexRow gap={2} className="naxatw-mb-0.5">
          {!hide0 && (
            <>
              <div
                className="naxatw-h-6 naxatw-w-6"
                style={{
                  backgroundColor: `#FFFFFF`,
                  outline: '1px solid #E6E6E6',
                }}
              />
              <div className="naxatw-flex naxatw-items-center naxatw-justify-start naxatw-gap-1">
                <p className="naxatw-text-[0.625rem] naxatw-font-medium naxatw-uppercase naxatw-leading-3 naxatw-tracking-[0.03125rem] naxatw-text-matt-200">
                  0
                </p>
              </div>
            </>
          )}
        </FlexRow>
        {legends.map((legend: Record<string, any>) => (
          <FlexRow
            gap={2}
            className="naxatw-items-center"
            key={`${legend.min}-${legend.max}`}
          >
            <div
              className="naxatw-h-6 naxatw-w-6"
              style={{
                backgroundColor: `${legend.color}`,
                outline: '1px solid #E6E6E6',
              }}
            />
            {legend?.hasGreaterThan ? (
              <div className="naxatw-flex naxatw-items-center naxatw-justify-start naxatw-gap-1">
                <>
                  <p>&gt;=</p>
                  <p className="naxatw-text-[0.625rem] naxatw-font-medium naxatw-uppercase naxatw-leading-3 naxatw-tracking-[0.03125rem] naxatw-text-matt-200">
                    {convertToCurrencySystem(legend?.min)}
                  </p>
                </>
              </div>
            ) : (
              <div className="naxatw-flex naxatw-items-center naxatw-justify-start naxatw-gap-1">
                <>
                  <p className="naxatw-text-[0.625rem] naxatw-font-medium naxatw-uppercase naxatw-leading-3 naxatw-tracking-[0.03125rem] naxatw-text-matt-200">
                    {convertToCurrencySystem(legend?.min)}
                  </p>
                  <p>-</p>
                  <p className="naxatw-text-[0.625rem] naxatw-font-medium naxatw-uppercase naxatw-leading-3 naxatw-tracking-[0.03125rem] naxatw-text-matt-200">
                    {convertToCurrencySystem(legend?.max)}
                  </p>
                </>
              </div>
            )}
          </FlexRow>
        ))}
      </FlexColumn>
    </FlexColumn>
  );
};

export default hasErrorBoundary(Legend);

export const StaticLegendForProvince = ({
  className,
}: {
  className?: string;
}) => {
  return (
    <FlexColumn
      gap={3}
      className={cn(
        'naxatw-absolute naxatw-bottom-5 naxatw-left-6 naxatw-z-10 naxatw-w-[175px] naxatw-rounded-lg naxatw-border naxatw-border-solid  naxatw-border-[#D7D7D7] naxatw-bg-white naxatw-px-3 naxatw-py-2 naxatw-shadow-[0px_2px_8px_0px_rgba(16,24,40,0.08)]',
        className,
      )}
    >
      {provinceFillColor?.map((legend: Record<string, any>) => (
        <FlexRow gap={2} className="naxatw-items-center" key={legend?.color}>
          <div
            className="naxatw-h-6 naxatw-w-6 naxatw-opacity-70"
            style={{
              backgroundColor: `${legend.color}`,
              outline: '1px solid #E6E6E6',
            }}
          />
          <div className="naxatw-flex naxatw-items-center naxatw-justify-start naxatw-gap-1">
            <>
              <p className="naxatw-text-[0.625rem] naxatw-font-medium naxatw-uppercase naxatw-leading-3 naxatw-tracking-[0.03125rem] naxatw-text-matt-200">
                {legend?.name}
              </p>
            </>
          </div>
        </FlexRow>
      ))}
    </FlexColumn>
  );
};
