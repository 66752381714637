/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useTypedDispatch } from '@Store/hooks';
import ToolTip from '@Components/RadixComponents/ToolTip';
import SwitchTab from '@Components/common/FormUI/SwitchTab';
import { nodeColors } from '@Constants/SankeyChartData';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import CaptureComponent from '../CaptureComponent';
import FullChart from '../FullChart';
import { ILegendProps } from '../types';

export interface ISwitchTabDataProps {
  switchTabOptions?: any;
  dispatchAction?: ActionCreatorWithPayload<any, string>;
  activeLabel?: string;
  switchBoxTitle?: string;
  objectKey?: string;
}

export interface IChartHeaderProps {
  chartTitle: string;
  hasDownloadBtn?: boolean;
  downloadComponentRef: React.RefObject<any>;
  legend?: (props: ILegendProps<any>) => JSX.Element;
  xLabel?: string;
  yLabel?: string;
  data: any[];
  type: any;
  fill?: string[];
  hasFullScreen?: boolean;
  switchTabData?: ISwitchTabDataProps;
  subTitle?: string;
}

export default function ChartHeader({
  chartTitle,
  hasDownloadBtn,
  downloadComponentRef,
  legend,
  xLabel,
  yLabel,
  data,
  type,
  fill,
  hasFullScreen = true,
  switchTabData,
  subTitle,
}: IChartHeaderProps) {
  const [fullScreen, setFullScreen] = useState(false);
  const dispatch = useTypedDispatch();
  const {
    switchTabOptions,
    dispatchAction,
    activeLabel,
    switchBoxTitle,
    objectKey,
  } = switchTabData || {};
  return (
    <div className="naxatw-flex naxatw-items-center naxatw-justify-between naxatw-border-b naxatw-border-solid naxatw-border-b-primary-200 naxatw-px-6 naxatw-py-5">
      {subTitle ? (
        <FlexColumn className="naxatw-gap-1">
          <p className="naxatw-text-base naxatw-font-bold naxatw-text-matt-200">
            {chartTitle}
          </p>
          {/* <p className="naxatw-text-[0.625rem] naxatw-font-normal naxatw-text-matt-100">
            {subTitle}
          </p> */}
        </FlexColumn>
      ) : (
        <p className="naxatw-subtitle-lg naxatw-text-matt-100">{chartTitle}</p>
      )}
      <div className="naxatw-flex naxatw-items-center naxatw-justify-end naxatw-gap-5">
        {switchTabOptions && (
          <div
            className={` ${subTitle ? 'naxatw-scale-[0.9]' : 'naxatw-scale-100'} naxatw-cursor-pointer`}
          >
            <SwitchTab
              options={switchTabOptions}
              activeLabel={switchTabOptions[0].label}
              activeValue={activeLabel}
              title={switchBoxTitle || 'View By'}
              defaultBg={false}
              onChange={value => {
                if (dispatchAction) {
                  dispatch(
                    dispatchAction(
                      !objectKey ? value : { key: objectKey, value },
                    ),
                  );
                }
              }}
            />
          </div>
        )}
        <FlexRow className="naxatw-items-center naxatw-gap-x-2">
          {hasFullScreen && (
            <FlexRow className="naxatw-h-6 naxatw-w-6 naxatw-cursor-pointer naxatw-items-center naxatw-justify-center">
              <ToolTip
                name="open_in_full"
                message="View full screen"
                className="naxatw-rounded-full naxatw-p-1 !naxatw-text-xl naxatw-text-matt-100 naxatw-duration-300 hover:naxatw-bg-grey-100"
                messageStyle="naxatw-font-normal"
                iconClick={() => setFullScreen(true)}
              />
            </FlexRow>
          )}
          {hasDownloadBtn && (
            <FlexRow className="actions naxatw-h-6 naxatw-w-6 naxatw-cursor-pointer naxatw-items-center naxatw-justify-center">
              <ToolTip
                name="download"
                message="Download chart"
                className="naxatw-rounded-full naxatw-p-1 !naxatw-text-xl  naxatw-text-matt-100 naxatw-duration-300 hover:naxatw-bg-grey-100"
                messageStyle="naxatw-font-normal"
                iconClick={() =>
                  CaptureComponent({
                    componentRef: downloadComponentRef,
                    captureName: chartTitle,
                  })
                }
              />
            </FlexRow>
          )}
        </FlexRow>
      </div>
      {hasFullScreen && fullScreen && (
        <FullChart
          fill={fill}
          show={fullScreen}
          data={data}
          legend={legend}
          xLabel={xLabel}
          yLabel={yLabel}
          type={type}
          chartTitle={chartTitle}
          onClose={() => setFullScreen(false)}
          sankeyChartData={{
            title1: 'Programme',
            title2: 'Working Areas',
            title3: 'Stakeholders',
            nodeColors,
          }}
        />
      )}
    </div>
  );
}
