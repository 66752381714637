import ChartRenderer from '@Components/common/Charts/ChartRenderer';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { getSectorsByBudgetUsingProvince } from '@Services/provincialProfile';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';

function Sectors() {
  // const [searchParams] = useSearchParams();
  const downloadComponentRef = useRef<any>(null);
  const { provinceId } = useParams();

  const chartData = {
    id: `top-sectors-by-budget-allocation-province-${provinceId}`,
    queryFn: getSectorsByBudgetUsingProvince,
    params: { province: provinceId },
    title: 'Top Sectors by Budget Allocated',
    hasDownloadBtn: true,
    chartType: 'bar',
    hasHeader: true,
    fill: ['#B7E7E4'],
    yLabel: 'Sectors',
    xLabel: 'Budget',
    name: 'top-sectors-by-budget-allocation',
    height: '18rem',
    xLabelClassname: '!naxatw-py-4',
  };

  return (
    <div ref={downloadComponentRef} className="naxatw-min-h-[22rem]">
      <ChartRenderer data={chartData} />
    </div>
  );
}
export default hasErrorBoundary(Sectors);
