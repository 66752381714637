import Explore from '@Views/Explore';
import LandingPage from '@Views/Landingpage';
import MainDashboardPage from '@Views/MainDashboard';
import Login from '@Views/Login';
import AdminPanel from '@Views/AdminPanel';
import ExploreByGeographyPage from '@Views/ExploreByGeography';
import ProvincePage from '@Views/ProvincePage';
import ExploreByProgrammePage from '@Views/ExploreByProgramme';
import ExploreByIndicatorsPage from '@Views/ExploreByIndicators';
import ProgrammeDetailsPage from '@Views/ProgrammeDetails';
import KnowledgeRepositoryPage from '@Views/KnowledgeRepository';
// import PortfolioResultsPage from '@Views/PortfolioResults';

import { IRoute } from './types';

const appRoutes: IRoute[] = [
  {
    path: '/login',
    name: 'Landing',
    component: Login,
    authenticated: false,
  },
  {
    path: '/sign-up',
    name: 'Landing',
    component: Login,
    authenticated: false,
  },
  {
    path: '/sign-up/verify-email',
    name: 'Landing',
    component: Login,
    authenticated: false,
  },
  {
    path: '/sign-up/verify-fcdo-email',
    name: 'Landing',
    component: Login,
    authenticated: false,
  },
  {
    path: '/forgot-password',
    name: 'Landing',
    component: Login,
    authenticated: false,
  },
  {
    path: '/reset-password/:uid/:token',
    name: 'Landing',
    component: Login,
    authenticated: false,
  },
  {
    path: '/',
    name: 'Explore ',
    component: Explore,
    authenticated: true,
  },
  {
    path: '/Landingpage',
    name: 'Landingpage ',
    component: LandingPage,
    authenticated: false,
  },
  {
    path: '/dashboard/map',
    name: 'MainDashboardPage',
    component: MainDashboardPage,
  },
  {
    path: '/dashboard/table',
    name: 'MainDashboardPage',
    component: MainDashboardPage,
  },
  {
    path: '/dashboard/chart',
    name: 'MainDashboardPage',
    component: MainDashboardPage,
  },
  {
    path: '/data-bank/*',
    name: 'DataBank',
    component: AdminPanel,
    authenticated: true,
  },
  {
    path: '/explore-by/geography',
    name: 'ExploreByGeography',
    component: ExploreByGeographyPage,
    authenticated: true,
  },
  {
    path: '/explore-by/geography/:provinceId',
    name: 'ExploreByGeographyLumbini',
    component: ProvincePage,
    authenticated: true,
  },
  {
    path: '/explore-by/programmes',
    name: 'ExploreByProgramme',
    component: ExploreByProgrammePage,
    authenticated: true,
  },
  {
    path: '/explore-by/programmes/:programmeId',
    name: 'Programme Details',
    component: ProgrammeDetailsPage,
    authenticated: true,
  },
  {
    path: '/explore-by/indicators',
    name: 'ExploreByIndicators',
    component: ExploreByIndicatorsPage,
    authenticated: true,
  },
  {
    path: '/knowledge-library',
    name: 'Knowledge Library',
    component: KnowledgeRepositoryPage,
    authenticated: true,
  },
  {
    path: '/knowledge-library/contextual',
    name: 'Knowledge Library',
    component: KnowledgeRepositoryPage,
    authenticated: true,
  },
  // {
  //   path: '/portfolio-results',
  //   name: 'Portfolio Results',
  //   component: PortfolioResultsPage,
  //   authenticated: true,
  // },
];

export default appRoutes;
