/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { FormControl, Input, RadioButton } from '@Components/common/FormUI';
import InputLabel from '@Components/common/InputLabel';
import PortalTemplate from '@Components/common/Layouts/Portal';
import { FlexColumn } from '@Components/common/Layouts';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import DropDown from '@Components/common/DropDown';
import Icon from '@Components/common/Icon';
import ErrorMessage from '@Components/common/ErrorMessage';
import Checkbox from '@Components/common/FormUI/CheckBox';
import { Button } from '@Components/RadixComponents/Button';
import IconButton from '@Components/common/IconButton';
import DeleteConfirmationOverlay from '@Components/common/PopupOverlays/DeleteConfirmationOverlay';
import {
  typesOfExpenditure,
  isDonorList,
  budgetTypes,
  treasuryTypes,
  budgetBtn,
  expenditureBtn,
} from '@Constants/FormConstants/componentFormConstants';
import { formatNumber } from '@Utils/index';
import CustomBudgetInput from './CustomBudgetInput';

const EstimatedBudgetAllocationForm = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteDonorId, setDeleteDonorId] = useState<any>(null);
  const [activeBudgetSelection, setActiveSelection] = useState('Amount');
  const [activeExpenditureSelection, setActiveExpenditureSelection] =
    useState('Amount');

  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const {
    fields: donorFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'estimated_budget.donor',
  });

  const [
    watchSelectedBudget,
    watchSelectedBudgetType,
    watchSelectedTreasuryType,
    watchSelectedDonor,
    watchSelectedRdel,
    watchSelectedCdel,
    watchSelectedRdelIcf,
    watchSelectedCdelIcf,
    watchSelectedOnBudgetOnTreasuryAmount,
    watchSelectedOnBudgetOnTreasuryPercentage,
    watchSelectedOnBudgetOffTreasuryAmount,
    watchSelectedOnBudgetOffTreasuryPercentage,
    watchSelectedOffBudgetOnTreasuryAmount,
    watchSelectedOffBudgetOnTreasuryPercentage,
    watchSelectedOffBudgetOffTreasuryAmount,
    watchSelectedOffBudgetOffTreasuryPercentage,
    watchSelectedRdelBudget,
    watchSelectedRdelPercentage,
    watchSelectedCdelBudget,
    watchSelectedCdelPercentage,
    watchSelectedRdelIcfBudget,
    watchSelectedRdelIcfPercentage,
    watchSelectedCdelIcfBudget,
    watchSelectedCdelIcfPercentage,
    watchSelectedDonorName,
  ] = useWatch({
    control,
    name: [
      'estimated_budget.approved_budget',
      'estimated_budget.budget_type',
      'estimated_budget.treasury_type',
      'estimated_budget.is_donor',
      'estimated_budget.rdel_expenditure',
      'estimated_budget.cdel_expenditure',
      'estimated_budget.rdel_icf_expenditure',
      'estimated_budget.cdel_icf_expenditure',
      'estimated_budget.on_budget_on_treasury_amount',
      'estimated_budget.on_budget_on_treasury_percentage',
      'estimated_budget.on_budget_off_treasury_amount',
      'estimated_budget.on_budget_off_treasury_percentage',
      'estimated_budget.off_budget_on_treasury_amount',
      'estimated_budget.off_budget_on_treasury_percentage',
      'estimated_budget.off_budget_off_treasury_amount',
      'estimated_budget.off_budget_off_treasury_percentage',
      'estimated_budget.rdel_budget',
      'estimated_budget.rdel_percentage',
      'estimated_budget.cdel_budget',
      'estimated_budget.cdel_percentage',
      'estimated_budget.rdel_icf_budget',
      'estimated_budget.rdel_icf_percentage',
      'estimated_budget.cdel_icf_budget',
      'estimated_budget.cdel_icf_percentage',
      'estimated_budget.donor',
    ],
  });

  //! update percentage
  const updatePercentageState = (watchValue: any, updatingKeys: any) => {
    const numericValue = Number(watchValue) || 0;

    if (activeBudgetSelection === 'Percentage') {
      const amount = watchSelectedBudget
        ? (numericValue * watchSelectedBudget) / 100
        : 0;
      setValue(updatingKeys, Number(amount.toFixed(0)));
    }
    if (activeExpenditureSelection === 'Percentage') {
      const amount = watchSelectedBudget
        ? (numericValue * watchSelectedBudget) / 100
        : 0;
      setValue(updatingKeys, Number(amount.toFixed(0)));
    }
  };

  //! update amount
  const updateFormState = (watchValue: any, updatingKeys: any) => {
    const numericValue = Number(watchValue) || 0;

    if (activeBudgetSelection === 'Amount') {
      const percentage = watchSelectedBudget
        ? (numericValue / watchSelectedBudget) * 100
        : 0;
      setValue(updatingKeys, Number(percentage.toFixed(0)));
    }

    if (activeExpenditureSelection === 'Amount') {
      const percentage = watchSelectedBudget
        ? (numericValue / watchSelectedBudget) * 100
        : 0;
      setValue(updatingKeys, Number(percentage.toFixed(0)));
    }
  };

  //! empty childen on parent onclick
  const updateChildrenState = (
    watchValue: any,
    updateAmount: string,
    updatePercentage: string,
  ) => {
    if (watchValue === false) {
      setValue(updateAmount, null);
      setValue(updatePercentage, null);
    }
  };

  // ----------------------- budget treasury amount  ---------------  //
  useEffect(() => {
    updateFormState(
      watchSelectedOnBudgetOnTreasuryAmount,
      'estimated_budget.on_budget_on_treasury_percentage',
    );
  }, [watchSelectedOnBudgetOnTreasuryAmount]);

  useEffect(() => {
    updateFormState(
      watchSelectedOnBudgetOffTreasuryAmount,
      'estimated_budget.on_budget_off_treasury_percentage',
    );
  }, [watchSelectedOnBudgetOffTreasuryAmount]);

  useEffect(() => {
    updateFormState(
      watchSelectedOffBudgetOnTreasuryAmount,
      'estimated_budget.off_budget_on_treasury_percentage',
    );
  }, [watchSelectedOffBudgetOnTreasuryAmount]);

  useEffect(() => {
    updateFormState(
      watchSelectedOffBudgetOffTreasuryAmount,
      'estimated_budget.off_budget_off_treasury_percentage',
    );
  }, [watchSelectedOffBudgetOffTreasuryAmount]);

  // ----------------------- budget treasury -----percentage -----//
  useEffect(() => {
    updatePercentageState(
      watchSelectedOnBudgetOnTreasuryPercentage,
      'estimated_budget.on_budget_on_treasury_amount',
    );
  }, [watchSelectedOnBudgetOnTreasuryPercentage]);

  useEffect(() => {
    updatePercentageState(
      watchSelectedOnBudgetOffTreasuryPercentage,
      'estimated_budget.on_budget_off_treasury_amount',
    );
  }, [watchSelectedOnBudgetOffTreasuryPercentage]);

  useEffect(() => {
    updatePercentageState(
      watchSelectedOffBudgetOnTreasuryPercentage,
      'estimated_budget.off_budget_on_treasury_amount',
    );
  }, [watchSelectedOffBudgetOnTreasuryPercentage]);

  useEffect(() => {
    updatePercentageState(
      watchSelectedOffBudgetOffTreasuryPercentage,
      'estimated_budget.off_budget_off_treasury_amount',
    );
  }, [watchSelectedOffBudgetOffTreasuryPercentage]);

  // ------------------------------ for Expenditure Amount ----------------------------------- //
  useEffect(() => {
    updateFormState(
      watchSelectedRdelBudget,
      'estimated_budget.rdel_percentage',
    );
  }, [watchSelectedRdelBudget]);

  useEffect(() => {
    updateFormState(
      watchSelectedCdelBudget,
      'estimated_budget.cdel_percentage',
    );
  }, [watchSelectedCdelBudget]);

  useEffect(() => {
    updateFormState(
      watchSelectedRdelIcfBudget,
      'estimated_budget.rdel_icf_percentage',
    );
  }, [watchSelectedRdelIcfBudget]);

  useEffect(() => {
    updateFormState(
      watchSelectedCdelIcfBudget,
      'estimated_budget.cdel_icf_percentage',
    );
  }, [watchSelectedCdelIcfBudget]);

  // ----------------------------- for Expenditure Percentage ----------------------------- //
  useEffect(() => {
    updatePercentageState(
      watchSelectedRdelPercentage,
      'estimated_budget.rdel_budget',
    );
  }, [watchSelectedRdelPercentage]);

  useEffect(() => {
    updatePercentageState(
      watchSelectedCdelPercentage,
      'estimated_budget.cdel_budget',
    );
  }, [watchSelectedCdelPercentage]);

  useEffect(() => {
    updatePercentageState(
      watchSelectedRdelIcfPercentage,
      'estimated_budget.rdel_icf_budget',
    );
  }, [watchSelectedRdelIcfPercentage]);

  useEffect(() => {
    updatePercentageState(
      watchSelectedCdelIcfPercentage,
      'estimated_budget.cdel_icf_budget',
    );
  }, [watchSelectedCdelIcfPercentage]);

  useEffect(() => {
    updateChildrenState(
      watchSelectedRdel,
      'estimated_budget.rdel_budget',
      'estimated_budget.rdel_percenatge',
    );

    updateChildrenState(
      watchSelectedCdel,
      'estimated_budget.cdel_budget',
      'estimated_budget.cdel_percenatge',
    );

    updateChildrenState(
      watchSelectedRdelIcf,
      'estimated_budget.rdel_icf_budget',
      'estimated_budget.rdel_icf_percenatge',
    );
    updateChildrenState(
      watchSelectedCdelIcf,
      'estimated_budget.cdel_icf_budget',
      'estimated_budget.cdel_icf_percenatge',
    );
  }, [
    watchSelectedRdel,
    watchSelectedCdel,
    watchSelectedCdelIcf,
    watchSelectedRdelIcf,
    setValue,
  ]);

  const budgetErrors = errors?.estimated_budget || {};
  const errorMessages = [
    /* @ts-ignore */
    budgetErrors.on_budget_on_treasury_amount?.message,
    /* @ts-ignore */
    budgetErrors.on_budget_off_treasury_amount?.message,
    /* @ts-ignore */
    budgetErrors.off_budget_on_treasury_amount?.message,
    /* @ts-ignore */
    budgetErrors.off_budget_off_treasury_amount?.message,
    /* @ts-ignore */
    budgetErrors.on_budget_on_treasury_percentage?.message,
    /* @ts-ignore */
    budgetErrors.on_budget_off_treasury_percentage?.message,
    /* @ts-ignore */
    budgetErrors.off_budget_on_treasury_percentage?.message,
    /* @ts-ignore */
    budgetErrors.off_budget_off_treasury_percentage?.message,
  ].find(message => message !== undefined);

  const expenditureErrors: any = [
    /* @ts-ignore */
    errors?.estimated_budget?.rdel_budget?.message,
    /* @ts-ignore */
    errors?.estimated_budget?.cdel_budget?.message,
    /* @ts-ignore */
    errors?.estimated_budget?.rdel_icf_budget?.message,
    /* @ts-ignore */
    errors?.estimated_budget?.cdel_icf_budget?.message,
    /* @ts-ignore */
    errors?.estimated_budget?.rdel_percentage?.message,
    /* @ts-ignore */
    errors?.estimated_budget?.cdel_percentage?.message,
    /* @ts-ignore */
    errors?.estimated_budget?.rdel_icf_percentage?.message,
    /* @ts-ignore */
    errors?.estimated_budget?.cdel_icf_percentage?.message,
    /* @ts-ignore */
  ].find(message => message !== undefined);

  // Function to handle changes and format the values correctly
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const numericValue = parseFloat(value.replace(/,/g, '')) || '';
    setValue(name, numericValue, { shouldDirty: true });
  };

  return (
    <div className="budget-form-container scrollbar naxatw-h-[calc(100vh-455px)] naxatw-w-full naxatw-overflow-y-auto naxatw-px-6 lg:naxatw-h-[calc(100vh-290px)]">
      <FlexColumn className="naxatw-w-full naxatw-space-y-5">
        <FormControl className="naxatw-w-full naxatw-gap-[0.5rem]">
          <InputLabel label="Total Approved Budget (£)" astric />
          <div className="naxatw-relative">
            <Controller
              name="estimated_budget.approved_budget"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Input
                  type="text"
                  name="estimated_budget.approved_budget"
                  className="naxatw-pl-[30px]"
                  value={formatNumber(field.value)}
                  onBlur={e => {
                    handleChange(e);
                  }}
                  onChange={field.onChange}
                />
              )}
            />

            <span className="pound-symbol naxatw-t naxatw-label naxatw-pointer-events-none naxatw-absolute naxatw-left-4 naxatw-top-[50%] -naxatw-translate-y-2/4">
              £
            </span>
          </div>
          {
            /* @ts-ignore */
            errors?.estimated_budget?.approved_budget?.message && (
              <ErrorMessage
                /* @ts-ignore */
                message={errors?.estimated_budget?.approved_budget?.message}
              />
            )
          }
        </FormControl>
        {watchSelectedBudget ? (
          <FlexColumn gap={5}>
            {/* budget type treasury type */}
            <div className="naxatw-rounded-lg naxatw-bg-primary-100 naxatw-p-4">
              <FlexColumn className="naxatw-mb-3">
                <div className="naxatw-flex naxatw-items-center naxatw-gap-5">
                  <div className="naxatw-w-1/2">
                    <div className="naxatw-mb-1">
                      <InputLabel label="Budget Type" />
                    </div>
                    <Controller
                      control={control}
                      name="estimated_budget.budget_type"
                      render={({ field: { value, onChange } }) => (
                        <DropDown
                          placeholder="Choose"
                          options={budgetTypes || []}
                          value={value}
                          onChange={onChange}
                          choose="value"
                          multiple
                          className="naxatw-w-full"
                          enableSearchbar={false}
                        />
                      )}
                    />
                  </div>
                  <div className="naxatw-w-1/2">
                    <div className="naxatw-mb-1">
                      <InputLabel label="Treasury Type" />
                    </div>
                    <Controller
                      control={control}
                      name="estimated_budget.treasury_type"
                      render={({ field: { value, onChange } }) => (
                        <DropDown
                          placeholder="Choose"
                          options={treasuryTypes || []}
                          value={value}
                          onChange={onChange}
                          multiple
                          choose="value"
                          className="naxatw-w-full"
                          enableSearchbar={false}
                        />
                      )}
                    />
                  </div>
                </div>
              </FlexColumn>
              {/* on budget on treasury */}
              <FlexColumn>
                {/* button */}
                <div className="naxatw-mb-[1rem] naxatw-mt-1 naxatw-flex naxatw-w-fit naxatw-items-center naxatw-rounded-[0.25rem] naxatw-bg-[#EAECF0] naxatw-p-[0.14044rem]">
                  {budgetBtn.map(btn => {
                    return (
                      <p
                        key={btn}
                        className={`${activeBudgetSelection === btn ? 'naxatw-bg-[#41588F] naxatw-text-[#F4F7FE]' : 'naxatw-bg-[[#EAECF0] naxatw-text-[#667085]'} naxatw-cursor-pointer naxatw-overflow-hidden naxatw-rounded-[0.25rem] naxatw-px-[0.5rem] naxatw-py-[0.25rem] naxatw-text-[0.75rem] naxatw-font-medium`}
                        onClick={() => setActiveSelection(btn)}
                      >
                        {btn}
                      </p>
                    );
                  })}
                </div>
                <div className="naxatw-grid naxatw-grid-cols-2 naxatw-gap-5">
                  {watchSelectedBudgetType?.includes('On-Budget') &&
                  watchSelectedTreasuryType?.includes('On-Treasury') ? (
                    <CustomBudgetInput
                      labelName="On Budget On Treasury"
                      activeSelectedButton={activeBudgetSelection}
                      registerAmount="estimated_budget.on_budget_on_treasury_amount"
                      registerPercentage="estimated_budget.on_budget_on_treasury_percentage"
                    />
                  ) : null}
                  {watchSelectedBudgetType?.includes('On-Budget') &&
                  watchSelectedTreasuryType?.includes('Off-Treasury') ? (
                    <CustomBudgetInput
                      labelName="On Budget Off Treasury"
                      activeSelectedButton={activeBudgetSelection}
                      registerAmount="estimated_budget.on_budget_off_treasury_amount"
                      registerPercentage="estimated_budget.on_budget_off_treasury_percentage"
                    />
                  ) : null}
                  {watchSelectedBudgetType?.includes('Off-Budget') &&
                  watchSelectedTreasuryType?.includes('On-Treasury') ? (
                    <CustomBudgetInput
                      labelName="Off Budget On Treasury"
                      activeSelectedButton={activeBudgetSelection}
                      registerAmount="estimated_budget.off_budget_on_treasury_amount"
                      registerPercentage="estimated_budget.off_budget_on_treasury_percentage"
                    />
                  ) : null}
                  {watchSelectedBudgetType?.includes('Off-Budget') &&
                  watchSelectedTreasuryType?.includes('Off-Treasury') ? (
                    <CustomBudgetInput
                      labelName="Off Budget Off Treasury"
                      activeSelectedButton={activeBudgetSelection}
                      registerAmount="estimated_budget.off_budget_off_treasury_amount"
                      registerPercentage="estimated_budget.off_budget_off_treasury_percentage"
                    />
                  ) : null}
                </div>
                {errorMessages && (
                  <ErrorMessage
                    className="naxatw-pl-2 naxatw-pt-3"
                    message={errorMessages}
                  />
                )}
              </FlexColumn>
            </div>
            {/* IS Multi Donor Fund  */}
            <div className="naxatw-rounded-lg naxatw-bg-primary-100 naxatw-p-4">
              <div className="naxatw-mb-3">
                <FormControl className="naxatw-w-full naxatw-gap-[0.5rem]">
                  <InputLabel label="Is this project contributing to a multi-donor trust fund ?" />
                  <Controller
                    control={control}
                    name="estimated_budget.is_donor"
                    defaultValue=""
                    render={({ field: { value, onChange } }) => (
                      <RadioButton
                        options={isDonorList || []}
                        value={value}
                        onChangeData={onChange}
                        className="!naxatw-bg-transparent"
                      />
                    )}
                  />
                </FormControl>
              </div>
              {watchSelectedDonor === 'true' ? (
                <div>
                  <div className="naxatw-mb-1">
                    <InputLabel label="Specify Names of Other Donors in the fund" />
                  </div>
                  <div className="donar-names naxatw-my-4 naxatw-flex naxatw-flex-col naxatw-gap-2">
                    {/* Donar Names */}
                    {donorFields?.map((donor: any, index: number) => {
                      return (
                        <div
                          className="naxatw-flex naxatw-items-center naxatw-justify-between naxatw-gap-3"
                          key={donor.id}
                        >
                          <Input
                            placeholder="Enter Donor Name"
                            {...register(
                              `estimated_budget.donor.${index}.donorName`,
                            )}
                          />
                          <IconButton
                            name="delete"
                            iconClassName="naxatw-text-[#CED5DF] naxatw-duration-200 hover:!naxatw-text-red-600 naxatw-cursor-pointer"
                            onClick={() => {
                              setShowDeleteModal(true);
                              setDeleteDonorId(index);
                            }}
                          />
                          {showDeleteModal && index === deleteDonorId && (
                            <PortalTemplate>
                              <DeleteConfirmationOverlay
                                onClose={() => setShowDeleteModal(false)}
                                onDelete={() => {
                                  remove(index);
                                  setShowDeleteModal(false);
                                }}
                              />
                            </PortalTemplate>
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <FlexColumn>
                    <div className="naxatw-w-full naxatw-cursor-not-allowed">
                      <Button
                        variant="secondary"
                        onClick={e => {
                          e.preventDefault();
                          append({
                            donorName: '',
                          });
                        }}
                        disabled={watchSelectedDonorName?.some(
                          (donor: Record<string, any>) =>
                            donor.donorName === '',
                        )}
                        className="!naxatw-w-full"
                      >
                        <Icon name="add" />
                        Add More Donor
                      </Button>
                    </div>
                    {
                      /* @ts-ignore */
                      errors?.estimated_budget?.donor?.message && (
                        <ErrorMessage
                          className="naxatw-pl-2 naxatw-pt-3"
                          message={
                            /* @ts-ignore */
                            errors?.estimated_budget?.donor
                              ?.message /* @ts-ignore */
                          }
                        />
                      )
                    }
                  </FlexColumn>
                </div>
              ) : null}
            </div>
            {/* ------------------ expenditure  --------------------> */}
            <div className="naxatw-mb-4 naxatw-rounded-lg naxatw-bg-primary-100 naxatw-p-4">
              <div className="">
                <div className="naxatw-mb-4">
                  <div className="naxatw-mb-2">
                    <InputLabel label="Types of Expenditure" />
                  </div>
                  <div className="naxatw-flex naxatw-flex-col naxatw-gap-1">
                    {typesOfExpenditure.map((exp: any) => {
                      return (
                        <Controller
                          key={exp.id}
                          control={control}
                          name={`estimated_budget.${exp.value}`}
                          render={({ field: { value, onChange } }) => (
                            <Checkbox
                              value={value}
                              onChange={onChange}
                              label={exp.label}
                              id={exp.id}
                              checked={value}
                              labelClassName="naxatw-bg-transparent"
                            />
                          )}
                        />
                      );
                    })}
                  </div>
                </div>
                <div>
                  {/* button */}
                  <div className="naxatw-mb-[1rem] naxatw-flex naxatw-w-fit naxatw-items-center naxatw-rounded-[0.25rem] naxatw-bg-[#EAECF0] naxatw-p-[0.14044rem]">
                    {expenditureBtn.map(btn => {
                      return (
                        <p
                          key={btn}
                          className={`${activeExpenditureSelection === btn ? 'naxatw-bg-[#41588F] naxatw-text-[#F4F7FE]' : 'naxatw-bg-[[#EAECF0] naxatw-text-[#667085]'} naxatw-cursor-pointer naxatw-overflow-hidden naxatw-rounded-[0.25rem] naxatw-px-[0.5rem] naxatw-py-[0.25rem] naxatw-text-[0.75rem] naxatw-font-medium`}
                          onClick={() => setActiveExpenditureSelection(btn)}
                        >
                          {btn}
                        </p>
                      );
                    })}
                  </div>
                  <div className="naxatw-grid naxatw-grid-cols-2 naxatw-items-center naxatw-gap-5">
                    {watchSelectedRdel ? (
                      <CustomBudgetInput
                        labelName="RDEL Amount (£)"
                        registerAmount="estimated_budget.rdel_budget"
                        registerPercentage="estimated_budget.rdel_percentage"
                        activeSelectedButton={activeExpenditureSelection}
                      />
                    ) : null}
                    {watchSelectedCdel ? (
                      <CustomBudgetInput
                        labelName="CDEL Amount (£)"
                        registerAmount="estimated_budget.cdel_budget"
                        registerPercentage="estimated_budget.cdel_percentage"
                        activeSelectedButton={activeExpenditureSelection}
                      />
                    ) : null}
                    {watchSelectedRdelIcf ? (
                      <CustomBudgetInput
                        labelName="RDEL-ICF Amount (£)"
                        registerAmount="estimated_budget.rdel_icf_budget"
                        registerPercentage="estimated_budget.rdel_icf_percentage"
                        activeSelectedButton={activeExpenditureSelection}
                      />
                    ) : null}
                    {watchSelectedCdelIcf ? (
                      <CustomBudgetInput
                        labelName="CDEL-ICF Amount (£)"
                        registerAmount="estimated_budget.cdel_icf_budget"
                        registerPercentage="estimated_budget.cdel_icf_percentage"
                        activeSelectedButton={activeExpenditureSelection}
                      />
                    ) : null}
                  </div>
                  {expenditureErrors && (
                    <ErrorMessage
                      className="naxatw-pl-2 naxatw-pt-3"
                      message={expenditureErrors}
                    />
                  )}
                </div>
              </div>
            </div>
          </FlexColumn>
        ) : null}
      </FlexColumn>
    </div>
  );
};

export default EstimatedBudgetAllocationForm;
